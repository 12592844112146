import React from "react";

const Location: React.FC = (props: any) => (
  <div className="card-user card shadow-none mb-0">
    <div className=" card-header">
      <h5 className="card-title " id="registro"><i className="nc-icon nc-pin-3"></i> Ubicaciones</h5>
    </div>
    <div className="card-body">
      <ul className="list-unstyled mb-0">
        {props.addresses ?
          props.addresses.map((address: any) => {
            return (
              <li >
                <b>{address.name}</b><br /> <address className="text-muted"> {address.postalAddress},<br />  {address.building},<br />
                  {address.postalCode.text} {address.municipality.text}  ({address.province.text} - {address.country.text}) <br />
                  Telf: {address.phoneNumber1} {address.phoneNumber2 ? "- " + address.phoneNumber2 : null}</address>
              </li>
            )
          })
          : null}
      </ul>
    </div>
  </div>
);

export default Location; 
