import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import { offerListItem } from "../../models/Offer";
import { required } from "../../utils/validations";
import { formSubscriptionItems } from "final-form";

const Offer: React.FC = (props: any) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [offerOid, setOfferOid] = useState(null);
  let finishOn = new Date(Date.now());
  const today = new Date(Date.now());

  return (
    <div>
      <div className="card-user card">
        <div className="card-header">
          <h5 className="card-title">Listado de ofertas</h5>
          <Link to="/private/new-offer" className="btn btn-primary">
            + Añadir oferta de trabajo
          </Link>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-left">Oferta</th>
                  <th className="text-left">Municipio</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Publicación</th>
                  <th className="text-center">Caducidad</th>
                  <th className="text-center">
                    <i
                      className="fas fa-users "
                      data-toggle="tooltip"
                      title="Total inscripciones"
                    />
                  </th>
                  <th className="text-center">
                    <i
                      className="fas fa-clock text-warning"
                      data-toggle="tooltip"
                      title="Por leer"
                    />
                  </th>
                  <th className="text-center">
                    <i
                      className="fas fa-tag text-warning"
                      data-toggle="tooltip"
                      title="En proceso"
                    />
                  </th>
                  <th className="text-center">
                    <i
                      className="fas fa-check text-success"
                      data-toggle="tooltip"
                      title="Finalistas"
                    />
                  </th>
                  <th className="text-center">
                    <i
                      className="fas fa-trophy text-success"
                      data-toggle="tooltip"
                      title="Seleccionados/as"
                    />
                  </th>
                  <th className="text-center">
                    <i
                      className="fas fa-user-slash text-danger"
                      data-toggle="tooltip"
                      title="Descartados/as"
                    />
                  </th>
                  <th className="text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {props.offers &&
                  props.offers.map((offer: offerListItem, key: number) => {
                    return (
                      <tr key={key}>
                        <td className="text-left">{offer.name}</td>
                        <td className="text-left">{offer.municipality}</td>
                        <td className="text-center">
                          {offer.state === 0 ? "Borrador" : null}
                          {offer.state === 1 ? "No visible" : null}
                          {offer.state === 2 ? "Publicada" : null}
                          {offer.state === 3 ? "No visible" : null}
                          {offer.state === 4 ? "Cerrada" : null}
                        </td>
                        <td className="text-center">
                          {offer.startOn
                            ? new Date(offer.startOn).toLocaleDateString()
                            : "-"}
                        </td>
                        <td className="text-center">
                          {offer.finishOn
                            ? new Date(offer.finishOn).toLocaleDateString()
                            : "-"}
                        </td>
                        <td className="text-center">
                          {offer.totalCandidacies}
                        </td>
                        <td className="text-center">
                          {offer.unreadCandidacies}
                        </td>
                        <td className="text-center">
                          {offer.inProcessCandidacies}{" "}
                        </td>
                        <td className="text-center">
                          {offer.preselectedCandidacies}
                        </td>
                        <td className="text-center">
                          {offer.selectedCandidacies}
                        </td>
                        <td className="text-center">
                          {offer.discardedCandidacies}
                        </td>

                        <td className="text-left">
                          {offer.state === 1 ||
                          offer.state === 2 ||
                          offer.state === 3 ||
                          offer.state === 4 ? (
                            <button
                              data-toggle="tooltip"
                              title="Ver"
                              className="btn btn-outline-primary btn-sm p-1 mr-1"
                              onClick={() => {
                                if (offer.state !== 0) {
                                  props.routeProps.history.push(
                                    "/private/offer-detail/" + offer.oid,
                                    offer.oid
                                  );
                                }
                              }}
                            >
                              {" "}
                              Ver
                            </button>
                          ) : null}
                          {offer.totalCandidacies == 0 && (
                            <Link
                              to={"/private/new-offer/" + offer.oid}
                              data-toggle="tooltip"
                              title="Editar"
                              className="btn btn-outline-primary btn-sm p-1 mr-1"
                            >
                              {" "}
                              Editar
                            </Link>
                          )}
                          {offer.state === 1 || offer.state === 3 ? (
                            <button
                              data-toggle="tooltip"
                              title="Publicar"
                              className="btn btn-outline-primary btn-sm p-1 mr-1"
                              onClick={(e: any) => {
                                e.preventDefault();
                                finishOn = new Date(offer.finishOn);

                                if (
                                  offer.finishOn &&
                                  today.getTime() < finishOn.getTime()
                                ) {
                                  props.publish(offer.oid);
                                } else {
                                  props.routeProps.history.push(
                                    "/private/publish/" + offer.oid,
                                    offer
                                  );
                                }
                              }}
                            >
                              {" "}
                              Publicar
                            </button>
                          ) : null}
                          {offer.state === 2 ? (
                            <a
                              href=""
                              data-toggle="tooltip"
                              title="Despublicar"
                              className="btn btn-outline-primary btn-sm p-1 mr-1"
                              onClick={(e: any) => {
                                e.preventDefault();
                                props.hide(offer.oid);
                              }}
                            >
                              {" "}
                              Despublicar
                            </a>
                          ) : null}
                          {offer.state === 2 || offer.state === 3 ? (
                            <a
                              href=""
                              data-toggle="tooltip"
                              title="Cerrar"
                              className="btn btn-outline-primary btn-sm p-1"
                              onClick={(e: any) => {
                                e.preventDefault();

                                setCloseModal(!closeModal);
                                setOfferOid(offer.oid);
                              }}
                            >
                              {" "}
                              Cerrar
                            </a>
                          ) : null}
                          {offer.state === 0 ||
                          offer.state === 1 ||
                          offer.state === 4 ? (
                            <a
                              href=""
                              data-toggle="tooltip"
                              title="Eliminar"
                              className="btn btn-outline-primary btn-sm p-1"
                              onClick={(e: any) => {
                                e.preventDefault();
                                // handleSubmit();
                                setDeleteModal(!deleteModal);
                                setOfferOid(offer.oid);
                              }}
                            >
                              {" "}
                              Eliminar
                            </a>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* PopUp Eliminar */}
      {deleteModal && (
        <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
          <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
            Eliminar oferta
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={() => {
                props.deleteOffer(offerOid);
                setDeleteModal(!deleteModal);
              }}
              initialValues={{ offerOid: offerOid }}
              render={({
                handleSubmit,
                form,
                values,
                initialValues,
                submitting
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className=" card-body">
                    <p>
                      Estás a punto de eliminar esta oferta. Este proceso es
                      irreversible, estás seguro que quieres continuar?
                    </p>
                    <Field
                      name="offerOid"
                      component="input"
                      initialValue={initialValues.offerOid}
                      hidden
                    />
                    <button
                      className="btn btn-outline-primary mr-1"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setDeleteModal(!deleteModal);
                      }}
                    >
                      {" "}
                      No, cancelar
                    </button>
                    <button
                      className="btn btn-primary "
                      onClick={(e: any) => {
                        form.submit();
                      }}
                      disabled={submitting}
                    >
                      Sí, continuar
                    </button>
                  </div>
                </form>
              )}
            ></Form>
          </ModalBody>
        </Modal>
      )}
      {/* PopUp Cerrar */}
      {closeModal && (
        <Form
          onSubmit={values => {
            props.closeOffer(values);
            setCloseModal(!closeModal);
          }}
          initialValues={{ offerOid: offerOid, closeReason: "1", reason: "" }}
          render={({
            handleSubmit,
            form,
            values,
            initialValues,
            submitting
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal
                isOpen={closeModal}
                toggle={() => {
                  setCloseModal(!closeModal);
                }}
                backdrop="static"
              >
                <ModalHeader
                  toggle={() => {
                    setCloseModal(!closeModal);
                  }}
                >
                  Cerrar oferta
                </ModalHeader>
                <ModalBody>
                  <div className=" card-body">
                    <p>
                      ¿Ya has finalizado el proceso de selección de candidatos
                      para esta oferta?
                    </p>
                    <p>
                      Ésta opción permite cerrar la oferta y enviar un mensaje a
                      los candidatos aún no descartados informándoles que el
                      proceso de selección ha finalizado.
                    </p>
                    <p>
                      Por favor, indicanos el motivo por el que cierras la
                      oferta.
                    </p>

                    <Field
                      name="offerOid"
                      component="input"
                      initialValue={initialValues.offerOid}
                      hidden
                    />
                    <div className="form-group">
                      <label>Motivo *</label>
                      <Field
                        name="closeReason"
                        className="form-control"
                        component="select"
                        defaultValue={initialValues.closeReason}
                      >
                        <option value="1">
                          Ya hemos encontrado candidatos para esta oferta
                        </option>
                        <option value="2">Otro</option>
                      </Field>

                      <div className="invalid-feedback">
                        Seleccione una opción para continuar.
                      </div>
                    </div>
                    {values.closeReason && values.closeReason == "2" && (
                      <div className="form-group">
                        <label>Descríbelo aquí *</label>
                        <Field
                          className={
                            form.getFieldState("reason") &&
                            form.getFieldState("reason").error &&
                            form.getFieldState("reason").submitFailed
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="reason"
                          component="textarea"
                          validate={required}
                          defaultValue={initialValues.reason}
                        />
                        <div className="invalid-feedback">
                          {form.getFieldState("reason") &&
                            form.getFieldState("reason").error}
                        </div>
                      </div>
                    )}

                    <button
                      className="btn btn-outline-primary mr-1"
                      onClick={(e: any) => {
                        e.preventDefault();

                        setCloseModal(!closeModal);
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => form.submit()}
                      disabled={submitting}
                    >
                      Cerrar oferta
                    </button>
                  </div>
                </ModalBody>
              </Modal>
            </form>
          )}
        />
      )}
    </div>
  );
};

export default Offer;
