import React, { useRef, useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form, Field } from "react-final-form";
import { Button } from "reactstrap";
import { selectMultipleElements, selectSingleElement } from "../utils/selector";
import { atLeastOne, required } from "../utils/validations";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import LanguagesTypeAhead from "../components/LanguagesTableTypeAhead";
const spainID = "00000000-0000-0000-0000-0000000000a8";

const Requisites: React.FC = (props: any) => {
  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      initialValues={{
        companyOid: props.companyID ? props.companyID : null,
        oid: props.offer.oid,
        educationLevel: props.offer.educationLevel
          ? [
              selectSingleElement(
                props.lists.educationLevelList,
                props.offer.educationLevel
              )
            ]
          : [],
        educationTitles: props.offer.educationTitles
          ? selectMultipleElements(
              props.lists.educationTitleList.concat(
                props.offer.educationTitles.filter((option: any) => {
                  return option.isCustomValue === true;
                })
              ),
              props.offer.educationTitles
            )
          : null,

        averageGrade: props.offer.averageGrade,

        languages: initializeLanguageNode(props),

        professionalCards: props.offer.professionalCards
          ? selectMultipleElements(
              props.lists.professionalCardList.concat(
                props.offer.professionalCards.filter((option: any) => {
                  return option.isCustomValue === true;
                })
              ),
              props.offer.professionalCards
            )
          : null,

        hobbies: props.offer.hobbies
          ? selectMultipleElements(
              props.lists.hobbyList.concat(
                props.offer.hobbies.filter((option: any) => {
                  return option.isCustomValue === true;
                })
              ),
              props.offer.hobbies
            )
          : null,

        travelAvailability: props.offer.travelAvailability,
        drivingLicense: props.offer.drivingLicense,
        drivingLicenses: props.offer.drivingLicenses
          ? selectMultipleElements(
              props.lists.drivingLicenseList,
              props.offer.drivingLicenses
            )
          : null,

        vehicle: props.offer.vehicle,
        placeOfResidence: props.offer.placeOfResidence,
        countries: props.offer.countries
          ? selectMultipleElements(
              props.lists.countriesList,
              props.offer.countries
            )
          : null,
        provinces: props.offer.provinces
          ? selectMultipleElements(
              props.lists.provincesList.concat(
                props.offer.provinces.filter((option: any) => {
                  return option.isCustomValue === true;
                })
              ),
              props.offer.provinces
            )
          : null,
        municipalities: props.offer.municipalities
          ? selectMultipleElements(
              props.lists.municipalitiesList.concat(
                props.offer.municipalities.filter((option: any) => {
                  return option.isCustomValue === true;
                })
              ),
              props.offer.municipalities
            )
          : null
      }}
      onSubmit={(values: any) => {
        props.submitRequisites(
          props.stepper,
          props.setOfferID,
          {
            ...values,
            languages: values.languages.map((item: any) => {
              return {
                language: {
                  categoryOid: item.language.oid
                },

                level: {
                  categoryOid: item.level.oid
                }
              };
            }),
            educationLevel: { categoryOid: values.educationLevel[0].oid },
            drivingLicenses:
              values.drivingLicenses &&
              values.drivingLicenses.map((title: any) => {
                return {
                  isCustomValue: false,
                  categoryOid: title.oid
                };
              }),
            educationTitles:
              values.educationTitles &&
              values.educationTitles.map((title: any) => {
                if (title.customOption !== null && title.customOption) {
                  // new Custom option (typeahead naming)
                  return {
                    isCustomValue: true,
                    text: title.label
                  };
                } else if (title.isCustomValue == true) {
                  // custom value from entity
                  return {
                    isCustomValue: true,
                    text: title.text
                  };
                } else {
                  // list item
                  return {
                    isCustomValue: false,
                    categoryOid: title.oid
                  };
                }
              }),
            professionalCards:
              values.professionalCards &&
              values.professionalCards.map((title: any) => {
                if (title.customOption !== null && title.customOption) {
                  // new Custom option (typeahead naming)
                  return {
                    isCustomValue: true,
                    text: title.label
                  };
                } else if (title.isCustomValue == true) {
                  // custom value from entity
                  return {
                    isCustomValue: true,
                    text: title.text
                  };
                } else {
                  // list item
                  return {
                    isCustomValue: false,
                    categoryOid: title.oid
                  };
                }
              }),
            hobbies:
              values.hobbies &&
              values.hobbies.map((title: any) => {
                if (title.customOption !== null && title.customOption) {
                  // new Custom option (typeahead naming)
                  return {
                    isCustomValue: true,
                    text: title.label
                  };
                } else if (title.isCustomValue == true) {
                  // custom value from entity
                  return {
                    isCustomValue: true,
                    text: title.text
                  };
                } else {
                  // list item
                  return {
                    isCustomValue: false,
                    categoryOid: title.oid
                  };
                }
              }),

            countries: values.countries.map((item: any) => {
              return { categoryOid: item.oid };
            }),
            provinces:
              values.provinces &&
              values.provinces.map((title: any) => {
                if (title.customOption !== null && title.customOption) {
                  // new Custom option (typeahead naming)
                  return {
                    isCustomValue: true,
                    text: title.label
                  };
                } else if (title.isCustomValue == true) {
                  // custom value from entity
                  return {
                    isCustomValue: true,
                    text: title.text
                  };
                } else {
                  // list item
                  return {
                    isCustomValue: false,
                    categoryOid: title.oid
                  };
                }
              }),

            municipalities:
              values.municipalities &&
              values.municipalities.map((title: any) => {
                if (title.customOption !== null && title.customOption) {
                  // new Custom option (typeahead naming)
                  return {
                    isCustomValue: true,
                    text: title.label
                  };
                } else if (title.isCustomValue == true) {
                  // custom value from entity
                  return {
                    isCustomValue: true,
                    text: title.text
                  };
                } else {
                  // list item
                  return {
                    isCustomValue: false,
                    categoryOid: title.oid
                  };
                }
              })
          },
          props.companyID
        );
      }}
      render={({
        handleSubmit,
        form: {
          mutators: { push, pop }
        },
        form,
        submitting,
        values,
        pristine,
        initialValues,
        invalid,
        errors
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="card-user card shadow-none mb-0">
              <div className=" card-header">
                <h5 className="card-title mb-0" id="registro">
                  <i className="nc-icon nc-single-02" /> Requisitos candidato/a
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="form-group">
                      <label>Estudios mínimos *</label>
                      <Field
                        name={"educationLevel"}
                        validate={atLeastOne}
                        render={field => {
                          return (
                            <div>
                              <Typeahead
                                emptyLabel={"No se han encontrado resultados."}
                                inputProps={{
                                  className:
                                    field.meta.error && field.meta.submitFailed
                                      ? "form-control is-invalid"
                                      : "form-control"
                                }}
                                labelKey={(option: any) => {
                                  return option.text ? option.text : undefined;
                                }}
                                filterBy={(option: any, props) => {
                                  if (props.selected.length) {
                                    // Display all the options if there's a selection.
                                    return true;
                                  }
                                  // Otherwise filter on some criteria.
                                  return (
                                    option.text
                                      .toLowerCase()
                                      .indexOf(props.text.toLowerCase()) !== -1
                                  );
                                }}
                                id="educationLevelTypeahead"
                                placeholder="Elige estudios mínimos..."
                                options={props.lists.educationLevelList}
                                onChange={(value: any) => {
                                  field.input.onChange(value);
                                }}
                                defaultSelected={values.educationLevel}
                              />

                              {field.meta.error && field.meta.touched && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {field.meta.error}
                                </div>
                              )}
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Titulación/es (Opcional) </label>
                      <Field
                        className="form-control"
                        name={"educationTitles"}
                        render={(field: any) => {
                          return (
                            <Typeahead
                              paginationText={"Mostrar más"}
                              allowNew={true}
                              newSelectionPrefix={"Añadir titulación: "}
                              inputProps={{
                                className: "form-control"
                              }}
                              id={field.input.name}
                              placeholder="Elige titulación/es..."
                              labelKey={(option: any): string => {
                                return option.text ? option.text : "";
                              }}
                              multiple
                              options={props.lists.educationTitleList}
                              onChange={(value: any) => {
                                field.input.onChange(value);
                              }}
                              defaultSelected={initialValues.educationTitles}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label> Nota media superior a (Opcional) </label>
                      <Field
                        className="form-control"
                        name="averageGrade"
                        type="number"
                        step="0.1"
                        component="input"
                        defaultValue={initialValues.averageGrade}
                      />
                    </div>

                    <div className="form-group">
                      <label>
                        Otros títulos, certificaciones y/o carnés (Opcional)
                      </label>

                      <Field
                        className="form-control"
                        name={"professionalCards"}
                        render={(field: any) => {
                          return (
                            <Typeahead
                              paginationText={"Mostrar más"}
                              allowNew={true}
                              newSelectionPrefix={"Añadir certificación: "}
                              inputProps={{
                                className: "form-control"
                              }}
                              id={field.input.name}
                              placeholder="Elige título/s, certificación/es y/o carné/s..."
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              multiple
                              options={props.lists.professionalCardList}
                              onChange={(value: any) => {
                                field.input.onChange(value);
                              }}
                              defaultSelected={initialValues.professionalCards}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Idioma/s *</label>
                      <br />
                      <a
                        data-toggle="tooltip"
                        title="Añadir"
                        className="btn btn-outline-primary btn-sm p-1 mr-1 mt-0"
                        onClick={e => {
                          e.preventDefault();
                          push("languages", undefined);
                        }}
                      >
                        <i className="fas fa-plus" /> Añadir idioma
                      </a>

                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <small>Idioma</small>
                            </th>
                            <th scope="col">
                              <small>Nivel</small>
                            </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray name="languages">
                            {({ fields }) =>
                              fields.map((name, index) => {
                                return (
                                  <tr key={name}>
                                    <td>
                                      <Field
                                        validate={required}
                                        name={`${name}.language`}
                                        render={field => {
                                          return (
                                            <LanguagesTypeAhead
                                              id={`${name}.language`}
                                              field={field}
                                              disabled={false}
                                              index={index}
                                              placeholder={"Elige un idioma..."}
                                              list={props.lists.languagesList}
                                              defaultSelected={getLanguage(
                                                initialValues.languages,
                                                index
                                              )}
                                            />
                                          );
                                        }}
                                      />
                                    </td>

                                    <td>
                                      <Field
                                        validate={required}
                                        name={`${name}.level`}
                                        render={field => {
                                          return (
                                            <LanguagesTypeAhead
                                              id={`${name}.level`}
                                              field={field}
                                              disabled={
                                                !values.languages[index] ||
                                                !values.languages[index]
                                                  .language
                                              }
                                              index={index}
                                              placeholder={"Elige nivel..."}
                                              list={
                                                props.lists.languageLevelList
                                              }
                                              defaultSelected={getLanguageLevel(
                                                initialValues.languages,
                                                index
                                              )}
                                            />
                                          );
                                        }}
                                      />
                                    </td>

                                    <td>
                                      <div
                                        style={{
                                          visibility:
                                            values.languages &&
                                            values.languages.length > 1
                                              ? "visible"
                                              : "hidden"
                                        }}
                                      >
                                        <a
                                          data-toggle="tooltip"
                                          title="Eliminar"
                                          className="btn btn-outline-primary btn-sm p-1 mr-1"
                                          href=""
                                          style={{ cursor: "pointer" }}
                                          onClick={e => {
                                            e.preventDefault();
                                            if (
                                              values.languages &&
                                              values.languages.length > 1
                                            ) {
                                              fields.remove(index);
                                            }
                                          }}
                                        >
                                          <i className="far fa-trash-alt" />{" "}
                                          Eliminar
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </FieldArray>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="form-group">
                      <label>Pasiones y/o aficiones (Opcional)</label>

                      <Field
                        className="form-control"
                        name={"hobbies"}
                        render={(field: any) => {
                          return (
                            <Typeahead
                              paginationText={"Mostrar más"}
                              allowNew={true}
                              newSelectionPrefix={"Añadir afición: "}
                              inputProps={{
                                className: "form-control"
                              }}
                              id={field.input.name}
                              placeholder="Elige pasión/es y/o afición/es..."
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              multiple
                              options={props.lists.hobbyList}
                              onChange={(value: any) => {
                                field.input.onChange(value);
                              }}
                              defaultSelected={initialValues.hobbies}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="form-group mb-1">
                      <div className="form-check">
                        <label className="form-check-label">
                          <Field
                            name="travelAvailability"
                            type="checkbox"
                            component="input"
                            defaultValue={initialValues.travelAvailability}
                          />
                          Disponibilidad para viajar requerida
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-check">
                        <label className="form-check-label">
                          <Field
                            name="drivingLicense"
                            type="checkbox"
                            component="input"
                            defaultValue={initialValues.drivingLicense}
                          />
                          Permiso de conducción requerido
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                        </label>
                      </div>

                      {values.drivingLicense === true && (
                        <Field
                          className="form-control"
                          validate={required}
                          name={"drivingLicenses"}
                          render={(field: any) => {
                            return (
                              <div>
                                <Typeahead
                                  inputProps={{
                                    className:
                                      field.meta.error &&
                                      field.meta.submitFailed
                                        ? "form-control is-invalid"
                                        : "form-control",
                                    style: { width: "auto" }
                                  }}
                                  id={field.input.name}
                                  placeholder="Elige tipo/s de permiso..."
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={props.lists.drivingLicenseList}
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  emptyLabel={
                                    "No se han encontrado resultados."
                                  }
                                  defaultSelected={
                                    initialValues.drivingLicenses
                                  }
                                />
                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      )}
                    </div>

                    <div className="form-group mb-1">
                      <div className="form-check">
                        <label className="form-check-label">
                          <Field
                            name="vehicle"
                            type="checkbox"
                            component="input"
                            defaultValue={initialValues.vehicle}
                          />
                          Vehículo propio requerido
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="form-group mb-1">
                      <div className="form-check">
                        <label className="form-check-label">
                          <Field
                            name="placeOfResidence"
                            type="checkbox"
                            component="input"
                            defaultValue={initialValues.placeOfResidence}
                          />
                          Lugar de residencia concreto
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                        </label>
                      </div>
                    </div>

                    {values.placeOfResidence && (
                      <div>
                        <div className="form-group">
                          <label>País/es *</label>

                          <Field
                            validate={atLeastOne}
                            name="countries"
                            render={(field: any) => {
                              return (
                                <div>
                                  <Typeahead
                                    isLoading={
                                      !props.lists.countriesList ||
                                      props.lists.countriesList.length == 0
                                    }
                                    emptyLabel={
                                      !props.lists.countriesList ||
                                      props.lists.countriesList.length == 0
                                        ? "Cargando..."
                                        : "No se han encontrado resultados."
                                    }
                                    isInvalid={
                                      field.meta.error && field.meta.touched
                                    }
                                    id={field.input.name}
                                    labelKey={(option: any) => {
                                      return option.text
                                        ? option.text
                                        : undefined;
                                    }}
                                    multiple
                                    options={props.lists.countriesList}
                                    placeholder="Selecciona..."
                                    onChange={(value: any) => {
                                      field.input.onChange(value);
                                    }}
                                    defaultSelected={initialValues.countries}
                                  />
                                  {field.meta.error && field.meta.touched && (
                                    <div
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    >
                                      {field.meta.error}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Provincia/s (Opcional)</label>

                          <Field
                            name="provinces"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  disabled={
                                    !values.countries ||
                                    values.countries.length < 1
                                  }
                                  isLoading={
                                    !props.lists.provincesList ||
                                    props.lists.provincesList.length == 0
                                  }
                                  emptyLabel={
                                    "Escribe la provincia para añadirla"
                                  }
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={props.lists.provincesList}
                                  placeholder="Selecciona..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  allowNew={true}
                                  newSelectionPrefix={"Añadir provincia: "}
                                  defaultSelected={values.provinces}
                                  filterBy={(option: any, props) => {
                                    let include = false;

                                    if (
                                      values.countries &&
                                      values.countries.find((country: any) => {
                                        return country.oid == spainID;
                                      })
                                    ) {
                                      include = true;
                                    }

                                    return (
                                      include &&
                                      option.text
                                        .toLowerCase()
                                        .indexOf(props.text.toLowerCase()) !==
                                        -1
                                    );
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Municipio/s (Opcional)</label>

                          <Field
                            name="municipalities"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  disabled={
                                    !values.countries ||
                                    values.countries.length < 1
                                  }
                                  isLoading={
                                    !props.lists.municipalitiesList ||
                                    props.lists.municipalitiesList.length == 0
                                  }
                                  emptyLabel={
                                    "Escribe el municipio para añadirlo"
                                  }
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={props.lists.municipalitiesList}
                                  placeholder="Selecciona..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  filterBy={(option: any, tprops) => {
                                    let include: boolean = option.isCustomValue;

                                    if (
                                      values.provinces &&
                                      values.provinces.length > 0 &&
                                      values.countries &&
                                      values.countries.find((country: any) => {
                                        return country.oid == spainID;
                                      })
                                    ) {
                                      include = values.provinces.some(
                                        (province: any) =>
                                          province.oid == option.parentOid
                                      );
                                    }

                                    if (
                                      field.input.value &&
                                      field.input.value.length > 0
                                    ) {
                                      var alreadySelected = field.input.value.some(
                                        (val: any) => {
                                          return val.oid == option.oid;
                                        }
                                      );

                                      include = include && !alreadySelected;
                                    }

                                    return (
                                      include &&
                                      option.text
                                        .toLowerCase()
                                        .indexOf(tprops.text.toLowerCase()) !==
                                        -1
                                    );
                                  }}
                                  allowNew={true}
                                  newSelectionPrefix={"Añadir municipio: "}
                                  defaultSelected={initialValues.municipalities}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="btn btn-primary"
              onClick={() => props.stepper.previous()}
            >
              Atrás
            </Button>

            <Button
              disabled={submitting}
              className="btn btn-primary"
              type="submit"
            >
              Siguiente
            </Button>
          </form>
        );
      }}
    />
  );
};

function getLanguage(languages: any, index: number): any[] {
  let selected: any[] = [];

  if (languages[index] && languages[index].language) {
    selected.push(languages[index].language);
  }
  return selected;
}
function getLanguageLevel(languages: any, index: number): any[] {
  let selected: any[] = [];

  if (languages[index] && languages[index].level) {
    selected.push(languages[index].level);
  }
  return selected;
}

function initializeLanguageNode(props: any): any[] {
  let selectedLanguages: any[] = [];

  if (props.offer.languages && props.offer.languages.length > 0) {
    props.offer.languages.forEach((languageNode: any) => {
      let languageListItem = selectSingleElement(
        props.lists.languagesList,
        languageNode.language
      );
      let levelListItem = selectSingleElement(
        props.lists.languageLevelList,
        languageNode.level
      );

      selectedLanguages.push({
        language: languageListItem,
        level: levelListItem
      });
    });
  } else {
    selectedLanguages.push(null);
  }

  return selectedLanguages;
}

export default Requisites;
