import React, { useEffect, useState } from "react";
import { getCategories } from "../../utils/GetCategories";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";

const SocialBenefits: React.FC = (props: any) => {
  const [SRGroupList, setSRGroupList] = useState([]);
  debugger;
  const [socialResponsibilities, setsocialResponsibilities] = useState(
    props.offerDetail && props.offerDetail.socialResponsibilities.length
      ? props.offerDetail.socialResponsibilities
      : !props.offerDetail
      ? props.companyDetail.socialResponsibilities
      : []
  );
  const [loading, setloading] = useState(true);
  useEffect(() => {
    getCategories("SocialResponsibilityGroup", setSRGroupList).then(() =>
      setloading(false)
    );
  }, []);
  debugger;
  return (
    <div className="card-user card  shadow-none mb-0">
      <div className=" card-header pt-0">
        <h5 className="card-title " id="registro">
          Beneficios sociales
        </h5>
      </div>

      <LoadingOverlay
        active={loading}
        spinner={<SyncLoader loading={loading} color="#51cbce" />}
        text="Cargando..."
        styles={{
          overlay: (base: any) => ({
            ...base,
            color: "black",
            backgroundColor: "transparent"
          })
        }}
      >
        {SRGroupList.length > 0 && (
          <div className="card-body pb-0">
            <div className="row">
              {SRGroupList &&
                SRGroupList.map((category: any) => {
                  if (
                    socialResponsibilities.some((SR: any) => {
                      return SR.parentOid === category.oid;
                    })
                  ) {
                    return (
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="row">
                          <div className="col-2 col-sm-3 col-md-3 col-lg-3">
                            <img
                              src={require("../../assets/img/ic_" +
                                category.oid +
                                ".png")}
                              alt="Logo"
                              className="img-responsive"
                            />
                          </div>
                          <div className="col">
                            <h6 className="mb-2">{category.text}</h6>
                            <p className="mb-0">
                              {socialResponsibilities
                                .filter((SR: any) => {
                                  return SR.parentOid === category.oid;
                                })
                                .map((SR: any) => {
                                  return SR.text;
                                })
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (
                    socialResponsibilities.some((SR: any) => {
                      return SR.parentOid === category.oid;
                    })
                  ) {
                    return (
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="row">
                          <div className="col-2 col-sm-3 col-md-3 col-lg-3">
                            <img
                              src={require("../../assets/img/ic_" +
                                category.oid +
                                ".png")}
                              alt="Logo"
                              className="img-responsive"
                            />
                          </div>
                          <div className="col">
                            <h6 className="mb-2">{category.text}</h6>
                            <p className="mb-0">
                              {socialResponsibilities
                                .filter((SR: any) => {
                                  return SR.parentOid === category.oid;
                                })
                                .map((SR: any) => {
                                  return SR.text;
                                })
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default SocialBenefits;
