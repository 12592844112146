import React, { useState, useEffect } from 'react';
import Filters from '../components/Offers/Filters';
import OfferItem from '../components/Offers/OfferItem';
import { filters } from '../models/Filters';
import { config } from '../utils/Constants';

const Offers: React.FC<filters> = (props: any) => {
  const emptyFilters: filters = {
    socialResponsibilities: [],
    workingDays: [],
    contractModalities: [],
    minimumSalary: 0,
    maximumSalary: 0,
    salary: { min: 0, max: 0 },
    offerSectors: [],
    professions: [],
    provinces: [],
    municipalities: [],
    countries: [],
    languages: [],
    educationLevels: null,
    professionalSkills: [],
    knowledges: []
  };

  const [offers, setOffers] = useState(null);
  const [count, setCount] = useState(null);
  const [maxSalary, setMaxSalary] = useState(null);
  const [page, setPage] = useState(1);
  const [jobFilterCount, setJobFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);
  const [requirementsFilterCount, setRequirementsFilterCount] = useState(0);
  const [load, setLoad] = useState(true);
  const [filters, setFilters] = useState(emptyFilters);

  useEffect(() => {
    let jobCount = 0;
    filters.socialResponsibilities &&
      filters.socialResponsibilities.length !== 0 &&
      jobCount++;
    filters.workingDays && filters.workingDays.length !== 0 && jobCount++;
    filters.minimumSalary || (filters.maximumSalary && jobCount++);
    filters.offerSectors && filters.offerSectors.length !== 0 && jobCount++;
    filters.contractModalities &&
      filters.contractModalities.length !== 0 &&
      jobCount++;
    filters.professions && filters.professions.length !== 0 && jobCount++;
    setJobFilterCount(jobCount);

    let locationCount = 0;
    filters.provinces && filters.provinces.length !== 0 && locationCount++;
    filters.municipalities &&
      filters.municipalities.length !== 0 &&
      locationCount++;
    filters.countries && filters.countries.length !== 0 && locationCount++;
    setLocationFilterCount(locationCount);

    let requirementsCount = 0;
    filters.languages && filters.languages.length !== 0 && requirementsCount++;
    filters.educationLevels &&
      filters.educationLevels !== null &&
      requirementsCount++;
    filters.professionalSkills &&
      filters.professionalSkills.length !== 0 &&
      requirementsCount++;
    filters.knowledges &&
      filters.knowledges.length !== 0 &&
      requirementsCount++;
    setRequirementsFilterCount(requirementsCount);
  }, [filters]);

  useEffect(() => {
    props.setMainSpinner(true);
    getOffers(filters, initialUrl).then((response: any) => {
      setOffers(response.items);
      setCount(response.totalCount);

      if (!maxSalary || maxSalary < response.maxSalary) {
        setMaxSalary(response.maxSalary > 20000 ? response.maxSalary : 20000);
      }
      props.setMainSpinner(false);
      window.scrollTo(0, 0);
    });
  }, [load]);

  useEffect(() => {
    props.setMainSpinner(true);
    getOffers(
      filters,
      `${config.apiURL}offers?PageNumber=${page}&PageSize=10`
    ).then((response: any) => {
      setOffers(response.items);
      setCount(response.totalCount);
      props.setMainSpinner(false);
      window.scrollTo(0, 0);
    });
  }, [page]);

  const filterProps = {
    ...props,
    maxSalary,
    setFilters,
    emptyFilters,
    jobFilterCount,
    locationFilterCount,
    requirementsFilterCount,
    setLoad,
    load
  };

  const offerItemProps = {
    ...props,
    offers,
    getOffers,
    filters,
    headers,
    count,
    setPage
  };

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <h5>Buscador de ofertas de empleo</h5>
      </div>

      <Filters {...filterProps} />
      {offers && <OfferItem {...offerItemProps} />}
    </div>
  );
};

let headers: any = null;
const initialUrl = `${config.apiURL}offers?PageNumber=1&PageSize=10`;

function getOffers(filters: any, url: string): Promise<[]> {
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        socialResponsibilities: filters.socialResponsibilities,
        workingDays: filters.workingDays,
        contractModalities: filters.contractModalities,
        minimumSalary: filters.minimumSalary,
        maximumSalary: filters.maximumSalary,
        jobSectors: filters.offerSectors,
        professions: filters.professions,
        provinces:
          filters.provinces &&
          filters.provinces.map((province: any) => {
            if (province.customOption !== null && province.customOption) {
              return {
                isCustomValue: true,
                text: province.label
              };
            } else {
              return {
                isCustomValue: false,
                oid: province.oid,
                parentOid: province.parentOid
              };
            }
          }),
        municipalities:
          filters.municipalities &&
          filters.municipalities.map((municipality: any) => {
            if (
              municipality.customOption !== null &&
              municipality.customOption
            ) {
              return {
                isCustomValue: true,
                text: municipality.label
              };
            } else {
              return {
                isCustomValue: false,
                oid: municipality.oid,
                parentOid: municipality.parentOid
              };
            }
          }),
        countries: filters.countries,
        languages: filters.languages,
        educationLevel: filters.educationLevels,
        professionalSkills: filters.professionalSkills,
        knowledges: filters.knowledges
      })
    }).then((response: any) => {
      if (response.ok) {
        response.json().then((data: any) => {
          resolve(data);
        });
        headers = JSON.parse(response.headers.get('X-Pagination'));
      } else {
        reject(response.status);
      }
    });
  });
}

export default Offers;
