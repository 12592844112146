import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProfileView from '../views/Profile';
import MyOffersView from '../views/MyOffers';
import { config } from '../utils/Constants';
import { useLocation } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const styles = { margin: '80px 30px 0 30px' };

const getCompany = async (setter: any) => {
  const result = await fetch(`${config.apiURL}companies/myCompany`, {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
    }
  });
  if (result.ok) {
    var response = await result.json();
    setter(response.item);
  }
};

const Private: React.FC = (props: any) => {
  const [company, setCompany] = useState(null);
  const [refreshCompany, setRefreshCompany] = useState(null);
  let location = useLocation();

  useEffect(() => {
    getCompany(setCompany);
  }, [refreshCompany]);

  const privateProps = {
    ...props,
    company,
    setCompany
  };
  return (
    <div style={styles}>
      {company && (
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames='fade'
            timeout={{ enter: 300, exit: 300 }}
          >
            <Switch location={location}>
              <Route
                path={['/private/profile', '/private/contract-service']}
                exact
                render={() => (
                  <ProfileView
                    {...privateProps}
                    refresher={setRefreshCompany}
                  />
                )}
              />

              <Route
                render={routeProps => (
                  <MyOffersView
                    {...privateProps}
                    routeProps={routeProps}
                    refresher={setRefreshCompany}
                  />
                )}
              />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    </div>
  );
};

export default Private;
