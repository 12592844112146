import React from "react";

const Location: React.FC = (props: any) => (
  <div className="card-user card shadow-none ">
    <div className=" card-header pt-0">
      <h5 className="card-title mb-0" id="registro">
        <i className="nc-icon nc-pin-3"></i> Ubicación trabajo
      </h5>
    </div>
    <div className="card-body pb-0">
      <p className="mb-2">
        Pais trabajo:{" "}
        <span className="font-weight-bold">
          {" "}
          {props.address ? props.address.country.text : null}
        </span>
      </p>
      <p className="mb-2">
        Comunidad autónoma:{" "}
        <span className="font-weight-bold">
          {" "}
          {props.address && props.address.region
            ? props.address.region.text
            : null}
        </span>
      </p>
      {props.address ? (
        <p className="mb-2">
          Provincia:{" "}
          <span className="font-weight-bold">
            {" "}
            {props.address.province.text}
          </span>
        </p>
      ) : null}
      {props.address ? (
        <p className="mb-2">
          Municipio:{" "}
          <span className="font-weight-bold">
            {" "}
            {props.address.municipality.text}
          </span>
        </p>
      ) : null}
      <p className="mb-2">
        Vehículo propio requerido:{" "}
        <span className="font-weight-bold">
          {" "}
          {props.vehicle === true ? <span>Sí</span> : <span>No</span>}
        </span>
      </p>
      <p className="mb-2">
        Carné de conducir requerido:{" "}
        <span className="font-weight-bold">
          {" "}
          {props.drivingLicense === true ? (
            <span>Sí -</span>
          ) : (
            <span>No</span>
          )}{" "}
          {props.drivingLIcense &&
            props.drivingLicenses
              .map((drivingLicense: any) => {
                return drivingLicense.text;
              })
              .join(", ")}{" "}
        </span>
      </p>
      <p className="mb-2">
        Disponibilidad para viajar requerida:{" "}
        <span className="font-weight-bold">
          {" "}
          {props.travelAvailability === true ? (
            <span>Sí</span>
          ) : (
            <span>No</span>
          )}
        </span>
      </p>
    </div>
  </div>
);

export default Location;
