export declare interface KillerQuestion {
  oid?: string;
  question: string;
  type: { enumValue?: number; text?: string };
  answerOptions: Answer[];
}

export declare interface Answer {
  oid?: string;
  option: string;
  value: number;
}

export const newQuestion: KillerQuestion = {
  question: "",
  type: {},
  answerOptions: []
};
