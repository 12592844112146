import React from "react";
import { tsPropertySignature } from "@babel/types";

const LinkItem: React.FC = (props: any) => (
  <div>
    {props.links ? (
      <div className="card-user card shadow-none mb-0">
        <div className=" card-header">
          <h5 className="card-title">
            <i className="nc-icon nc-globe"></i> Enlaces
          </h5>
        </div>
        <div className="card-body">
          <ul className="list-unstyled mb-0">
            {props.links.map((link: any, i: number) => {
              return (
                <li key={i}>
                  <p>
                    <b>{link.name}</b>
                    <br />{" "}
                    <a
                      rel="noreferrer noopener"
                      href={
                        link.url.includes("http://") ||
                        link.url.includes("https://")
                          ? link.url
                          : "//" + link.url
                      }
                      target={"_blank"}
                    >
                      {link.url}
                    </a>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    ) : null}
  </div>
);

export default LinkItem;
