import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";
import TPV from "../../utils/TPV";

const BuyPack: React.FC = (props: any) => {
  const [selectedPack, setSelectedPack] = useState(null);

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <div className="card-header">
            <p className="font-weight-bold text-primary mb-0 mt-1">
              {" "}
              {props.company.availableWeeks === 1
                ? props.company.availableWeeks + " semana disponible"
                : props.company.availableWeeks + " semanas disponibles"}
            </p>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <img
                  src={require(".././../assets/img/ic_doc.png")}
                  alt="Contratación de packs"
                  className="d-block mx-auto"
                />
              </div>

              <div className="col-md-10">
                <h2 className="mt-1 mb-1 h4">
                  Contratación de packs de publicación
                </h2>
                <p className="mb-1">
                  ¡Ya hemos llegado! Estamos rebosantes de energía y ganas de
                  compartir con el mundo la experiencia Worketik. Y que mejor
                  celebración que ofrecerte nuestros packs a un precio
                  increíble.
                </p>
                <form action="">
                  <Typeahead
                    id="packsTypeahead"
                    labelKey={(option: any) => {
                      return option.description + " (" + option.name + ")";
                    }}
                    filterBy={(option, props) => {
                      if (props.selected.length) {
                        // Display all the options if there's a selection.
                        return true;
                      }
                      // Otherwise filter on some criteria.
                      return (
                        (option.description + " (" + option.name + ")")
                          .toLowerCase()
                          .indexOf(props.text.toLowerCase()) !== -1
                      );
                    }}
                    placeholder="Elige un pack..."
                    options={props.packs || []}
                    isLoading={props.loading}
                    emptyLabel={
                      !props.packs
                        ? "Cargando..."
                        : "No se han encontrado resultados."
                    }
                    onChange={(value: any) => {
                      setSelectedPack(value[0]);
                    }}
                  />

                  <TPV
                    companyId={props.company.oid}
                    service={selectedPack}
                    refreshTable={props.refreshTable}
                    refreshCompany={props.refreshCompany}
                  ></TPV>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <LoadingOverlay
          active={props.loading}
          spinner={<SyncLoader loading={props.loading} color="#51cbce" />}
          text="Cargando..."
          styles={{
            overlay: (base: any) => ({
              ...base,
              borderRadius: "12px"
            })
          }}
        >
          <div className="card ">
            <div className="card-body">
              <h3 className="h5">Nuestros packs</h3>

              {props.packs &&
                props.packs.map((pack: any, j: number) => {
                  return (
                    <div className="card border shadow-none  mb-2" key={j}>
                      <div className="card-body p-2">
                        <p className="mb-0">
                          <b>{pack.name}</b> <br />
                          {pack.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default BuyPack;
