import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BasicInfo from '../components/OfferDetail/BasicInfo';
import Description from '../components/OfferDetail/Description';
import Education from '../components/OfferDetail/Education';
import Location from '../components/OfferDetail/Location';
import Offer from '../components/OfferDetail/Offer';
import SocialBenefits from '../components/OfferDetail/SocialBenefits';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from '../utils/Constants';
import { Helmet } from 'react-helmet';
const OfferDetail: React.FC = (props: any) => {
  debugger;
  const [loading, setLoading] = useState(true);
  let link: any = null;
  if (
    sessionStorage.getItem('companyToken') &&
    props.company &&
    props.company.oid
  ) {
    props.setRouteOid(props.routeProps.match.params.id);
    link = `${config.apiURL}offers/companies/${props.company.oid}/offers/${props.routeProps.match.params.id}`;
  } else {
    link = `${config.apiURL}offers/${props.routeProps.match.params.id}`;
  }
  const getOfferDetail = async (setter: any) => {
    const result = await fetch(link, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
      },
    });
    if (result.ok) {
      var response = await result.json();
      setter(response.item);
    }

    setLoading(false);
  };

  const [offerDetail, setOfferDetail] = useState(null);
  const [logged, setLogged] = useState(false);

  const token = sessionStorage.getItem('companyToken');

  useEffect(() => {
    if (token !== null) setLogged(true);
    getOfferDetail(setOfferDetail);
  }, []);

  const SRProps = {
    ...props,
    offerDetail,
  };

  const basicInfoProps = {
    ...props,
    offerDetail,
    logged,
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={<SyncLoader loading={loading} color='#51cbce' />}
      text='Cargando...'
      styles={{
        overlay: (base: any) => ({
          ...base,
          borderRadius: '12px',
        }),
      }}
    >
      <div>
        {offerDetail && (
          <Helmet>
            <script type='application/ld+json'>
              {`{
                "@context": "https://schema.org",
                "@type": "JobPosting",
                "datePosted": "${new Date(
                  new Date().setDate(
                    new Date().getDate() - offerDetail.publishedDays
                  )
                ).toISOString()}",
                "description": "<p>${offerDetail.description}</p>",
      
                "hiringOrganization": {
                  "@type": "${'Organization'}",
                  "name":"${offerDetail.companyName}",
                 
                  "logo": "${offerDetail.logoUrl}"
                },

                "jobLocation": {
                  "@type": "Place",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress":" ${offerDetail.address.postalAddress}",
                    "addressLocality": "${
                      offerDetail.address.municipality.text
                    }",
                    "addressRegion": "${
                      offerDetail.address.region
                        ? offerDetail.address.region.text
                        : offerDetail.address.province.text
                    }",
                    "postalCode": "${offerDetail.address.postalCode.text}",
                    "addressCountry": "${offerDetail.address.country.text}"
                  }
                },

                "title": "${offerDetail.name}",
                "baseSalary": {
                  "@type": "MonetaryAmount",
                  "currency": "EUR",
                  "value": {
                    "@type": "QuantitativeValue",
                    "minValue": ${offerDetail.minSalary},
                    "maxValue": ${offerDetail.maxSalary},
                    "unitText": "${
                      offerDetail.salaryType == 0 ? 'MONTH' : 'YEAR'
                    }"
                  }
                },
          
                "employmentType": [${getEmploymentType(offerDetail)}]
              }`}
            </script>
          </Helmet>
        )}
        {logged === false ? (
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb bg-transparent p-1'>
              <Link to='/offers' className='breadcrumb-item'>
                Buscador de ofertas
              </Link>
              <li className='breadcrumb-item active' aria-current='page'>
                Detalle oferta
              </li>
            </ol>
          </nav>
        ) : null}

        <div className='card-user card '>
          <div className='card-body'>
            <div className=' card-header'>
              {/* <small className='text-muted mb-2'>
                Publicado hace {offerDetail ? offerDetail.publishedDays : null}{' '}
                {offerDetail && offerDetail.publishedDays === 1
                  ? 'día'
                  : 'días'}
              </small> */}
              <h5 className='card-title' id='registro'>
                {offerDetail ? offerDetail.name : null}
              </h5>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                {offerDetail && <BasicInfo {...basicInfoProps} />}
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                {offerDetail && <Offer {...offerDetail} />}
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                {offerDetail && <Location {...offerDetail} />}
              </div>
              <div className='col-xs-12 col-sm-12 col-md-12'>
                {offerDetail && <Education {...offerDetail} />}
              </div>
              <div className='col-md-12'>
                {offerDetail && <SocialBenefits {...SRProps} />}
              </div>
              <div className='col-md-12'>
                {offerDetail && <Description {...offerDetail} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

function getEmploymentType(offer: any) {
  let employmentTypeText: string[] = [];

  switch (offer.workingDay.categoryOid) {
    case '00000000-0000-0000-0000-00000000026a': // jornada a tiempo completo
      employmentTypeText.push('"FULL_TIME"');
      break;

    case '00000000-0000-0000-0000-000000000271': // media jornada
    case '00000000-0000-0000-0000-00000000026e': // parcial por horas
    case '00000000-0000-0000-0000-000000000270': // jornada reducida
      employmentTypeText.push('"PART_TIME"');
      break;
  }

  switch (offer.contractModality.categoryOid) {
    case '00000000-0000-0000-0000-000000000002': // prácticas
    case '00000000-0000-0000-0000-000000000005': // formación y el aprendizaje
      employmentTypeText.push('"INTERN"');
      break;

    case '00000000-0000-0000-0000-000000000006': // voluntariado
      employmentTypeText.push('"VOLUNTEER"');
      break;

    case '00000000-0000-0000-0000-000000000004': // temporal
      employmentTypeText.push('"TEMPORARY"');
      break;
  }

  return employmentTypeText.length ? employmentTypeText : '"OTHER"';
}

export default OfferDetail;
