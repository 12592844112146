import React, { useState, useEffect } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import ContractService from '../layouts/ContractService';
import ProfileContainer from '../layouts/Profile';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from '../utils/Constants';
import { useLocation } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
const styles = { margin: '80px 30px 0 30px' };

const Profile: React.FC = (props: any) => {
  const [lists, setLists] = useState(null);
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  useEffect(() => {
    Promise.all([
      fetch(`${config.apiURL}categories/sector`),
      fetch(`${config.apiURL}categories/subsector`),
      fetch(`${config.apiURL}categories/language`),
      fetch(`${config.apiURL}categories/companySize`),
      fetch(`${config.apiURL}categories/organizationType`),
      fetch(`${config.apiURL}categories/SocialResponsibilityGroup`),
      fetch(`${config.apiURL}categories/SocialResponsibility`),

      fetch(`${config.apiURL}categories/country`),
      fetch(`${config.apiURL}categories/province`),
      fetch(`${config.apiURL}categories/municipality`),
      fetch(`${config.apiURL}categories/postalcode`)
    ]).then(categories => {
      Promise.all([
        categories[0].json(),
        categories[1].json(),
        categories[2].json(),
        categories[3].json(),
        categories[4].json(),
        categories[5].json(),
        categories[6].json(),
        categories[7].json(),
        categories[8].json(),
        categories[9].json(),
        categories[10].json()
      ])
        .then(data => {
          setLists({
            sectorsList: data[0].items,
            subSectorsList: data[1].items,
            languagesList: data[2].items,
            companySizesList: data[3].items,
            organizationTypesList: data[4].items,
            SRGroupList: data[5].items,
            SRList: data[6].items,

            countriesList: data[7].items,
            provincesList: data[8].items,
            municipalitiesList: data[9].items,
            postalCodesList: data[10].items
          });
        })
        .then(() => setLoading(false));
    });
  }, []);

  return (
    <div style={styles}>
      <ul className='nav nav-pills mb-3'>
        <li className='nav-item'>
          <NavLink to='/private/profile' className='nav-link nav-link-primary'>
            Datos de la organización
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/private/contract-service' className='nav-link'>
            Contratación de servicios
          </NavLink>
        </li>
      </ul>

      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames='fade'
          timeout={{ enter: 300, exit: 300 }}
        >
          <Switch location={location}>
            <Route
              path='/private/profile'
              exact
              render={() => (
                <LoadingOverlay
                  text='Cargando...'
                  styles={{
                    overlay: (base: any) => ({
                      ...base,

                      borderRadius: '12px'
                    })
                  }}
                  active={
                    loading && props.location.pathname.match('/private/profile')
                  }
                  spinner={
                    <SyncLoader
                      color='#51cbce'
                      loading={
                        loading &&
                        props.location.pathname.match('/private/profile')
                      }
                    />
                  }
                >
                  {loading && (
                    <div>
                      <div className='content'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='card-user card'>
                              <div className='card-header'>
                                <h5 className='card-title'>
                                  Datos de la organización
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className='col-md-12'>
                            <div className='card-user card'>
                              <div className='card-header'>
                                <h5 className='card-title'>
                                  Datos de contacto
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='card-user card'>
                              <div className='card-header'>
                                <h5 className='card-title'>
                                  Beneficios sociales
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          {' '}
                          <div className='card-user card'>
                            <div className='card-header'>
                              <h5 className='card-title mb-2'>Ubicación/es</h5>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          {' '}
                          <div className='card-user card'>
                            <div className='card-header'>
                              <h5 className='card-title mb-2'>Enlaces</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!loading && <ProfileContainer {...props} lists={lists} />}
                </LoadingOverlay>
              )}
            />

            <Route
              path='/private/contract-service'
              render={() => <ContractService {...props} />}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Profile;
