import React from "react";

const TermsOfUse: React.FC = () => (
    <div className="card-user card ">
        <div className=" card-header">
            <h5 className="card-title " id="registro">Condiciones de uso</h5>
        </div>
        <div className="card-body">
            <p className="costats">En #Worketik sabemos que leer nuestra política y reglas de uso no es lo mejor que vas a leer hoy, pero nos gustan las cosas bien hechas, así que aquí te mostramos nuestra manera de hacer las cosas.</p>
            <p className="costats"><b>Nuestra política de contratación para publicación de ofertas de empleo en #Worketik</b></p>
            <p className="costats">
                <ul>
                    <li>Nuestras ofertas de empleo recogen todos los requerimientos para una posición concreta, en ella deben constar los requerimientos de ésta y las condiciones ofrecidas, incluyendo tipo de contrato, jornada, salario y número de pagas de manera visible. Nuestra recomendación a las empresas es publicar cuanta más información mejor, para hacer de este primer contacto una posibilidad para que las personas vean y se imaginen si encajan o no en vuestra organización. Las empresas deberán ser lo más sinceras y reales posibles con relación a lo que pueden ofrecer y a las demandas en sus vacantes.</li>
                    <br/><li>La duración de la publicación se contará en semanas, pudiendo añadir semanas según necesidades de la vacante. Durante la vigencia de la vacante se podrá publicar y despublicar según se quiera. Las ofertas se podrán adquirir: 1 semana o una vacante, 5 semanas o 5 vacantes, o 10 semanas o 10 vacantes.</li>
                    <br/><li>Antes de poder publicar te pediremos que aceptes las reglas aquí establecidas y las políticas legales relativas al mismo, recuerda que podrás leerlas tantas veces como quieras.Deberás tener presente que el campo ocupación y dirección de la vacante no serán modificables.Si necesitas hacer algún cambio en estos dos campos deberás comunicarlo a nuestro servicio de atención al cliente. Recuerda que podrás contactar con nosotros siempre que lo desees.  </li>
                    <br/><li>Potestad de verificación de los datos publicados: Para nosotros es un requisito que tengas la suficiente potestad para publicar una vacante, y es muy importante que las ofertas sean reales, tanto en las condiciones como en los beneficios e información que publiques relativa a tu empresa.Por supuesto decirte que vamos a retirar automáticamente todas aquellas ofertas que presenten alguna irregularidad legal o bien que atenten contra los derechos fundamentales de las personas. Ten presente que revisaremos las vacantes para que estas cumplan con nuestros estándares de calidad. </li>
                    <br/><li>#Worketik, el portal de empleo ético: La ética y las buenas maneras de gestión son una premisa importantísima para nosotros, por lo que en #Worketik no vamos a invitar a publicar ninguna empresa que discrimine por ninguna razón de ningún tipo, para nosotros todas las personas deben ser tratadas con respeto. Consideramos que en ninguna oferta laboral se deberá pedir dinero de ningún tipo, por lo que no vamos a permitir la publicación de ninguna oferta que requiera realizar ningún tipo de rembolso económico por participar en el proceso de selección, tampoco aquellas ofertas que requieran que las personas realicen llamadas a números de teléfono de tarificación adicional. No publicaremos ninguna oferta que en la que se permita la inscripción de menores sin el consentimiento de padres o tutores, ni tampoco permitiremos que se utilicen los datos de las personas para ninguna otra finalidad que no sea la de formar parte del proceso de selección. </li>
                    <br/><li>Empresas y empleos responsables: Las empresas son las responsables de las vacantes que publican. No permitiremos que las empresas utilicen o vendan los datos de los candidatos en ninguna circunstancia, así mismo las empresas deben hacerse responsables sobre las ofertas publicadas, quedando Worketik Portal, S.L eximida de cualquier responsabilidad derivada. </li>
                    <br/><li>Nuestro compromiso: En #Worketik nos comprometemos ser interactivos, a escuchar todas las sugerencias de mejora e ir adaptando nuestro portal a las necesidades detectadas. Queremos ser tu compañero de viaje en esta función tan importante como es incorporar personas a vuestra empresa. Queremos que publiques ofertas reales, legales y respetuosas con las personas, así mismo te vamos a ir recordando que tu trabajo es muy importante y significativo para todas aquellas personas que se inscriben en las vacantes, por lo que en caso de que detectemos que realizas un mal uso de nuestro portal te lo informaremos y en última instancia si no realizas ninguna acción para subsanar el mal uso, procederemos a cancelar el servicio. #Worketik es el portal para las buenas empresas así que no vamos a permitir que se nos pase nada por alto.</li>
                    <br/><li>También te pedimos que te comprometas a dar siempre respuesta a las candidaturas, tanto sea para incluir como para excluir del proceso, tenemos fijado un límite temporal de 15 días en el que consideramos que deberás ponerte en contacto con las personas para indicarles si están o no incluidas. </li>
                    <br/><li>Por nuestra parte vamos a facilitarte el trabajo, ayudarte en lo todas las dudas o sugerencias que tengas. Nos comprometemos a ser el mejor portal de empleo en el que hayas publicado, por nuestra ética, profesionalidad y porque para nosotros las personas son lo primero. Como ya sabes al usar #Worketik te comprometes a cumplir nuestra normativa, y a aceptar nuestros valores #Worketik. Vamos a evolucionar y crecer, por lo que nuestras condiciones de servicio pueden ser modificadas, en cualquier caso, verás los cambios en el apartado correspondiente. </li>
                </ul>
            </p>
            <p className="costats"><b>Personas en búsqueda de empleo.</b></p>
            <p className="costats">
                <ul>
                    <li>Compromiso con la veracidad: Pedimos a las personas, tanto empresas como personas en búsqueda de empleo que sean sinceras en la información que transmitan a las partes, por lo que deberás asegurarte de que la información de tu CV sea correcta y veraz. En caso de que se detecte alguna irregularidad te lo comunicaremos. 
                        <br/>Como ya sabes estamos aquí para cambiar las reglas del juego, por lo que, si detectas alguna irregularidad en alguna vacante, por favor ponte en contacto con nosotros para que podamos actuar al respecto. En #Worketik las personas son lo primero, pedimos que las empresas sean respetuosas con vosotr@s y de la misma manera consideramos que como solicitantes de empleo también debéis serlo vosotros también en el trato que tengáis con las empresas. 
                    </li>
                    <br/><li>Uso: #Worketik es un portal de empleo, que bajo ninguna circunstancia influye o gestiona ningún proceso de selección. Nuestra función es proporcionarte las mejores empresas donde trabajar. En nuestra página de LinkedIn encontrarás recomendaciones y artículos interesantes para poder presentar una candidatura que saque a relucir lo mejor de ti. Te invitamos a que nos sigas. También aprovechamos para recordarte la importancia de que revises el tipo de redacción e información que presentes a las empresas, recuerda que es tu carta de presentación y como decía Oscar Wild «Nunca hay una segunda oportunidad para causar una primera buena impresión» </li>
                </ul>
            </p>

        </div>
    </div>

);

export default TermsOfUse; 
