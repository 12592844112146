import { config } from './Constants';

export const required = (value: any) =>
  value ? undefined : 'Rellene este campo para continuar.';

export const atLeastOne = (value: any) =>
  value && value.length > 0 ? undefined : 'Rellene este campo para continuar.';

export const validCreatedOn = (value: any) => {
  let parsed = parseInt(value, 10);
  return value &&
    !isNaN(value) &&
    parsed > 0 &&
    parsed <= new Date().getFullYear()
    ? undefined
    : value
    ? 'Escriba un año válido con  4 cifras y sin espacios.'
    : undefined;
};

export const validPhoneNumber = (value: any) => {
  if (value) {
    const str = value.toString();
    const patt = /^\+?\d{11,}|^\d{9,}$/g;
    const isValid = patt.test(str);

    return isValid
      ? undefined
      : 'Escriba un número de teléfono válido de mínimo 9 cifras sin espacios.';
  } else {
    return undefined;
  }
};

export const validFileSize = (value: File) => {
  return value.size / 1024 / 1024 < 4
    ? undefined
    : 'El tamaño máximo admitido es de 4MB.';
};

export const validFileFormat = (value: File) => {
  const str = value.name
    .substring(value.name.length - 3, value.name.length)
    .toLowerCase();

  const wordCheckString = value.name
    .substring(value.name.length - 4, value.name.length)
    .toLowerCase();
  return str === 'pdf' || str === 'doc' || wordCheckString === 'docx'
    ? undefined
    : 'Añade un documento de formato word o pdf.';
};

const simpleMemoize = (fn: any) => {
  let lastArg: any;
  let lastResult: any;
  return (arg: any) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

export const CIFAvailable = simpleMemoize(async (value: any) => {
  let response: Response = await fetch(
    `${config.apiURL}companies/id/${value}`,
    {
      method: 'HEAD'
    }
  );

  if (response.ok) {
    return 'El identificador introducido ya está registrado.';
  } else {
    return undefined;
  }
});

export const validEmailFormat = (value: any) => {
  if (value) {
    const str = value.toString();
    const patt = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    const isValid = patt.test(str);

    return isValid
      ? undefined
      : 'Escriba una dirección de correo electrónico válida con una @ y un punto.';
  } else {
    return undefined;
  }
};

export const matchingPasswords = (value: any, values: any) => {
  console.log(values);
  return !values.confirmPassword ||
    !values.password ||
    values.confirmPassword == values.password
    ? undefined
    : 'Las contraseñas no coinciden, introdúzcalas de nuevo por favor.';
};

export const minSixChars = (value: any) =>
  !value || value.length >= 6
    ? undefined
    : 'Escriba una contraseña válida de mínimo 6 carácteres.';

export const validCIFFormat = (value: any) => {
  if (value) {
    const str = value.toString();
    const patt = /[A-Za-z]{1}&*\d{8}|\d{8}[A-Za-z]{1}|[A-Za-z]{1}\d{7}[A-Za-z]{1}/;
    const isValid = patt.test(str);

    return isValid && value.length == 9
      ? undefined
      : 'Escriba el identificador fiscal de la organización en un formato válido (ocho dígitos y una letra sin guiones ni espacios).';
  } else {
    return undefined;
  }
};

export const composeValidators = (...validators: any) => (
  value: any,
  values: any
) =>
  validators.reduce(
    (error: any, validator: any) => error || validator(value, values),
    undefined
  );
