export const config =
/*{
  apiURL: "https://localhost:44368/",
  cToken: () => sessionStorage.getItem("companyToken"),
  RedsysURL: "https://sis-t.redsys.es:25443/sis/realizarPago",
  eventOrigin: "http://localhost:3000/",
};*/
/*{
  apiURL: "https://we-api.aqaia.net/",
  cToken: () => sessionStorage.getItem("companyToken"),
  RedsysURL: "https://sis-t.redsys.es:25443/sis/realizarPago",
  eventOrigin: "https://we-app.aqaia.net/",
};*/
{
  apiURL: "https://api.worketik.com/",
  cToken: () => sessionStorage.getItem("companyToken"),
  RedsysURL: "https://sis.redsys.es/sis/realizarPago",
  eventOrigin: "https://www.worketik.com",
};
