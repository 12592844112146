import React from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { tsPropertySignature } from "@babel/types";

const NewPassword: React.FC = (props: any) => (
    <div >
        <div className="card-user card ">
            <div className=" card-header">
                <h5 className="card-title " id="registro">Nueva contraseña</h5>
            </div>
            <div className="card-body">
                <Form
                    onSubmit={props.postChangePassword}
                    render={({ handleSubmit, form, values, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <label className="mb-0">Nueva contraseña *</label>
                                    <Field
                                        name="password"
                                        component="input"
                                        type="password"
                                        data-minlength="6"
                                        className="form-control"
                                        id="inputPassword"
                                        aria-describedby="PasswordHelp"
                                        required
                                    />
                                    <small id="PasswordHelp" className="help-block form-text text-muted"> Para hacerla más segura, introduzca una contraseña de mínimo 6 caracteres. Utilice letras mayúsculas y minúsculas, números y símbolos.</small>
                                    <div className="invalid-feedback">
                                        Rellene este campo para continuar. <br />
                                        Escriba una contraseña válida de mínimo 6 caracteres.
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <label className="mb-0">Repita la contraseña *</label>
                                    <Field
                                        name="confirmPassword"
                                        component="input"
                                        type="password"
                                        data-minlength="6"
                                        className="form-control"
                                        id="inputPassword2"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Rellene este campo para continuar.<br />
                                        Escriba una contraseña válida de mínimo 6 caracteres. <br />
                                        Las contraseñas no coinciden, introduzcalas de nuevo por favor.</div>
                                </div>
                            </div>

                            <button disabled={submitting || pristine} className="btn btn-primary" type="submit">Guardar nueva contraseña</button>
                            <Link to="/login" className="btn btn-outline-primary">Cancelar</Link>
                        </form>
                    )}
                />

            </div>
        </div>

    </div>
);

export default NewPassword; 
