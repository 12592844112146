import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import PrivateArea from '../layouts/Admin';
export interface ProtectedRouteProps extends RouteProps {
  authenticationPath: string;
  mode: string;
  UpdateAuthentication: any;
}

export class ProtectedRoute extends Route<ProtectedRouteProps> {
  public render() {
    let redirectPath: string = '';
    if (sessionStorage.getItem('companyToken') == null) {
      redirectPath = this.props.authenticationPath;
    }

    if (redirectPath) {
      const renderComponent = () => (
        <Redirect to={{ pathname: redirectPath }} />
      );
      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      );
    } else {
      return (
        <Route
          render={(routeProps) => {
            return <PrivateArea {...{ ...this.props, ...routeProps }} />;
          }}
        />
      );
    }
  }
}
