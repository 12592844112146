import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { tsPropertySignature } from "@babel/types";
import { Link } from "react-router-dom";
import { Field, Form } from "react-final-form";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";
import { getMaxListeners } from "cluster";
import { required, atLeastOne } from "../../utils/validations";

const PublishOffer: React.FC = (props: any) => {
  const [finishDate, setFinishDate] = useState(null);
  return (
    <div>
      <div
        className="alert alert-info alert-dismissible fade show mb-2"
        role="alert"
      >
        <i className="fas fa-info-circle"></i>
        {" Le quedan " +
          props.company.availableWeeks +
          " semanas de publicación de oferta/s. Para comprar más semanas de publicación, haga "}
        <Link to="/private/contract-service" style={{ color: "white" }}>
          <b>click aquí</b>.
        </Link>
      </div>

      <LoadingOverlay
        active={props.loading}
        spinner={<SyncLoader loading={props.loading} color="#51cbce" />}
        text="Cargando..."
        styles={{
          overlay: (base: any) => ({
            ...base,
            borderRadius: "12px"
          })
        }}
      >
        <div className="card-user card ">
          <div className=" card-header border-bottom pb-2">
            <h5 className="card-title mb-0 font-weight-bold" id="registro">
              {props.loading || !props.offer ? "" : props.offer.name}
            </h5>
            <p className="mb-2">
              <span>Publicación oferta</span>
            </p>
          </div>

          {!props.loading && props.offer && (
            <div className="card-body">
              <Form
                onSubmit={values => {
                  props.publish(values);
                }}
                initialValues={{
                  oid: props.offer.oid,
                  publicationLength: [],
                  startOn: new Date()
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  initialValues,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-6">
                        <Field
                          name="oid"
                          type="text"
                          component="input"
                          defaultValue={initialValues.oid}
                          hidden
                        />
                        <div className="form-group">
                          <label>
                            {props.offer.startOn
                              ? "Ampliar tiempo de publicación (semanas)*"
                              : "Tiempo de publicación (semanas)*"}
                          </label>

                          <Field
                            validate={atLeastOne}
                            name={"publicationLength"}
                            render={(field: any) => {
                              return (
                                <div>
                                  <Typeahead
                                    inputProps={{
                                      className:
                                        field.meta.error &&
                                        field.meta.submitFailed
                                          ? "form-control is-invalid"
                                          : "form-control"
                                    }}
                                    labelKey={(option: any) => {
                                      return option;
                                    }}
                                    filterBy={(option: any, props) => {
                                      return true;
                                    }}
                                    id="weeksTypeahead"
                                    placeholder="¿Cuántas semanas?"
                                    options={getList(
                                      props.company.availableWeeks
                                    )}
                                    emptyLabel={"Opción no disponible."}
                                    onChange={(value: any) => {
                                      field.input.onChange(value);
                                    }}
                                    defaultSelected={
                                      initialValues.publicationLength
                                    }
                                  />
                                  {field.meta.error && field.meta.touched && (
                                    <div
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    >
                                      {field.meta.error}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Fecha inicio publicación *</label>
                          <Field
                            className={
                              form.getFieldState("startOn") &&
                              form.getFieldState("startOn").error &&
                              form.getFieldState("startOn").submitFailed
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            validate={required}
                            name="startOn"
                            render={field => {
                              return (
                                <input
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  id="date"
                                  className="form-control"
                                  type="date"
                                  defaultValue={dateFormat(new Date())}
                                />
                              );
                            }}
                          ></Field>
                          <div className="invalid-feedback">
                            {form.getFieldState("startOn") &&
                              form.getFieldState("startOn").error}
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Fecha fin publicación *</label>
                          <input
                            id="date"
                            className="form-control"
                            type="date"
                            readOnly
                            value={appendWeeks(values)}
                            // value={finishDate}
                          />
                        </div>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={submitting}
                        >
                          Publicar
                        </button>{" "}
                        <button
                          className="btn btn-outline-primary"
                          type="submit"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
};

function getList(availableWeeks: number): any[] {
  let list: any[] = [];
  list.push("1 semana");

  for (let index = 2; index <= availableWeeks; index++) {
    list.push(index + " semanas");
  }

  return list;
}

function appendWeeks(values: any) {
  debugger;
  if (values.startOn && values.publicationLength.length > 0) {
    return dateFormat(
      new Date(
        new Date(values.startOn).setDate(
          new Date(values.startOn).getDate() +
            values.publicationLength[0].split(" ")[0] * 7
        )
      )
    );
  } else return "";
}

function dateFormat(date: Date) {
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  if (parseInt(month) < 10) {
    month = "0" + month.toString();
  }
  if (parseInt(day) < 10) {
    day = "0" + day.toString();
  }
  return date.getFullYear() + "-" + month + "-" + day;
}
export default PublishOffer;
