import React, { useState, useEffect } from 'react';
import Stepper from 'bs-stepper';
import '../assets/css/bs-stepper.css';

import { Button } from 'reactstrap';
import Offer from '../components/NewOffer/Offer';
import Job from '../components/Job';
import Requisites from '../components/Requisites';
import KillerQuestions from '../components/NewOffer/KillerQuestions';
import { newOffer, offer } from '../models/Offer';
import { category } from '../models/Category';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from '../utils/Constants';

const getOffer = async (
  setter: any,
  id: string,
  companyID: string,
  setSelectedSocialBenefits: any
) => {
  const result = await fetch(
    `${config.apiURL}offers/companies/${companyID}/offers/${id}`,
    {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
      },
    }
  );
  if (result.ok) {
    var response = await result.json();
    setter({
      ...response.item,
    });
    setSelectedSocialBenefits(response.item.socialResponsibilities);
  }
};

const submitFirstStep = (
  stepper: Stepper,
  setter: any,
  values: any,
  companyID: string,
  offerID?: string
) => {
  fetch(
    `${config.apiURL}companies/${companyID}/offers/${offerID ? offerID : ''}`,
    {
      method: offerID ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
      },
      body: JSON.stringify({
        ...values,
        addressOid: values.address.oid,
        salaryType: values.salaryType.key,
        functionalLevel: {
          categoryOid: values.functionalLevel.oid,
          text: values.functionalLevel.text,
        },
        workingDay: {
          categoryOid: values.workingDay.oid,
          text: values.workingDay.text,
        },
        contractModality: {
          categoryOid: values.contractModality.oid,
          text: values.contractModality.text,
        },
        socialResponsibilities: values.socialResponsibilities.map(
          (item: any) => {
            return { ...item, parentOid: undefined };
          }
        ),
      }),
    }
  ).then((response: any) => {
    if (response.ok) {
      response.json().then((data: any) => {
        setter(data.item.oid);
        stepper.next();
      });
    }
  });
};

const submitJob = (
  stepper: Stepper,
  setter: any,
  values: any,
  companyID: string
) => {
  fetch(`${config.apiURL}companies/${companyID}/offers/${values.oid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
    },
    body: JSON.stringify({
      ...values,
      experience: {
        categoryOid: values.experience[0].oid,
        text: values.experience[0].text,
      },
      functions:
        values.functions && values.functions.length
          ? values.functions.map((item: any) => {
              return { categoryOid: item.oid, text: item.text };
            })
          : undefined,

      knowledges:
        values.knowledges && values.knowledges.length
          ? values.knowledges.map((item: any) => {
              return { categoryOid: item.oid, text: item.text };
            })
          : undefined,
      peopleInCharge: {
        categoryOid: values.peopleInCharge[0].oid,
        text: values.peopleInCharge[0].text,
      },
      professionalSkills: values.professionalSkills.map((item: any) => {
        return { categoryOid: item.oid, text: item.text };
      }),
      professions: values.professions.map((item: any) => {
        return { categoryOid: item.oid, text: item.text };
      }),
      sectors: values.sectors.map((item: any) => {
        return { categoryOid: item.oid, text: item.text };
      }),
      subsectors: values.subsectors.map((item: any) => {
        return { categoryOid: item.oid, text: item.text };
      }),
    }),
  }).then((response: any) => {
    if (response.ok) {
      response.json().then((data: any) => {
        setter(data.item.oid);
        stepper.next();
      });
    }
  });
};

const submitRequisites = (
  stepper: Stepper,
  setter: any,
  values: any,
  companyID: string
) => {
  fetch(`${config.apiURL}companies/${companyID}/offers/${values.oid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
    },
    body: JSON.stringify({
      ...values,
    }),
  }).then((response: any) => {
    if (response.ok) {
      response.json().then((data: any) => {
        setter(data.item.oid);
        stepper.next();
      });
    }
  });
  stepper.next();
};

const finalize = (offerID: string, companyID: string, history: any) => {
  fetch(`${config.apiURL}companies/${companyID}/offers/${offerID}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
    },
    body: JSON.stringify({
      state: 'created',
      oid: offerID,
      // killerQuestions: KillerQuestions.length
      //   ? KillerQuestions.map((question: KillerQuestion) => {
      //       return {
      //         ...question,
      //         questionType: question.questionType.enumValue,
      //         options: question.options.length
      //           ? question.options.map((option: any) => {
      //               return { ...option, value: option.value.enumValue };
      //             })
      //           : []
      //       };
      //     })
      //   : []
    }),
  }).then((response: any) => {
    if (response.ok) {
      response.json().then((data: any) => {
        //set alert (green)
      });
    }
  });
  history.push('/private/');
};

const NewOffer: React.FC = (props: any) => {
  const [stepper, setStepper] = useState(null);
  const [lists, setLists] = useState(null);
  const [loading, setLoading] = useState(true);
  const [indexStep, setIndexStep] = useState(0);
  const [
    enhancedCompanySocialBenefits,
    setEnhancedCompanySocialBenefits,
  ] = useState(null);
  const [selectedSocialBenefits, setSelectedSocialBenefits] = useState([]);
  const [offer, setOffer] = useState(null);
  const [offerID, setOfferID] = useState(
    props.routeProps.match.params.id ? props.routeProps.match.params.id : null
  );

  useEffect(() => {}, []);

  useEffect(() => {
    if (offerID || (offer && offer.oid)) {
      getOffer(setOffer, offerID, props.company.oid, setSelectedSocialBenefits);
    } else {
      setOffer(newOffer);
      setSelectedSocialBenefits(props.company.socialResponsibilities);
    }
  }, [indexStep]);

  useEffect(() => {
    setStepper(
      new Stepper(document.querySelector('#stepper1'), {
        linear: true,
        animation: true,
      })
    );

    document
      .querySelector('#stepper1')
      .addEventListener('show.bs-stepper', (event: any) => {
        setIndexStep(event.detail.indexStep);
        document.getElementById('stepper1').scrollIntoView();
      });

    Promise.all([
      fetch(`${config.apiURL}categories/FunctionalLevel`),
      fetch(`${config.apiURL}categories/workingDay`),
      fetch(`${config.apiURL}categories/contractModality`),
      fetch(`${config.apiURL}categories/SocialResponsibilityGroup`),
      fetch(`${config.apiURL}categories/SocialResponsibility`),

      fetch(`${config.apiURL}categories/sector`),
      fetch(`${config.apiURL}categories/subsector`),
      fetch(`${config.apiURL}categories/professionalSkill`),
      fetch(`${config.apiURL}categories/profession`),
      fetch(`${config.apiURL}categories/experience`),
      fetch(`${config.apiURL}categories/peopleInCharge`),

      fetch(`${config.apiURL}categories/educationLevel`),
      fetch(`${config.apiURL}categories/educationTitle`),
      fetch(`${config.apiURL}categories/professionalCard`),
      fetch(`${config.apiURL}categories/hobby`),
      fetch(`${config.apiURL}categories/drivingLicense`),
      fetch(`${config.apiURL}categories/country`),
      fetch(`${config.apiURL}categories/province`),
      fetch(`${config.apiURL}categories/municipality`),
      fetch(`${config.apiURL}categories/postalcode`),

      fetch(`${config.apiURL}categories/language`),
      fetch(`${config.apiURL}categories/languageLevel`),
    ]).then((categories) => {
      Promise.all([
        categories[0].json(),
        categories[1].json(),
        categories[2].json(),
        categories[3].json(),
        categories[4].json(),

        categories[5].json(),
        categories[6].json(),
        categories[7].json(),
        categories[8].json(),
        categories[9].json(),
        categories[10].json(),

        categories[11].json(),
        categories[12].json(),
        categories[13].json(),
        categories[14].json(),
        categories[15].json(),
        categories[16].json(),
        categories[17].json(),
        categories[18].json(),
        categories[19].json(),

        categories[20].json(),
        categories[21].json(),
      ])
        .then((data) => {
          setLists({
            functionalLevelList: data[0].items,
            workingDayList: data[1].items,
            contractModalityList: data[2].items,
            SRGroupList: data[3].items,
            SRList: data[4].items,

            sectorsList: data[5].items,
            subSectorsList: data[6].items,
            professionalSkillList: data[7].items,
            professionList: data[8].items,
            experienceList: data[9].items,
            peopleInCharge: data[10].items,

            educationLevelList: data[11].items,
            educationTitleList: data[12].items,
            professionalCardList: data[13].items,
            hobbyList: data[14].items,
            drivingLicenseList: data[15].items,
            countriesList: data[16].items,
            provincesList: data[17].items,
            municipalitiesList: data[18].items,
            postalCodesList: data[19].items,
            languagesList: data[20].items,
            languageLevelList: data[21].items,
          });

          let results: any = [];
          props.company.socialResponsibilities.forEach((element: category) => {
            data[4].items.find((listItem: any) => {
              if (listItem.oid == element.categoryOid) {
                results.push({ ...element, parentOid: listItem.parentOid });
              }
            });
          });
          setEnhancedCompanySocialBenefits(results);
        })
        .then(() => setLoading(false));
    });
  }, []);

  return (
    // <div className="card-user card">
    <div id='stepper1' className='bs-stepper card-user card'>
      <div className='bs-stepper-header'>
        <div className='step' data-target='#offerData'>
          <Button className='step-trigger'>
            <span className='bs-stepper-circle'>1</span>
            <span className='bs-stepper-label'>Datos oferta</span>
          </Button>
        </div>
        <div className='line' />
        <div className='step' data-target='#job'>
          <Button className='step-trigger'>
            <span className='bs-stepper-circle'>2</span>
            <span className='bs-stepper-label'>Puesto de trabajo</span>
          </Button>
        </div>
        <div className='line' />
        <div className='step' data-target='#requisites'>
          <Button className='step-trigger'>
            <span className='bs-stepper-circle'>3</span>
            <span className='bs-stepper-label'>Requisitos candidato</span>
          </Button>
        </div>
        <div className='line' />
        <div className='step' data-target='#killerQuestions'>
          <Button className='step-trigger'>
            <span className='bs-stepper-circle'>4</span>
            <span className='bs-stepper-label'>Preguntas de filtrado</span>
          </Button>
        </div>
      </div>
      <div className='bs-stepper-content'>
        <div id='offerData' className='content fade'>
          <LoadingOverlay
            active={loading}
            spinner={<SyncLoader loading={loading} color='#51cbce' />}
            text='Cargando...'
            styles={{
              overlay: (base: any) => ({
                ...base,
                color: 'black',
                backgroundColor: 'transparent',
              }),
            }}
          >
            {offer && lists && indexStep == 0 && (
              <Offer
                lists={lists}
                {...props}
                offer={offer}
                setOfferID={setOfferID}
                stepper={stepper}
                companySocialBenefits={enhancedCompanySocialBenefits}
                submitFirstStep={submitFirstStep}
                selectedSocialBenefits={selectedSocialBenefits}
                companyID={props.company.oid}
              />
            )}
            {loading && (
              <div className='card-user card shadow-none mb-0'>
                <div className=' card-header'>
                  <h5 className='card-title mb-0' id='registro'>
                    <i className='nc-icon nc-alert-circle-i' /> Datos de la
                    oferta
                  </h5>
                </div>
              </div>
            )}
          </LoadingOverlay>
        </div>

        <div id='job' className='content fade'>
          <LoadingOverlay
            active={loading}
            spinner={<SyncLoader loading={loading} color='#51cbce' />}
            text='Cargando...'
            styles={{
              overlay: (base: any) => ({
                ...base,
                color: 'black',
                backgroundColor: 'transparent',
              }),
            }}
          >
            {offer && lists && indexStep == 1 && !loading && (
              <Job
                lists={lists}
                {...props}
                offer={offer}
                setOfferID={setOfferID}
                stepper={stepper}
                submitJob={submitJob}
                companyID={props.company.oid}
              />
            )}
            {loading && (
              <div className='card-user card shadow-none mb-0'>
                <div className=' card-header'>
                  <h5 className='card-title mb-0' id='jobForm'>
                    <i className='nc-icon nc-briefcase-24' /> Descripción del
                    puesto de trabajo
                  </h5>
                </div>
              </div>
            )}
          </LoadingOverlay>
        </div>
        <div id='requisites' className='content fade'>
          <LoadingOverlay
            active={loading}
            spinner={<SyncLoader loading={loading} color='#51cbce' />}
            text='Cargando...'
            styles={{
              overlay: (base: any) => ({
                ...base,
                color: 'black',
                backgroundColor: 'transparent',
              }),
            }}
          >
            {offer && lists && indexStep == 2 && !loading && (
              <Requisites
                lists={lists}
                {...props}
                offer={offer}
                setOfferID={setOfferID}
                submitRequisites={submitRequisites}
                stepper={stepper}
                companyID={props.company.oid}
              />
            )}

            {loading && (
              <div className='card-user card shadow-none mb-0'>
                <div className=' card-header'>
                  <h5 className='card-title mb-0' id='registro'>
                    <i className='nc-icon nc-single-02' /> Requisitos
                    candidato/a
                  </h5>
                </div>
              </div>
            )}
          </LoadingOverlay>
        </div>
        <div id='killerQuestions' className='content fade'>
          <LoadingOverlay
            active={loading}
            spinner={<SyncLoader loading={loading} color='#51cbce' />}
            text='Cargando...'
            styles={{
              overlay: (base: any) => ({
                ...base,
                color: 'black',
                backgroundColor: 'transparent',
              }),
            }}
          >
            {offer && lists && indexStep == 3 && !loading && (
              <KillerQuestions
                lists={lists}
                offer={offer}
                finalize={finalize}
                history={props.routeProps.history}
                companyID={props.company.oid}
                offerID={offerID}
              />
            )}
            {loading && (
              <div className='card-user card shadow-none mb-0'>
                <div className=' card-header'>
                  <h5 className='card-title mb-2' id='registro'>
                    <i className='nc-icon nc-bullet-list-67'></i> Preguntas de
                    filtrado
                  </h5>
                </div>
              </div>
            )}
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
};

export default NewOffer;
