import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import CircleLoader from 'react-spinners/CircleLoader';

import { getCategories } from '../utils/GetCategories';
import { Field, Form } from 'react-final-form';
import { getError } from '../utils/errors';
import { Typeahead } from 'react-bootstrap-typeahead';
import { required } from '../utils/validations';
import { formSubscriptionItems } from 'final-form';
import { number } from 'prop-types';
import { isNumber } from 'util';
import { config } from '../utils/Constants';

const candidacyStateOptions = [
  { enumValue: 0, text: 'Recibida / Por leer' },
  { enumValue: 1, text: 'En proceso' },
  { enumValue: 2, text: 'Seleccionado/a' },
  { enumValue: 3, text: 'Finalista' },
  { enumValue: 4, text: 'Descartado/a' }
];

const candidacyDiscardedOptions = [
  { enumValue: 1, text: 'Preguntas de filtrado' },
  { enumValue: 2, text: 'No cumple uno a más requisitos de la oferta' },
  {
    enumValue: 3,
    text: 'Hay otras candidaturas que se ajustan mejor al perfil'
  },
  { enumValue: 4, text: 'No superada la entrevista' },
  { enumValue: 5, text: 'Otro' }
];

const CandidateDetail: React.FC = (props: any) => {
  const [candidacy, setCandidacy] = useState(null);
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false
  });

  useEffect(() => {
    if (alert && alert.visible) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  const getCandidacy = async () => {
    const result = await fetch(
      `${config.apiURL}companies/${props.company.oid}/offers/${props.routeProps.match.params.id}/candidacies/${props.routeProps.match.params.idCandidate}`,

      {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        }
      }
    );
    if (result.ok) {
      var response = await result.json();
      setCandidacy(response.item);
    }
  };

  const postCandidacyState = async (values: any) => {
    const result = await fetch(
      `${config.apiURL}companies/${props.company.oid}/offers/${props.routeProps.match.params.id}/candidacies/${props.routeProps.match.params.idCandidate}/state`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
          'Content-type': 'application/json-patch+json'
        },
        body: JSON.stringify({
          state: values.state.enumValue,
          discardedReason:
            values.state.enumValue === 4 ? values.discardedReason.enumValue : 0,
          reason: values.reason
        })
      }
    );
    setStateModal(false);
    if (result.ok) {
      setAlert({
        text: '¡Candidatura actualizada!',
        color: 'success',
        visible: true
      });
      setRefresh(new Date().getTime());
    } else {
      try {
        let data = await result.json();
        setAlert({
          text: getError(data && data.errors ? data.errors : null),
          color: 'danger',
          visible: true
        });
      } catch (error) {
        setAlert({
          text: getError(),
          color: 'danger',
          visible: true
        });
      }
    }
  };

  const putCandidacy = async (values: any) => {
    const result = await fetch(
      `${config.apiURL}companies/${props.company.oid}/offers/${props.routeProps.match.params.id}/candidacies/${props.routeProps.match.params.idCandidate}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
          'Content-type': 'application/json-patch+json'
        },
        body: JSON.stringify({ notes: values.notes })
      }
    );

    if (result.ok) {
      setAlert({
        text: '¡Nota guardada!',
        color: 'success',
        visible: true
      });
      setRefresh(new Date().getTime());
    } else {
      try {
        let data = await result.json();
        setAlert({
          text: getError(data && data.errors ? data.errors : null),
          color: 'danger',
          visible: true
        });
      } catch (error) {
        setAlert({
          text: getError(),
          color: 'danger',
          visible: true
        });
      }
    }
  };

  useEffect(() => {
    getCandidacy().then(() => setLoading(false));
  }, [refresh]);

  return (
    <div>
      <Alert
        className='fixed-top text-center'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%'
        }}
        color={alert.color}
        isOpen={alert.visible}
      >
        {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
      </Alert>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb bg-transparent p-1'>
          <Link
            className='breadcrumb-item'
            to={props.routeProps.location.pathname.substring(
              0,
              props.routeProps.location.pathname.lastIndexOf('/')
            )}
          >
            Candidatos inscritos
          </Link>

          <Link
            className='breadcrumb-item active'
            aria-current='page'
            to={props.routeProps.location.pathname}
          >
            Detalle Candidato
          </Link>
        </ol>
      </nav>
      <div className='card-user card '>
        <LoadingOverlay
          active={loading || saving}
          spinner={<SyncLoader loading={loading || saving} color='#51cbce' />}
          text={loading === true ? 'Cargando...' : 'Guardando...'}
          styles={{
            overlay: (base: any) => ({
              ...base,
              borderRadius: '12px'
            })
          }}
        >
          <div className=' card-header border-bottom pb-2'>
            <h5 className='card-title mb-0 font-weight-bold' id='registro'>
              {candidacy && candidacy.name
                ? candidacy.name
                : 'Nombre y apellidos'}
            </h5>
            <Link
              to={props.routeProps.location.pathname.substring(
                0,
                props.routeProps.location.pathname.lastIndexOf('/')
              )}
              className='btn btn-outline-primary btn-sm mr-1'
            >
              Volver
            </Link>

            <a
              href={`${config.apiURL}companies/${props.company.oid}/offers/${
                props.routeProps.match.params.id
              }/candidacies/${
                props.routeProps.match.params.idCandidate
              }/document?access_token=${sessionStorage.getItem(
                'companyToken'
              )}`}
              className='btn btn-outline-primary btn-sm mr-1'
            >
              Ver CV
            </a>

            <a
              href=''
              className='btn btn-outline-primary btn-sm'
              onClick={(e: any) => {
                e.preventDefault();
                setStateModal(true);
              }}
            >
              Cambiar estado
            </a>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-6'>
                <div className='form-group'>
                  <label className='mb-0'>Estado:</label>
                  {candidacy && candidacy.state === 0 ? (
                    <span className='p-2 '>
                      {' '}
                      <i
                        className='fas fa-sm fa-clock '
                        data-toggle='tooltip'
                        title='Por leer'
                      ></i>{' '}
                      Por leer
                    </span>
                  ) : null}
                  {candidacy && candidacy.state === 1 ? (
                    <span className='p-2 '>
                      {' '}
                      <i
                        className='fas fa-sm fa-tag '
                        data-toggle='tooltip'
                        title='En proceso'
                      ></i>{' '}
                      En proceso
                    </span>
                  ) : null}
                  {candidacy && candidacy.state === 2 ? (
                    <span className='p-2 '>
                      <i
                        className='fas fa-sm fa-check '
                        data-toggle='tooltip'
                        title='Seleccionado/a'
                      ></i>{' '}
                      Seleccionado/a
                    </span>
                  ) : null}
                  {candidacy && candidacy.state === 3 ? (
                    <span className='p-2 '>
                      {' '}
                      <i
                        className='fas fa-sm fa-trophy'
                        data-toggle='tooltip'
                        title='Finalista'
                      ></i>{' '}
                      Finalista
                    </span>
                  ) : null}
                  {candidacy && candidacy.state === 4 ? (
                    <span className='p-2 '>
                      {' '}
                      <i
                        className='fas fa-sm fa-user-slash'
                        data-toggle='tooltip'
                        title='Descartado'
                      ></i>{' '}
                      Descartado
                    </span>
                  ) : null}
                </div>

                <div className='form-group'>
                  <label className='mb-0'>Correo electrónico</label>
                  <input
                    className='form-control'
                    name='email'
                    type='email'
                    required
                    readOnly
                    value={candidacy && candidacy.email ? candidacy.email : ''}
                  />
                </div>
                <div className='form-group'>
                  <label className='mb-0'>Nivel de estudios</label>
                  <input
                    className='form-control'
                    type='text'
                    value={
                      candidacy && candidacy.educationLevel
                        ? candidacy.educationLevel.text
                        : 'Sin especificar'
                    }
                    required
                    readOnly
                  />
                </div>

                <div className='form-group'>
                  <label className='mb-0'>Lugar de nacimiento</label>
                  <input
                    className='form-control'
                    type='text'
                    value={
                      candidacy && candidacy.bornIb
                        ? candidacy.bornIb
                        : 'Sin especificar'
                    }
                    required
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label className='mb-0'>Lugar de residencia</label>
                  <input
                    className='form-control'
                    type='text'
                    value={
                      candidacy && candidacy.residence
                        ? candidacy.residence
                        : 'Sin especificar'
                    }
                    required
                    readOnly
                  />
                </div>
              </div>
              <div className='col-6'>
                <Form
                  onSubmit={putCandidacy}
                  render={({ handleSubmit, pristine, submitting, values }) => (
                    <form onSubmit={handleSubmit}>
                      <div className='form-group'>
                        <label className='mb-0'>
                          Notas internas (Opcional)
                        </label>
                        <Field
                          component='textarea'
                          name='notes'
                          className='form-control'
                          id='exampleFormControlTextarea1'
                          initialValue={
                            candidacy && candidacy.notes ? candidacy.notes : ''
                          }
                        ></Field>
                      </div>
                      <button
                        type='submit'
                        className='btn btn-round btn-primary'
                        disabled={pristine || submitting}
                      >
                        Guardar Cambios
                      </button>
                    </form>
                  )}
                />
              </div>

              {candidacy && candidacy.offerKillerQuestions.length > 0 && (
                <div className='col-12 mt-4'>
                  <h6 className='card-title'>Preguntas de filtrado</h6>

                  <div>
                    <p>
                      Resultado:{' '}
                      <span className='badge badge-lg badge-primary'>
                        {candidacy.result}/{candidacy.total}
                      </span>
                    </p>

                    {candidacy.offerKillerQuestions.map(
                      (offerQuestion: any, key: number) => {
                        return (
                          <div key={key}>
                            <p className='mb-2 mt-3'>
                              {offerQuestion.question}
                            </p>
                            {offerQuestion.type === 0 && (
                              <div className='form-group mb-3'>
                                <textarea
                                  className='form-control'
                                  readOnly
                                  value={
                                    candidacy.candidacyAnswers.find(
                                      (candidacyAnswer: any) =>
                                        candidacyAnswer.questionOid ==
                                        offerQuestion.oid
                                    ).openAnswer || ''
                                  }
                                ></textarea>
                              </div>
                            )}

                            {offerQuestion.type === 1 && (
                              <div className='form-group mb-3'>
                                {offerQuestion.answerOptions.length &&
                                  offerQuestion.answerOptions.map(
                                    (answerOption: any, key: number) => {
                                      return (
                                        <div className='form-check'>
                                          <label className='form-check-label'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='exampleRadios'
                                              checked={candidacy.candidacyAnswers.some(
                                                (candidacyAnswer: any) =>
                                                  candidacyAnswer.questionOid ==
                                                    offerQuestion.oid &&
                                                  candidacyAnswer.answerOptionOid ==
                                                    answerOption.oid
                                              )}
                                              disabled
                                            />
                                            {answerOption.option}
                                            <span className='circle'>
                                              <span className='check'></span>
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            )}

                            {offerQuestion.type === 2 && (
                              <div className='form-group mb-3'>
                                {offerQuestion.answerOptions.length &&
                                  offerQuestion.answerOptions.map(
                                    (answerOption: any, key: number) => {
                                      return (
                                        <div className='form-check'>
                                          <label className='form-check-label'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              checked={candidacy.candidacyAnswers.some(
                                                (candidacyAnswer: any) =>
                                                  candidacyAnswer.questionOid ==
                                                    offerQuestion.oid &&
                                                  candidacyAnswer.answerOptionOid ==
                                                    answerOption.oid
                                              )}
                                              disabled
                                            />
                                            {answerOption.option}
                                            <span className='form-check-sign'>
                                              <span className='check'></span>
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </LoadingOverlay>
      </div>

      {/* PopUp cambiar estado */}
      <Modal isOpen={stateModal} toggle={() => setStateModal(!stateModal)}>
        <ModalHeader toggle={() => setStateModal(!stateModal)}>
          Cambiar estado
        </ModalHeader>
        <ModalBody>
          <Form
            initialValues={{
              state: { enumValue: null, text: '' },
              discardedReason: { enumValue: null, text: '' },
              reason: ''
            }}
            onSubmit={postCandidacyState}
            render={({
              handleSubmit,
              initialValues,
              pristine,
              submitting,
              values,
              form
            }) => (
              <form onSubmit={handleSubmit}>
                <div className=' card-body'>
                  <p>
                    Estado actual:{' '}
                    {
                      candidacyStateOptions.find(
                        item => candidacy.state == item.enumValue
                      ).text
                    }
                  </p>

                  <div className='form-group'>
                    <label>Cambiar estado a</label>
                    <Field
                      validate={value =>
                        value && isNumber(value.enumValue)
                          ? undefined
                          : 'Rellene este campo para continuar'
                      }
                      className='form-control'
                      name='state'
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={
                                !candidacyStateOptions
                                  ? 'Cargando...'
                                  : 'No se han encontrado resultados.'
                              }
                              inputProps={{
                                className:
                                  field.meta.error && field.meta.submitFailed
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                              }}
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              filterBy={(option: any, props) => {
                                if (props.selected.length) {
                                  // Display all the options if there's a selection.
                                  return true;
                                }
                                // Otherwise filter on some criteria.
                                return (
                                  option.text
                                    .toLowerCase()
                                    .indexOf(props.text.toLowerCase()) !== -1
                                );
                              }}
                              id='stateTypeahead'
                              placeholder='Selecciona...'
                              options={candidacyStateOptions}
                              onChange={(value: any) => {
                                field.input.onChange(value[0]);
                              }}
                            />
                            {field.meta.error && field.meta.touched && (
                              <div
                                className='invalid-feedback'
                                style={{ display: 'block' }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>

                  {values.state.enumValue == 4 && (
                    <div className='form-group'>
                      <label>Seleccione un motivo *</label>

                      <Field
                        validate={value =>
                          value && isNumber(value.enumValue)
                            ? undefined
                            : 'Rellene este campo para continuar'
                        }
                        className='form-control'
                        name='discardedReason'
                        render={(field: any) => {
                          return (
                            <div>
                              <Typeahead
                                emptyLabel={
                                  !candidacyStateOptions
                                    ? 'Cargando...'
                                    : 'No se han encontrado resultados.'
                                }
                                inputProps={{
                                  className:
                                    field.meta.error && field.meta.submitFailed
                                      ? 'form-control is-invalid'
                                      : 'form-control'
                                }}
                                labelKey={(option: any) => {
                                  return option.text ? option.text : undefined;
                                }}
                                filterBy={(option: any, props) => {
                                  if (props.selected.length) {
                                    // Display all the options if there's a selection.
                                    return true;
                                  }
                                  // Otherwise filter on some criteria.
                                  return (
                                    option.text
                                      .toLowerCase()
                                      .indexOf(props.text.toLowerCase()) !== -1
                                  );
                                }}
                                id='discardedReasonTypeahead'
                                placeholder='Selecciona...'
                                options={candidacyDiscardedOptions}
                                onChange={(value: any) => {
                                  field.input.onChange(value[0]);
                                }}
                                defaultSelected={[]}
                              />
                              {field.meta.error && field.meta.touched && (
                                <div
                                  className='invalid-feedback'
                                  style={{ display: 'block' }}
                                >
                                  {field.meta.error}
                                </div>
                              )}
                            </div>
                          );
                        }}
                      />
                    </div>
                  )}

                  <div className='form-group'>
                    <label>
                      {values.state.enumValue === 4 &&
                      values.discardedReason.enumValue == 5
                        ? 'Describelo aquí *'
                        : 'Motivo (Opcional)'}
                    </label>

                    {!(
                      values.state.enumValue == 4 &&
                      values.discardedReason.enumValue == 5
                    ) && (
                      <Field
                        component='textarea'
                        name='reason'
                        className='form-control'
                      />
                    )}

                    {values.state.enumValue == 4 &&
                      values.discardedReason.enumValue == 5 && (
                        <div>
                          <Field
                            validate={required}
                            component='textarea'
                            name='reason'
                            className={
                              form.getFieldState('reason') &&
                              form.getFieldState('reason').error &&
                              form.getFieldState('reason').submitFailed
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            initialValue={
                              candidacy && candidacy.reason
                                ? candidacy.reason
                                : ''
                            }
                          />

                          <div className='invalid-feedback'>
                            {form.getFieldState('reason') &&
                              form.getFieldState('reason').error}
                          </div>
                        </div>
                      )}
                  </div>

                  {/* <div className="form-group">
                    <label>Describelo aquí *</label>
                    <textarea className="form-control" name="name" required />
                    <div className="invalid-feedback">
                      Añada el motivo para continuar.
                    </div>
                  </div> */}

                  <button type='submit' className='btn btn-sm btn-primary'>
                    Cambiar estado
                  </button>
                </div>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CandidateDetail;
