import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch } from 'react-router-dom';

import DemoNavbar from '../components/Navbars/DemoNavbar';
import Sidebar from '../components/Sidebar/Sidebar';
import PrivateView from '../views/Private';

import getRoutes from '../routes';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

var ps: any;

class Dashboard extends React.Component<any, any> {
  mainPanel: any;
  routes: any[];
  constructor(props: any) {
    super(props);
    this.mainPanel = React.createRef();
    this.routes = getRoutes(this.props.mode);
  }
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.body.classList.toggle('perfect-scrollbar-on');
    }
  }
  componentDidUpdate(e: any) {
    // if (e.history.action === "PUSH" && document.scrollingElement) {
    this.mainPanel.current.scrollTop = 0;
    if (document.scrollingElement != null) {
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {
    return (
      <div className='wrapper'>
        <Sidebar {...this.props} routes={this.routes} />
        <div className='main-panel' ref={this.mainPanel}>
          <DemoNavbar {...this.props} />
          {/* Main Layout */}
          <TransitionGroup>
            <CSSTransition
              key={this.props.location.key}
              classNames='fade'
              timeout={{ enter: 300, exit: 300 }}
            >
              <Switch location={this.props.location}>
                <Route
                  path='/private'
                  render={() => <PrivateView {...this.props} />}
                ></Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
          {/* <Footer fluid /> */}
        </div>
      </div>
    );
  }
}

export default Dashboard;
