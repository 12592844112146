export function selectMultipleElements(
  source: any[],
  itemElements: any[]
): any[] {
  var elements: any[] = [];

  source.forEach((item: any) => {
    if (item.isCustomValue == true) {
      elements.push(item);
    } else {
      itemElements.forEach((element: any) => {
        if (
          element &&
          (element.categoryOid === item.oid || element.oid === item.oid)
        ) {
          elements.push(item);
        }
      });
    }
  });

  return elements;
}

export function selectSingleElement(source: any[], element: any): any[] {
  var elements: any[] = [];

  source.forEach((item: any) => {
    if (
      element &&
      (element.isCustomValue ||
        element.categoryOid === item.oid ||
        element.oid === item.oid)
    ) {
      elements.push(item);
    }
  });
  return elements[0];
}
