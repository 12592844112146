import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

var ps: any;

class Sidebar extends React.Component<any, any> {
  sidebar: any;
  constructor(props: any) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName: any) {
    let className = "";

    if (this.props.location.pathname == "/private" && routeName == "") {
      return "active";
    }

    return this.props.location.pathname.indexOf(routeName) > -1 &&
      routeName != ""
      ? "active"
      : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar" data-color="white" data-active-color="info">
        <div className="logo">
          <Link className="logo-img" to="/">
            <img
              src={require("../../assets/img/logo_worketik.png")}
              alt="Logo Worketik"
              className="d-block mt-3 mb-3 worketik_logo"
            />
          </Link>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
            {this.props.routes.map((prop: any, key: any) => {
              if (prop.isMenuItem) {
                return (
                  <li
                    className={
                      this.activeRoute(prop.path) +
                      (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName={this.activeRoute(prop.path)}
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              }
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
