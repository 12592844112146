import React, { useState, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  required,
  atLeastOne,
  validCreatedOn,
  composeValidators,
  validCIFFormat
} from '../../utils/validations';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from '../../utils/Constants';

const Company: React.FC<any> = (props: any) => {
  const [logo, setLogo] = useState(0);

  const changeLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    let body = new FormData();
    body.append('file', event.target.files[0]);

    fetch(`${config.apiURL}companies/${props.oid}/logo`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      },
      body: body
    }).then(
      (response: Response) => {
        if (response.status === 201 || response.status === 200) {
          props.setAlert({
            text: '¡Logo actualizado!',
            color: 'success',
            visible: true
          });
          setLogo(new Date().getTime());
        } else {
          props.setAlert({
            text:
              'Ha ocurrido un error desconocido. Comprueba tu conexión a internet y vuelve a intentarlo.' +
              ' Si el error persiste, ponte en contacto con nosotros y te ayudamos.',
            color: 'danger',
            visible: true
          });
        }
      },
      () => {
        props.setAlert({
          text:
            'Ha ocurrido un error desconocido. Comprueba tu conexión a internet y vuelve a intentarlo.' +
            ' Si el error persiste, ponte en contacto con nosotros y te ayudamos.',
          color: 'danger',
          visible: true,
          dismiss: true
        });
      }
    );
  };

  return (
    <div>
      <LoadingOverlay
        active={!props.companyData}
        spinner={<SyncLoader loading={!props.companyData} color='#51cbce' />}
        text='Cargando...'
        styles={{
          overlay: (base: any) => ({
            ...base,
            borderRadius: '12px'
          })
        }}
      >
        <div className='card-header'>
          <h5 className='card-title mb-0'>Datos de la organización</h5>
        </div>
        {props.companyData && (
          <div className='card-body'>
            <Form
              mutators={{ ...arrayMutators }}
              onSubmit={props.putCompanyData}
              initialValues={props.companyData}
              render={({
                handleSubmit,
                form,
                submitting,
                values,
                pristine,
                initialValues
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group mb-0'>
                        <label className='d-block'>Logo (Opcional)</label>
                        <img
                          src={`${config.apiURL}companies/${initialValues.oid}/logo?${logo}`}
                          alt='Logo Worketik'
                          className='img-responsive mb-2 company_logo d-inline img-thumbnail mr-1'
                        />
                        <span className='d-inline'>
                          <label className='btn btn-outline-primary btn-sm btn-round text-primary align-top'>
                            Cambiar logo{' '}
                            <input type='file' hidden onChange={changeLogo} />
                          </label>
                        </span>
                      </div>

                      <div className='form-group'>
                        <Field
                          name='oid'
                          type='text'
                          component='input'
                          defaultValue={initialValues.oid}
                          hidden
                        />
                        <label>Nombre de la organización *</label>

                        <Field
                          className={
                            form.getFieldState('name') &&
                            form.getFieldState('name').error &&
                            form.getFieldState('name').submitFailed
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          name='name'
                          component='input'
                          type='text'
                          autoComplete='off'
                          validate={required}
                        />
                        <div className='invalid-feedback'>
                          {form.getFieldState('name') &&
                            form.getFieldState('name').error}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='mb-0'>CIF / NIF *</label>
                        <Field
                          validate={composeValidators(required, validCIFFormat)}
                          className={
                            form.getFieldState('id') &&
                            form.getFieldState('id').error &&
                            form.getFieldState('id').submitFailed
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          name='id'
                          component='input'
                          type='text'
                          autoComplete='off'
                        />
                        <div className='invalid-feedback'>
                          {form.getFieldState('id') &&
                            form.getFieldState('id').error}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label>Sector *</label>
                        <Field
                          validate={atLeastOne}
                          name='sectors'
                          render={(field: any) => {
                            return (
                              <div>
                                <Typeahead
                                  emptyLabel={
                                    !props.lists.sectorsList.length
                                      ? 'Cargando...'
                                      : 'No se han encontrado resultados.'
                                  }
                                  isInvalid={
                                    field.meta.error && field.meta.touched
                                  }
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={props.lists.sectorsList}
                                  placeholder='Selecciona...'
                                  onChange={(value: any) => {
                                    field.input.onChange(value);

                                    let newSubsectors: any = [];
                                    value.forEach((sector: any) => {
                                      form
                                        .getFieldState('subsectors')
                                        .value.forEach((subsector: any) => {
                                          if (
                                            subsector.parentOid == sector.oid
                                          ) {
                                            newSubsectors.push(subsector);
                                          }
                                        });
                                    });
                                    form.change('subsectors', newSubsectors);
                                  }}
                                  defaultSelected={initialValues.sectors}
                                />
                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className='invalid-feedback'
                                    style={{ display: 'block' }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        <label>Subsector *</label>
                        <Field
                          type='select'
                          validate={atLeastOne}
                          name='subsectors'
                          render={(field: any) => {
                            return (
                              <div>
                                <Typeahead
                                  emptyLabel={
                                    !props.lists.subSectorsList.length
                                      ? 'Cargando...'
                                      : 'No se han encontrado resultados.'
                                  }
                                  isInvalid={
                                    field.meta.error && field.meta.touched
                                  }
                                  maxResults={props.lists.subSectorsList.length}
                                  paginate={false}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  filterBy={(option: any, props) => {
                                    var include = false;

                                    if (
                                      form.getFieldState('sectors') &&
                                      form.getFieldState('sectors').value
                                    ) {
                                      var isFromSelectedSector = form
                                        .getFieldState('sectors')
                                        .value.find((sector: any) => {
                                          return sector.oid == option.parentOid;
                                        });

                                      var alreadySelected = field.input.value.find(
                                        (subsector: any) => {
                                          return subsector.oid == option.oid;
                                        }
                                      );

                                      include =
                                        isFromSelectedSector &&
                                        !alreadySelected;
                                    }

                                    return (
                                      include &&
                                      option.text
                                        .toLowerCase()
                                        .indexOf(props.text.toLowerCase()) !==
                                        -1
                                    );
                                  }}
                                  multiple
                                  options={props.lists.subSectorsList}
                                  placeholder='Selecciona...'
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  selected={
                                    form.getFieldState('subsectors') &&
                                    form.getFieldState('subsectors').value
                                      ? form.getFieldState('subsectors').value
                                      : []
                                  }
                                />
                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className='invalid-feedback'
                                    style={{ display: 'block' }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className='form-group'>
                        <label>Idiomas (Opcional)</label>
                        <Field
                          type='select'
                          name='languages'
                          render={(field: any) => {
                            return (
                              <Typeahead
                                id={field.input.name}
                                labelKey={(option: any) => {
                                  return option.text ? option.text : undefined;
                                }}
                                emptyLabel={
                                  !props.lists.languagesList.length
                                    ? 'Cargando...'
                                    : 'No se han encontrado resultados.'
                                }
                                multiple
                                options={props.lists.languagesList}
                                placeholder='Selecciona...'
                                onChange={(value: any) => {
                                  field.input.onChange(value);
                                }}
                                defaultSelected={initialValues.languages}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>Tamaño de la organización (Opcional)</label>
                        <Field
                          name='companySize'
                          render={(field: any) => {
                            return (
                              <Typeahead
                                emptyLabel={
                                  !props.lists.companySizesList.length
                                    ? 'Cargando...'
                                    : 'No se han encontrado resultados.'
                                }
                                id={field.input.name}
                                labelKey={(option: any) => {
                                  return option.text ? option.text : undefined;
                                }}
                                filterBy={(option, props) => {
                                  if (props.selected.length) {
                                    // Display all the options if there's a selection.
                                    return true;
                                  }
                                  // Otherwise filter on some criteria.
                                  return (
                                    option.text
                                      .toLowerCase()
                                      .indexOf(props.text.toLowerCase()) !== -1
                                  );
                                }}
                                options={props.lists.companySizesList}
                                placeholder='Selecciona...'
                                onChange={(value: any) => {
                                  field.input.onChange(value[0]);
                                }}
                                defaultSelected={
                                  initialValues.companySize
                                    ? [initialValues.companySize]
                                    : []
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        <label>Año de creación *</label>
                        {/* <label>Año de creación (Opcional)</label> */}
                        <Field
                          className={
                            form.getFieldState('createdOn') &&
                            form.getFieldState('createdOn').error &&
                            form.getFieldState('createdOn').submitFailed
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          name='createdOn'
                          component='input'
                          type='text'
                          validate={validCreatedOn}
                          defaultValue={initialValues.createdOn}
                          autoComplete='off'
                        />

                        <div className='invalid-feedback'>
                          {form.getFieldState('createdOn') &&
                            form.getFieldState('createdOn').error}
                        </div>
                      </div>
                      <div className='form-group'>
                        {/* <label>Tipo de organización (Opcional)</label> */}
                        <label>Tipo de organización *</label>
                        <Field
                          type='select'
                          name='organizationType'
                          render={(field: any) => {
                            return (
                              <Typeahead
                                emptyLabel={
                                  !props.lists.organizationTypesList.length
                                    ? 'Cargando...'
                                    : 'No se han encontrado resultados.'
                                }
                                id={field.input.name}
                                labelKey={(option: any) => {
                                  return option.text ? option.text : undefined;
                                }}
                                filterBy={(option, props) => {
                                  if (props.selected.length) {
                                    // Display all the options if there's a selection.
                                    return true;
                                  }
                                  // Otherwise filter on some criteria.
                                  return (
                                    option.text
                                      .toLowerCase()
                                      .indexOf(props.text.toLowerCase()) !== -1
                                  );
                                }}
                                options={props.lists.organizationTypesList}
                                placeholder='Selecciona...'
                                onChange={(value: any) => {
                                  field.input.onChange(value[0]);
                                }}
                                defaultSelected={
                                  initialValues.organizationType
                                    ? [initialValues.organizationType]
                                    : []
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        {/* <label>Descripción organización (Opcional)</label> */}
                        <label>Descripción organización *</label>
                        <Field
                          className='form-control'
                          name='description'
                          component='textarea'
                          type='textarea'
                          autoComplete='off'
                          rows={10}
                          defaultValue={initialValues.description}
                        />
                      </div>
                    </div>

                    <div className='col-md-12 col-sm-12'>
                      <button
                        className='btn btn-primary btn-round'
                        type='submit'
                        disabled={submitting || pristine}
                      >
                        Guardar
                      </button>
                      <br />
                      <small className='text-muted'>
                        {' '}
                        Puedes darte de baja enviando un correo a
                        worketik@worketik.com{' '}
                      </small>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default Company;
