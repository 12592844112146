import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss?v=1.1.0';
import './assets/demo/demo.css';
import './assets/css/styles.css';
import './assets/css/Typeahead.css';
import './assets/css/Typeahead-bs4.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import App from './App';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
const browserHistory = createBrowserHistory();
ReactDOM.render(
  <Router history={browserHistory}>
    <App />
  </Router>,
  document.getElementById('root')
);
