import React from "react";

const Description: React.FC = (props: any) => (

    <div className="card-user card shadow-none mb-0">
        <div className=" card-header pt-0">
            <h5 className="card-title " id="registro"><i className="nc-icon nc-briefcase-24"></i> Puesto de trabajo</h5>
        </div>
        <div className="card-body pb-0">
            <p className="mb-2">Nivel funcional: <span className="font-weight-bold"> {props.functionalLevel ? props.functionalLevel.text : null}</span></p>
            <p className="mb-2">Personal a cargo: <span className="font-weight-bold"> {props.peopleInCharge ? props.peopleInCharge.text : null}</span></p>
            <p className="mb-2">Sector: <span className="font-weight-bold"> {props.sectors && props.sectors.map((sector: any) => {
                return sector.text
            }).join(", ")}</span></p>
            <p className="mb-2">Subsector: <span className="font-weight-bold"> {props.subsectors && props.subsectors.map((subsector: any) => {
                return subsector.text
            }).join(", ")}</span></p>
            <br />
            <p className="mb-2"><span className="font-weight-bold">Descripción del puesto de trabajo:</span></p>
            <p className="mb-2">{props.description ? props.description : null}</p>
            <br />
            <p className="mb-2"><span className="font-weight-bold">Ocupaciones:</span></p>
            <p className="mb-2">{props.professions && props.professions.map((profession: any) => { return profession.text }).join(", ")}</p>
            <br />
            <p className="mb-2"><span className="font-weight-bold">Funciones:</span></p>
            <p className="mb-2">{props.functions && props.functions.map((item: any) => { return item.text }).join(", ")}</p>
            <br />
            <p className="mb-2"><span className="font-weight-bold">Competencias:</span></p>
            <p className="mb-2">{props.professionalSkills && props.professionalSkills.map((professionalSkill: any) => { return professionalSkill.text }).join(", ")}</p>
            <br />
            <p className="mb-2"><span className="font-weight-bold">Conocimientos:</span></p>
            <p className="mb-2">{props.knowledges && props.knowledges.map((knowledge: any) => { return knowledge.text }).join(", ")}</p>
        </div>
    </div>
);

export default Description;
