import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Company from '../components/CompanyDetail/Company';
import SocialBenefits from '../components/OfferDetail/SocialBenefits';
import Location from '../components/CompanyDetail/Location';
import LinkItem from '../components/CompanyDetail/LinkItem';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from '../utils/Constants';
const CompanyDetail: React.FC = (props: any) => {
  const getCompanyDetail = async (setter: any) => {
    setLoading(true);
    const result = await fetch(
      `${config.apiURL}offers/${props.routeProps.match.params.id}/company`
    );
    if (result.ok) {
      var response = await result.json();
      setter(response.item);
    }
    setLoading(false);
  };

  const [companyDetail, setCompanyDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyDetail(setCompanyDetail);
  }, []);

  const SRProps = {
    ...props,
    companyDetail
  };

  return (
    <div>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb bg-transparent p-1'>
          <Link to='/offers' className='breadcrumb-item'>
            Buscador de ofertas
          </Link>
          <Link
            to={'/offers/offer-detail/' + props.routeProps.match.params.id}
            className='breadcrumb-item'
          >
            Detalle oferta
          </Link>
          <li className='breadcrumb-item active' aria-current='page'>
            Detalle organización
          </li>
        </ol>
      </nav>
      <LoadingOverlay
        active={loading}
        spinner={<SyncLoader loading={loading} color='#51cbce' />}
        text={'Cargando...'}
        styles={{
          overlay: (base: any) => ({
            ...base,
            borderRadius: '12px'
          })
        }}
      >
        <div className='card-user card '>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-12'>
                {companyDetail && <Company {...companyDetail} />}
                {companyDetail && <SocialBenefits {...SRProps} />}
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                {companyDetail && <Location {...companyDetail} />}
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                {companyDetail && <LinkItem {...companyDetail} />}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default CompanyDetail;
