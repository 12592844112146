import React, { useEffect, useState } from 'react';
import Company from '../components/Profile/Company';
import Contact from '../components/Profile/Contact';
import SocialBenefits from '../components/Profile/SocialBenefits';
import Links from '../components/Profile/LinkItem';
import Locations from '../components/Profile/Location';
import { Alert } from 'reactstrap';
import { getError } from '../utils/errors';
import { selectMultipleElements, selectSingleElement } from '../utils/selector';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { getCategories } from '../utils/GetCategories';
import { config } from '../utils/Constants';

const Profile: React.FC = (props: any) => {
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false
  });

  //#region [effects]
  useEffect(() => {
    if (alert && alert.visible) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);
  //#endregion

  //#region COMPANY DATA
  //#region [states]
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const [companyData, setCompanyData] = useState({
    oid: props.company.oid,
    name: props.company.name,
    id: props.company.id,
    description: props.company.description,
    sectors: selectMultipleElements(
      props.lists.sectorsList,
      props.company.sectors
    ),
    subsectors: selectMultipleElements(
      props.lists.subSectorsList,
      props.company.subsectors
    ),
    languages: selectMultipleElements(
      props.lists.languagesList,
      props.company.languages
    ),
    companySize: props.company.companySize
      ? selectSingleElement(
          props.lists.companySizesList,
          props.company.companySize
        )
      : null,
    organizationType: props.company.organizationType
      ? selectSingleElement(
          props.lists.organizationTypesList,
          props.company.organizationType
        )
      : null,
    createdOn: props.company.createdOn == 0 ? null : props.company.createdOn
  });
  //#endregion

  //#region [api]
  const putCompanyData = (values: any) => {
    setLoadingCompanyData(true);

    const sectorsArray: any[] = [];
    values.sectors.forEach((item: any) => {
      sectorsArray.push({ categoryOid: item.oid });
    });

    const subsectorsArray: any[] = [];
    values.subsectors.forEach((item: any) => {
      subsectorsArray.push({ categoryOid: item.oid });
    });

    const languagesArray: any[] = [];
    values.languages.forEach((item: any) => {
      languagesArray.push({ categoryOid: item.oid });
    });

    fetch(`${config.apiURL}companies/${values.oid}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      },
      body: JSON.stringify({
        name: values.name,
        id: values.id,
        description: values.description,
        sectors: sectorsArray,
        subsectors: subsectorsArray,
        languages: languagesArray,
        companySize: values.companySize
          ? {
              categoryOid: values.companySize.oid
            }
          : null,
        organizationType: values.organizationType
          ? {
              categoryOid: values.organizationType.oid
            }
          : null,
        createdOn: values.createdOn ? values.createdOn : undefined
      })
    }).then((response: Response) => {
      response
        .json()
        .then(
          content => {
            if (response.status === 201 || response.status === 200) {
              setAlert({
                text:
                  '¡Los datos de la organización se han actualizado con éxito!',
                color: 'success',
                visible: true
              });

              props.refresher(new Date().getTime());
              setCompanyData({
                oid: content.item.oid,
                name: content.item.name,
                id: content.item.id,
                description: content.item.description,
                sectors: selectMultipleElements(
                  props.lists.sectorsList,
                  content.item.sectors
                ),
                subsectors: selectMultipleElements(
                  props.lists.subSectorsList,
                  content.item.subsectors
                ),
                languages: selectMultipleElements(
                  props.lists.languagesList,
                  content.item.languages
                ),
                companySize: selectSingleElement(
                  props.lists.companySizesList,
                  content.item.companySize
                ),
                organizationType: selectSingleElement(
                  props.lists.organizationTypesList,
                  content.item.organizationType
                ),
                createdOn:
                  content.item.createdOn == 0 ? null : content.item.createdOn
              });
            } else {
              setAlert({
                text: getError(content.errors),
                color: 'danger',
                visible: true
              });
            }
          },
          () => {
            setAlert({
              text:
                'Ha ocurrido un error desconocido. Comprueba tu conexión a internet y vuelve a intentarlo.' +
                ' Si el error persiste, ponte en contacto con nosotros y te ayudamos.',
              color: 'danger',
              visible: true
            });
          }
        )

        .then(() => setLoadingCompanyData(false));
    });
  };
  //#endregion
  //#endregion

  //#region CONTACT DATA
  //#region [states]
  const [loadingContactData, setLoadingContactData] = useState(false);
  const [contactData, setContactData] = useState({
    oid: props.company.oid,
    contactName: props.company.person.name,
    position: props.company.person.position,
    email: props.company.person.email,
    phone: props.company.person.phone
  });
  //#endregion

  //#region [api]
  const putContactData = (values: any) => {
    setLoadingContactData(true);
    fetch(`${config.apiURL}companies/${values.oid}/person`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      },
      body: JSON.stringify({
        person: {
          name: values.contactName,
          position: values.position,
          email: values.email,
          phone: values.phone
        }
      })
    }).then((response: Response) => {
      response
        .json()
        .then(
          content => {
            if (response.status === 201 || response.status === 200) {
              setAlert({
                text:
                  '¡Los datos de la persona de contacto se han actualizado con éxito!',
                color: 'success',
                visible: true
              });

              props.refresher(new Date().getTime());
              setContactData({
                oid: content.item.oid,
                contactName: content.item.person.name,
                position: content.item.person.position,
                email: content.item.person.email,
                phone: content.item.person.phone
              });
            } else {
              setAlert({
                text: getError(content.errors),
                color: 'danger',
                visible: true
              });
            }
          },
          () => {
            setAlert({
              text:
                'Ha ocurrido un error desconocido. Comprueba tu conexión a internet y vuelve a intentarlo.' +
                ' Si el error persiste, ponte en contacto con nosotros y te ayudamos.',
              color: 'danger',
              visible: true
            });
          }
        )
        .then(() => setLoadingContactData(false));
    });
  };
  //#endregion
  //#endregion

  //#region SOCIAL BENEFITS
  //#region [states]
  const [loadingSocialR, setLoadingSocialR] = useState(false);
  const [socialBenefitsData, setSocialBenefitsData] = useState(
    getSocialBenefits(props.company, props.lists.SRList)
  );
  //#endregion

  //#region [api]
  const putSocialResponsibilities = () => {
    setLoadingSocialR(true);
    fetch(
      `${config.apiURL}companies/${props.company.oid}/socialresponsibilities`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        },
        body: JSON.stringify({
          socialResponsibilities: socialBenefitsData.map((item: any) => {
            return { categoryOid: item.oid };
          })
        })
      }
    ).then((response: Response) => {
      response
        .json()
        .then(
          content => {
            if (response.status === 201 || response.status === 200) {
              setAlert({
                text:
                  '¡Los beneficios sociales de la organización se han actualizado con éxito!',
                color: 'success',
                visible: true
              });

              props.refresher(new Date().getTime());
            } else {
              setAlert({
                text: getError(content.errors),
                color: 'danger',
                visible: true
              });
            }
          },
          () => {
            setAlert({
              text:
                'Ha ocurrido un error desconocido. Comprueba tu conexión a internet y vuelve a intentarlo.' +
                ' Si el error persiste, ponte en contacto con nosotros y te ayudamos.',
              color: 'danger',
              visible: true
            });
          }
        )
        .then(() => setLoadingSocialR(false));
    });
  };

  //#endregion
  //#endregion

  //#region  LOCATIONS
  //#region [states]
  const [locationsData, setLocationsData] = useState(props.company);
  const [loadingLocations, setLoadingLocations] = useState(false);
  //#endregion

  //#region [api]
  const postAddress = (values: any) => {
    setLoadingLocations(true);
    fetch(`${config.apiURL}companies/${props.company.oid}/addresses/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      },
      body: JSON.stringify({
        country: {
          categoryOid: values.country.oid
        },
        name: values.name,
        municipality: {
          categoryOid: values.municipality.oid,
          text: values.municipality.text
        },
        postalCode: {
          categoryOid: values.postalCode.oid,
          text: values.postalCode.text
        },
        province: {
          categoryOid: values.province.oid,
          text: values.province.text
        },
        postalAddress: values.postalAddress,
        building: values.building,
        phoneNumber1: values.phoneNumber1,
        phoneNumber2: values.phoneNumber2
      })
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setAlert({
                text: 'Dirección añadida!',
                color: 'success',
                visible: true
              });
              props.setCompany({ ...data.item });
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingLocations(false));
  };

  const putAddress = (values: any) => {
    setLoadingLocations(true);
    fetch(
      `${config.apiURL}companies/${props.company.oid}/addresses/${values.oid}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        },
        body: JSON.stringify({
          country: {
            categoryOid: values.country.oid
          },
          name: values.name,
          municipality: {
            categoryOid: values.municipality.oid,
            text: values.municipality.text
          },
          postalCode: {
            categoryOid: values.postalCode.oid,
            text: values.postalCode.text
          },
          province: {
            categoryOid: values.province.oid,
            text: values.province.text
          },
          postalAddress: values.postalAddress,
          building: values.building,
          phoneNumber1: values.phoneNumber1,
          phoneNumber2: values.phoneNumber2
        })
      }
    )
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setAlert({
                text: 'Dirección modificada!',
                color: 'success',
                visible: true
              });
              props.setCompany({ ...data.item });
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingLocations(false));
  };

  const deleteAddress = (addressOid: any) => {
    setLoadingLocations(true);
    fetch(
      `${config.apiURL}companies/${props.company.oid}/addresses/${addressOid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        }
      }
    )
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setAlert({
                text: 'Dirección borrada!',
                color: 'success',
                visible: true
              });
              props.setCompany({ ...data.item });
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingLocations(false));
  };

  //#endregion
  //#endregion

  //#region LINKS
  //#region [states]
  const [linksData, setLinksData] = useState(props.company);
  const [loadingLinks, setLoadingLinks] = useState(false);

  //#endregion
  //#region [api]
  const postLink = (values: any) => {
    setLoadingLinks(true);
    fetch(`${config.apiURL}companies/${props.company.oid}/links/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      },
      body: JSON.stringify({
        name: values.name,
        url: values.url
      })
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setAlert({
                text: '¡Enlace añadido!',
                color: 'success',
                visible: true
              });
              props.setCompany({ ...data.item });
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingLinks(false));
  };

  const putLink = (values: any) => {
    fetch(
      `${config.apiURL}companies/${props.company.oid}/links/${values.oid}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        },
        body: JSON.stringify({
          name: values.name,
          url: values.url
        })
      }
    )
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setAlert({
                text: '¡Enlace modificado!',
                color: 'success',
                visible: true
              });
              props.setCompany({ ...data.item });
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingLinks(false));
  };

  const deleteLink = (linkOid: any) => {
    setLoadingLinks(true);
    fetch(`${config.apiURL}companies/${props.company.oid}/links/${linkOid}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      }
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setAlert({
                text: '¡Enlace borrado!',
                color: 'success',
                visible: true
              });
              props.setCompany({ ...data.item });
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingLinks(false));
  };
  //#endregion
  //#endregion

  let profileProps = {
    ...props,
    companyData,
    putCompanyData,
    setAlert
  };

  let contactProps = {
    ...props,
    contactData,
    putContactData
  };

  let socialBenefitsProps = {
    ...props.company,
    ...props,
    socialBenefitsData,
    setSocialBenefitsData,
    putSocialResponsibilities
  };

  let addressesProps = {
    ...props.company,
    ...props,
    postAddress,
    putAddress,
    deleteAddress
  };

  let linksProps = {
    ...props.company,
    postLink,
    putLink,
    deleteLink
  };

  return (
    <div>
      <Alert
        className='fixed-top text-center'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%'
        }}
        color={alert.color}
        isOpen={alert.visible}
      >
        {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
      </Alert>
      <div className='content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card-user card'>
              <LoadingOverlay
                active={loadingCompanyData}
                spinner={
                  <SyncLoader loading={loadingCompanyData} color='#51cbce' />
                }
                text='Guardando...'
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    borderRadius: '12px'
                  })
                }}
              >
                <Company {...profileProps} />
              </LoadingOverlay>
            </div>
          </div>

          <div className='col-md-12'>
            <div className='card-user card'>
              <LoadingOverlay
                active={loadingContactData}
                spinner={
                  <SyncLoader loading={loadingContactData} color='#51cbce' />
                }
                text='Guardando...'
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    borderRadius: '12px'
                  })
                }}
              >
                <Contact {...contactProps} />
              </LoadingOverlay>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='card-user card'>
              <LoadingOverlay
                active={loadingSocialR}
                spinner={
                  <SyncLoader loading={loadingSocialR} color='#51cbce' />
                }
                text='Guardando...'
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    borderRadius: '12px'
                  })
                }}
              >
                <SocialBenefits {...socialBenefitsProps} />
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <LoadingOverlay
            active={loadingLocations}
            spinner={<SyncLoader loading={loadingLocations} color='#51cbce' />}
            text='Guardando...'
            styles={{
              overlay: (base: any) => ({
                ...base,
                borderRadius: '12px'
              })
            }}
          >
            <Locations {...addressesProps} />
          </LoadingOverlay>
        </div>
        <div className='col-md-6'>
          <LoadingOverlay
            active={loadingLinks}
            spinner={<SyncLoader loading={loadingLinks} color='#51cbce' />}
            text='Guardando...'
            styles={{
              overlay: (base: any) => ({
                ...base,
                borderRadius: '12px'
              })
            }}
          >
            <Links {...linksProps} />
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
};

export default Profile;

function getSocialBenefits(company: any, pool: any) {
  let companySocialResponsibilities: any[] = [];

  if (company.socialResponsibilities) {
    company.socialResponsibilities.forEach((SRfromEntity: any) => {
      pool.forEach((SRfromPool: any) => {
        if (SRfromEntity.categoryOid === SRfromPool.oid) {
          companySocialResponsibilities.push(SRfromPool);
        }
      });
    });
  }

  return companySocialResponsibilities;
}
