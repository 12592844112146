import * as React from 'react';
import { Route, Link } from 'react-router-dom';

const NavItem: React.FC<navItemProps> = props => {
  return (
    <Route
      path={props.to}
      exact={props.exact}
      children={(routeProps: any) => {
        return (
          <li
            className={
              routeProps.match && routeProps.match.isExact
                ? 'nav-item active'
                : 'nav-item'
            }
            style={{ borderColor: 'white', border: '1px' }}
          >
            <Link className='nav-link ml-auto' to={props.to}>
              {props.children}
            </Link>
          </li>
        );
      }}
    />
  );
};

export default NavItem;

interface navItemProps {
  to: string;
  exact?: boolean;
  children?: any;
}
