import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EnrolComponent from '../components/Enrol';
import { Alert } from 'reactstrap';
import { getError } from '../utils/errors';
import { getCategories } from '../utils/GetCategories';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from '../utils/Constants';

const Enrol: React.FC = (props: any) => {
  const [alert, setAlert] = useState(null);
  const [success, setSuccess] = useState(false);
  const [spinnerProps, setSpinnerProps] = useState({
    loading: true,
    text: 'Cargando...',
  });

  const [educationLevelList, setEducationLevelList] = useState([]);

  useEffect(() => {
    getCategories('educationLevel', setEducationLevelList);
  }, []);

  useEffect(() => {
    if (alert && alert.visible && !alert.dismiss) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  const getOfferDetail = async (setter: any) => {
    setSpinnerProps({ loading: true, text: 'Cargando...' });
    const result = await fetch(
      `${config.apiURL}offers/${props.routeProps.match.params.id}`
    );
    if (result.ok) {
      var response = await result.json();
      setter(response.item);
    }
    setSpinnerProps({ loading: false, text: '' });
  };

  useEffect(() => {
    getOfferDetail(setOfferDetail);
  }, []);

  const [file, setFile] = useState(null);

  const postCandidacy = (values: any) => {
    setSpinnerProps({ loading: true, text: 'Presentando candidatura...' });
    var answersArray: any[] = [];
    offerDetail.killerQuestions.forEach((KQ: any) => {
      if (values[KQ.oid] && KQ.type === 0) {
        answersArray.push({
          questionOid: KQ.oid,
          openAnswer: values[KQ.oid],
        });
      }
      if (values[KQ.oid] && KQ.type === 1) {
        answersArray.push({
          questionOid: KQ.oid,
          answerOptionOid: values[KQ.oid],
        });
      }
      KQ.answerOptions.forEach((answer: any) => {
        if (values[answer.oid])
          answersArray.push({
            questionOid: KQ.oid,
            answerOptionOid: answer.oid,
          });
      });
    });

    let body = new FormData();
    body.append('file', file);
    body.append(
      'data',
      JSON.stringify({
        name: values.name,
        email: values.email,
        educationLevelOid: values.educationLevel,
        phone: values.phone,
        // bornIn: values.bornIn,
        // residence: values.residence,
        answerOptions: answersArray,
      })
    );

    fetch(
      `${config.apiURL}offers/${props.routeProps.match.params.id}/candidacies`,
      {
        method: 'POST',
        body: body,
      }
    ).then((response: any) => {
      setSpinnerProps({ loading: false, text: '' });
      if (response.ok) {
        response.json().then((data: any) => {
          setSuccess(true);
          setAlert({
            text: '¡Te has inscrito a la oferta!',
            color: 'success',
            visible: true,
          });
          setEnrolState(data.item.state);
        });
      } else {
        setSuccess(false);
        setAlert({
          text: getError(),
          color: 'danger',
          visible: true,
        });
      }
    });
  };
  const [offerDetail, setOfferDetail] = useState(null);
  const [enrolState, setEnrolState] = useState(null);
  const enrolProps = {
    ...props,
    postCandidacy,
    offerDetail,
    enrolState,
    file,
    educationLevelList,
    setFile,
    success,
    setAlert,
  };

  return (
    <div>
      {alert && alert.color === 'danger' && (
        <Alert
          toggle={() => {
            setAlert({ visible: false, color: '', text: '' });
          }}
          className='fixed-top text-center'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
          }}
          color={alert.color}
          isOpen={alert.visible}
        >
          {alert.text}
        </Alert>
      )}
      {alert && alert.color !== 'danger' && (
        <Alert
          className='fixed-top text-center'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
          }}
          color={alert.color}
          isOpen={alert.visible}
        >
          <b> {alert.text}</b>
        </Alert>
      )}
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb bg-transparent p-1'>
          <Link to='/offers' className='breadcrumb-item'>
            Buscador de ofertas
          </Link>
          <Link
            to={'/offers/offer-detail/' + props.routeProps.match.params.id}
            className='breadcrumb-item'
          >
            Detalle oferta
          </Link>
          <li className='breadcrumb-item active' aria-current='page'>
            Inscripción oferta
          </li>
        </ol>
      </nav>
      <LoadingOverlay
        active={spinnerProps.loading}
        spinner={<SyncLoader loading={spinnerProps.loading} color='#51cbce' />}
        text={spinnerProps.text}
        styles={{
          overlay: (base: any) => ({
            ...base,
            borderRadius: '12px',
          }),
        }}
      >
        <EnrolComponent {...enrolProps} />
      </LoadingOverlay>
    </div>
  );
};

export default Enrol;
