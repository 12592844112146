import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Field } from 'react-final-form';
import { Button } from 'reactstrap';
import {
  composeValidators,
  validEmailFormat,
  required,
  validFileFormat,
  validFileSize,
  atLeastOne,
  validPhoneNumber,
} from '../utils/validations';
const Enrol: React.FC<any> = (props: any) => {
  const documentRef = useRef(null);
  if (props.enrolState === null) {
    debugger;
    return (
      <div>
        <div className='card-user card '>
          <div className=' card-header border-bottom pb-2'>
            <h5 className='card-title mb-0 font-weight-bold' id='registro'>
              {props.offerDetail ? props.offerDetail.name + ' ' : null}
              {props.offerDetail
                ? '(' + props.offerDetail.companyName + ')'
                : null}
            </h5>
            <p className='mb-2'>
              <span>Inscripción oferta</span>
            </p>
          </div>
          <div className='card-body'>
            <Form
              onSubmit={(values) => {
                props.postCandidacy(values);
              }}
              render={({ handleSubmit, form, submitting, values, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-12 mt-3'>
                      <h6 className='card-title'>Datos candidato</h6>
                    </div>
                    <div className='col-6'>
                      <div className='form-group'>
                        <label className='mb-0'>Nombre y apellidos *</label>
                        <Field
                          name='name'
                          component='input'
                          className={
                            form.getFieldState('name') &&
                            form.getFieldState('name').error &&
                            form.getFieldState('name').submitFailed
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          autoComplete='off'
                          type='text'
                          validate={required}
                        />
                        <div className='invalid-feedback'>
                          {form.getFieldState('name') &&
                            form.getFieldState('name').error}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='mb-0'>Correo electrónico *</label>
                        <Field
                          validate={composeValidators(
                            required,
                            validEmailFormat
                          )}
                          component='input'
                          className={
                            form.getFieldState('email') &&
                            form.getFieldState('email').error &&
                            form.getFieldState('email').submitFailed
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          name='email'
                          type='email'
                          required
                        />
                        <div className='invalid-feedback'>
                          {form.getFieldState('email') &&
                            form.getFieldState('email').error}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='mb-0'>Documento currículum </label>

                        <Field
                          name='file'
                          validate={composeValidators(
                            required,
                            validFileFormat,
                            validFileSize
                          )}
                          render={(field) => {
                            return (
                              <div>
                                <div className='custom-file'>
                                  <input
                                    ref={documentRef}
                                    type='file'
                                    className='custom-file-input'
                                    id='customFile'
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      props.setFile(event.target.files[0]);
                                      field.input.onChange(
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                  <label className='custom-file-label'>
                                    {props.file
                                      ? props.file.name
                                      : 'Examinar...'}
                                  </label>

                                  <small
                                    id='emailHelp'
                                    className='form-text text-muted'
                                  >
                                    Añade un documento word o pdf que no supere
                                    los 4MB.
                                  </small>
                                  <br />
                                  {props.file && (
                                    <small
                                      id='delete'
                                      className='form-text text-muted'
                                    >
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();

                                          documentRef.current.value = null;
                                          props.setFile(null);
                                          form.change('file', null);
                                        }}
                                      >
                                        Eliminar
                                      </a>
                                    </small>
                                  )}
                                </div>

                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className='invalid-feedback'
                                    style={{ display: 'block' }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        ></Field>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='form-group'>
                        <label className='mb-0'>Nivel de estudios *</label>
                        <Field
                          className={
                            form.getFieldState('educationLevel') &&
                            form.getFieldState('educationLevel').error &&
                            form.getFieldState('educationLevel').submitFailed
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          validate={atLeastOne}
                          name='educationLevel'
                          render={(field: any) => {
                            return (
                              <div>
                                <Typeahead
                                  isLoading={!props.educationLevelList.length}
                                  emptyLabel={
                                    !props.educationLevelList.length
                                      ? 'Cargando...'
                                      : 'No se han encontrado resultados.'
                                  }
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text;
                                  }}
                                  options={props.educationLevelList}
                                  placeholder='Elige un nivel de estudios...'
                                  onChange={(value: any) => {
                                    form.change(
                                      'educationLevel',
                                      value.length ? value[0].oid : null
                                    );
                                  }}
                                />
                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className='invalid-feedback'
                                    style={{ display: 'block' }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='mb-0'>Teléfono *</label>
                        <Field
                          className={
                            form.getFieldState('phone') &&
                            form.getFieldState('phone').error &&
                            form.getFieldState('phone').submitFailed
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          name='phone'
                          component='input'
                          type='text'
                          autoComplete='off'
                          validate={composeValidators(
                            required,
                            validPhoneNumber
                          )}
                        />
                        <div className='invalid-feedback'>
                          {form.getFieldState('phone') &&
                            form.getFieldState('phone').error}
                        </div>
                      </div>
                      {/*                       
                      <div className="form-group">
                        <label className="mb-0">Lugar de nacimiento *</label>
                        <Field
                          validate={required}
                          name="bornIn"
                          component="input"
                          className={
                            form.getFieldState("bornIn") &&
                            form.getFieldState("bornIn").error &&
                            form.getFieldState("bornIn").submitFailed
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="text"
                          autoComplete="off"
                        />
                        <div className="invalid-feedback">
                          {form.getFieldState("bornIn") &&
                            form.getFieldState("bornIn").error}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Lugar de residencia *</label>
                        <Field
                          name="residence"
                          component="input"
                          className={
                            form.getFieldState("residence") &&
                            form.getFieldState("residence").error &&
                            form.getFieldState("residence").submitFailed
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="text"
                          autoComplete="off"
                          validate={required}
                        />
                        <div className="invalid-feedback">
                          {form.getFieldState("residence") &&
                            form.getFieldState("residence").error}
                        </div>
                      </div>
                   
                    */}
                    </div>
                    <div className='col-12 mt-4'>
                      {props.offerDetail &&
                        props.offerDetail.killerQuestions.length > 0 && (
                          <h6 className='card-title'>Preguntas de filtrado</h6>
                        )}
                      {props.offerDetail &&
                        props.offerDetail.killerQuestions.map((KQ: any) => {
                          return (
                            <div>
                              <p className='mb-2 mt-3'>{KQ.question}</p>
                              {KQ.type === 2 ? (
                                <div className='form-group mb-3'>
                                  {KQ.answerOptions.map(
                                    (answer: any, i: any) => {
                                      return (
                                        <div key={i} className='form-check'>
                                          <label className='form-check-label'>
                                            <Field
                                              labelkey={answer.oid}
                                              id={answer.oid}
                                              name={answer.oid}
                                              className='form-check-input'
                                              type='checkbox'
                                              component='input'
                                              checked={undefined}
                                            />
                                            {answer.option}
                                            <span className='form-check-sign'>
                                              <span className='check'></span>
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : KQ.type === 1 ? (
                                <div className='form-group mb-3'>
                                  {KQ.answerOptions.map((answer: any) => {
                                    return (
                                      <div className='form-check'>
                                        <label className='form-check-label'>
                                          <Field
                                            validate={(value) => {}}
                                            className='form-check-input'
                                            name={KQ.oid}
                                            component='input'
                                            type='radio'
                                            value={answer.oid}
                                            required
                                          />{' '}
                                          {answer.option}
                                          <span className='circle'>
                                            <span className='check'></span>
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : KQ.type === 0 ? (
                                <div className='form-group mb-3'>
                                  <Field
                                    name={KQ.oid}
                                    className='form-control'
                                    component='textarea'
                                    required
                                  />
                                  <div className='invalid-feedback'>
                                    Conteste para continuar.
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      <p className='text-muted mt-4'>
                        {' '}
                        WORKETIK PORTAL,S.L como responsable del tratamiento
                        tratará tus datos con la finalidad de dar respuesta a
                        petición de inscripción a una oferta de trabajo. Puedes
                        acceder, rectificar y suprimir tus datos, así como
                        ejercer otros derechos consultando la información
                        adicional y detallada sobre protección de datos en
                        nuestra{' '}
                        <Link to='/privacy-policy' target='_blank'>
                          política de privacidad
                        </Link>{' '}
                      </p>
                      <div className='form-group mb-1'>
                        <div className='form-check mb-3'>
                          <label className='form-check-label'>
                            <Field
                              validate={(value) =>
                                value
                                  ? undefined
                                  : 'Debes aceptar las condiciones para continuar.'
                              }
                              name='accept'
                              component='input'
                              className='form-check-input'
                              type='checkbox'
                            />
                            <span className='form-check-sign'></span>
                            <span className='text-muted'>
                              He leído y acepto las condiciones contenidas en la{' '}
                              <Link to='/privacy-policy' target='_blank'>
                                política de privacidad
                              </Link>{' '}
                              sobre el tratamiento de mis datos para gestionar
                              mi petición de inscripción en la oferta de
                              trabajo.
                              {values.accept}
                              {form.getFieldState('accept') &&
                                form.getFieldState('accept').error &&
                                form.getFieldState('accept').submitFailed && (
                                  <div
                                    className='invalid-feedback'
                                    style={{ display: 'block' }}
                                  >
                                    {form.getFieldState('accept').error}
                                  </div>
                                )}
                            </span>
                          </label>
                        </div>
                      </div>
                      <p className='text-muted'>
                        {' '}
                        Nos gustaría que nos prestaras tu consentimiento para:
                      </p>
                      <div className='form-group mb-3'>
                        <div className='form-check'>
                          <label className='form-check-label'>
                            <Field
                              className='custom-control-input'
                              name='sendMarketingInformation'
                              component='input'
                              type='checkbox'
                            />{' '}
                            <span className='form-check-sign'></span>
                            <span className='text-muted'>
                              Enviarte información comercial sobre los
                              productos, servicios, novedades de WORKETIK
                              PORTAL,S.L
                            </span>
                            {values.sendMarketingInformation}
                          </label>
                        </div>
                      </div>

                      <Link
                        to={'/offers/offer-detail/' + props.routeProps.match.params.id}
                        className='btn btn-outline-primary mr-1'
                      >
                        Cancelar
                      </Link>
                      <Button
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          if (!valid) {
                            props.setAlert({
                              text:
                                '¡Oh oh, algo no ha ido bien, revisa los campos del formulario!',
                              color: 'danger',
                              visible: true,
                            });
                            form.submit();
                          } else if (
                            props.offerDetail.killerQuestions.length &&
                            props.offerDetail.killerQuestions.some(
                              (KQ: any) =>
                                (KQ.type === 0 || KQ.type === 1) &&
                                !values[KQ.oid]
                            )
                          ) {
                            props.setAlert({
                              text:
                                '¡Debes contestar las preguntas de texto abierto y las de selección única!',
                              color: 'danger',
                              visible: true,
                            });
                          } else if (!submitting && valid) {
                            form.submit();
                          }
                        }}
                        disabled={submitting || props.success}
                        className='btn btn-primary '
                      >
                        Inscribirme en esta oferta
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }

  if (props.enrolState === 0) {
    return (
      <div>
        {/* Enviada correctamente */}
        <div className='card-user card '>
          <div className=' card-header'>
            <h5 className='card-title mb-0'>
              <i className='far fa-check-circle text-primary'></i>&nbsp; Tu
              inscripción se ha enviado correctamente a{' '}
              {props.offerDetail ? props.offerDetail.companyName : null}
            </h5>
          </div>
          <div className=' card-body'>
            <p className='mb-0'>
              <span>
                Recibiràs un correo con el resumen de la oferta y te
                informaremos de cualquier cambio en el estado de tu candidatura
                por correo electrónico. <br /> Muchas gracias por confiar en
                Worketik.
              </span>
            </p>
            <Link to='/' className='btn btn-primary '>
              Volver al buscador
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (props.enrolState === 4) {
    return (
      <div>
        {/* Rechazada */}
        <div className='card-user card '>
          <div className=' card-header'>
            <h5 className='card-title mb-0'>
              <i className='fas fa-user-slash text-danger'></i>&nbsp; Vaya... No
              han sido superadas las preguntas del test
            </h5>
          </div>
          <div className=' card-body'>
            <p className='mb-0'>
              <span>
                Tu candidatura ha sido rechazada ya que no han sido superadas
                las preguntas de filtrado
              </span>
            </p>
            <Link to='/' className='btn btn-primary '>
              Volver al buscador
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default Enrol;
