import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { myCompany } from "../../models/MyCompany";
import { Form, Field } from "react-final-form";

const LinkItem: React.FC<myCompany> = (props: any) => {
  const [modal, setModal] = useState(false);
  const [localInitialValues, setLocalInitialValues] = useState({
    oid: null,
    name: null,
    url: null
  });

  return (
    <div>
      <div className="card-user card">
        <div className="card-header">
          <h5 className="card-title mb-2">Enlaces </h5>
          <div className="mb-2">
            <a
              href=""
              onClick={(e: any) => {
                e.preventDefault();

                setModal(true);
                setLocalInitialValues({
                  oid: null,
                  name: null,
                  url: null
                });
              }}
            >
              + Añadir enlace
            </a>
          </div>

          <table className="table">
            <tbody>
              {props.links.map((item: any, i: any) => {
                return (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>
                      <b>{item.name}</b>
                      <br />
                      <a
                        rel="noreferrer noopener"
                        href={
                          item.url.includes("http://") ||
                          item.url.includes("https://")
                            ? item.url
                            : "//" + item.url
                        }
                        target={"_blank"}
                      >
                        {item.url}
                      </a>
                    </td>
                    <td>
                      <a
                        href=""
                        className="btn btn-outline-primary btn-sm p-1 mr-1 pull-right"
                        onClick={(e: any) => {
                          e.preventDefault();

                          setModal(true);
                          setLocalInitialValues({
                            oid: item.oid,
                            name: item.name,
                            url: item.url
                          });
                        }}
                      >
                        <i className="fas fa-edit" />
                      </a>

                      <a
                        href=""
                        className="btn btn-outline-primary btn-sm p-1 mr-1 pull-right"
                        onClick={(e: any) => {
                          e.preventDefault();

                          props.deleteLink(item.oid);
                        }}
                      >
                        <i className="fas fa-trash-alt" />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Form
        onSubmit={
          localInitialValues.oid == null ? props.postLink : props.putLink
        }
        initialValues={localInitialValues}
        render={({
          handleSubmit,
          form,
          values,
          submitting,
          pristine,
          initialValues
        }) => (
          <form>
            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
              <ModalHeader toggle={() => setModal(!modal)}>
                Añadir enlace
              </ModalHeader>

              <ModalBody>
                <div>
                  <Field
                    name="oid"
                    type="text"
                    component="input"
                    defaultValue={props.links.oid}
                    hidden
                  />
                  <div className="form-group">
                    <label>Titulo enlace *</label>
                    <Field
                      name="name"
                      type="text"
                      className="form-control"
                      component="input"
                      required
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      Rellene este campo para continuar.
                    </div>
                  </div>
                  <div className="form-group">
                    <label>URL enlace *</label>
                    <Field
                      name="url"
                      type="url"
                      className="form-control"
                      component="input"
                      required
                    />
                    <div className="invalid-feedback">
                      Rellene este campo para continuar.
                    </div>
                  </div>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  className="btn-outline-primary"
                  onClick={() => setModal(!modal)}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                    setModal(!modal);
                    form.reset();
                  }}
                  disabled={submitting || pristine}
                >
                  {initialValues.oid ? "Guardar" : "Añadir"}
                </Button>
              </ModalFooter>
            </Modal>
          </form>
        )}
      />
    </div>
  );
};
export default LinkItem;
