import React, { useState } from "react";
import { myContractedService } from "../../layouts/ContractService";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";

const Payment: React.FC<myContractedService> = (props: any) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <LoadingOverlay
          active={props.loading}
          spinner={<SyncLoader loading={props.loading} color="#51cbce" />}
          text="Cargando..."
          styles={{
            overlay: (base: any) => ({
              ...base,
              borderRadius: "12px"
            })
          }}
        >
          <div className="card-user card">
            <div className="card-header">
              <h5 className="card-title">Pagos realizados</h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead className="text-primary">
                    <tr>
                      <th>Fecha compra</th>
                      <th>Caducidad</th>
                      <th>Pack contratado</th>
                      <th className="text-center">Precio</th>
                      <th className="text-center">Und. totales</th>
                      <th className="text-center">Und. disponibles</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!props.loading &&
                      props.contractedServices.map((item: any, i: any) => {
                        return (
                          <tr key={i}>
                            <td>{new Date(item.date).toLocaleDateString()}</td>
                            <td>
                              {new Date(item.expiresOn).toLocaleDateString()}
                            </td>
                            <td>{item.name}</td>
                            <td className="text-center">{item.price}€</td>
                            <td className="text-center">
                              {item.units} semana/s{" "}
                            </td>
                            <td className="text-center">
                              {item.availableUnits} semana/s
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default Payment;
