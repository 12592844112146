import React, { useState, useEffect, useRef } from 'react';
import { EmptyResults, getTPVData } from './TPVData';
import { Button, Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { getError } from './errors';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from './Constants';

const TPV: React.FC<any> = (props: any) => {
  const [modal, setModal] = useState(false);
  const [transactionOid, setTransactionOid] = useState(null);
  const [loadingTPV, setLoadingTPV] = useState(true);
  const [dsParams, setDsParams] = useState(EmptyResults);
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false
  });

  useEffect(() => {
    if (alert && alert.visible) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  const formRef = useRef(null);

  const checkTransactionState = async () => {
    fetch(
      `${config.apiURL}companies/${props.companyId}/payments/${transactionOid}/isprocessed`,
      {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        }
      }
    ).then(
      (response: any) => {
        if (response.ok) {
          setAlert({
            text: '¡Ya tienes disponible tu nuevo pack de publicación!',
            color: 'success',
            visible: true
          });
          props.refreshTable(new Date().getTime());
          props.refreshCompany(new Date().getTime());
        } else if (response.status == 404) {
          setAlert({
            text: 'El proceso de compra se ha interrumpido',
            color: 'danger',
            visible: true
          });
        } else {
          response.json((content: any) => {
            setAlert({
              text: content.errors.join(),
              color: 'danger',
              visible: true
            });
          });
        }
      },
      () => {
        setAlert({
          text: getError(),
          color: 'danger',
          visible: true
        });
      }
    );
  };

  useEffect(() => {
    if (!modal) {
      setDsParams(EmptyResults);
    } else {
      setLoadingTPV(true);

      getTPVData(props.companyId, props.service.oid).then((response: any) => {
        debugger;
        if (response.ok) {
          response.json().then((data: any) => {
            setTransactionOid(data.item.oid);
            setDsParams({ ...data.payment });
          });
        }
      });
    }
  }, [modal]);

  useEffect(() => {
    debugger;
    if (formRef.current && dsParams != EmptyResults) {
      setLoadingTPV(false);
      formRef.current.submit();
    }
  }, [dsParams]);

  useEffect(() => {
    const onMessageReceivedEv = (event: any) => {
      if (event.origin === config.eventOrigin && event && event.data) {
        var data: any;
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          console.log(e);
        } finally {
          if (data && data.success === true) {
            setModal(false);
            setAlert({
              text: '¡Ya tienes disponible tu nuevo pack de publicación!',
              color: 'success',
              visible: true
            });

            if (props.refreshTable) {
              props.refreshTable(new Date().getTime());
            }

            props.refreshCompany(new Date().getTime());
          } else if (data && data.success === false) {
            setModal(false);
            setAlert({
              text: 'El proceso de compra se ha interrumpido',
              color: 'danger',
              visible: true
            });
          }
        }
      }
    };

    window.addEventListener('message', onMessageReceivedEv);
    return () => {
      return window.removeEventListener('message', onMessageReceivedEv);
    };
  }, []);

  return (
    <div>
      <Alert
        className='fixed-top text-center'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%'
        }}
        color={alert.color}
        isOpen={alert.visible}
      >
        {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
      </Alert>
      <Button
        disabled={!props.service}
        className='btn btn-primary'
        onClick={() => {
          setModal(!modal);
        }}
      >
        Comprar pack de publicación
      </Button>

      <Modal
        size='lg'
        isOpen={modal}
        toggle={() => setModal(!modal)}
        backdrop='static'
      >
        <LoadingOverlay
          active={loadingTPV || dsParams == EmptyResults}
          spinner={
            <SyncLoader
              loading={loadingTPV || dsParams == EmptyResults}
              color='#51cbce'
            />
          }
          text='Cargando...'
        >
          <ModalHeader
            toggle={() => {
              checkTransactionState();
              setModal(!modal);
            }}
          >
            Comprar pack de publicación
          </ModalHeader>

          <ModalBody>
            <div
              style={{
                alignContent: 'center',
                position: 'relative',
                width: '100%',
                height: '0',
                paddingBottom: '84%'
              }}
            >
              <iframe
                id='tpv_frame'
                name='tpv_frame'
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%'
                }}
                frameBorder={0}
              >
                <form
                  ref={formRef}
                  id='SenderForm'
                  action={config.RedsysURL}
                  method='post'
                  target='tpv_frame'
                >
                  <input
                    type='hidden'
                    name='Ds_SignatureVersion'
                    value={dsParams.ds_SignatureVersion}
                  />
                  <input
                    type='hidden'
                    name='Ds_MerchantParameters'
                    value={dsParams.ds_MerchantParameters}
                  />
                  <input
                    type='hidden'
                    name='Ds_Signature'
                    value={dsParams.ds_Signature}
                  />
                </form>
              </iframe>
            </div>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </div>
  );
};

export default TPV;
