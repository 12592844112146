import React, { useState, useRef } from "react";
import { Switch, Route, Link } from "react-router-dom";
import NavItem from "../components/NavItem";
import About from "../layouts/About";
import Login from "../layouts/Login";
import ResumeRegistration from "../layouts/ResumeRegistration";
import OffersView from "../views/Offers";
import NewPassword from "../layouts/NewPassword";
import TermsOfUse from "../components/TermsOfUse";
import EthicalCode from "../components/EthicalCode";
import PrivacyPolicy from "../components/PrivacyPolicy";
import LegalWarning from "../components/LegalWarning";
import CookiesPolicy from "../components/CookiesPolicy";
import SocialMediaPolicy from "../components/SocialMediaPolicy";
import { NavbarToggler, Collapse, Navbar, NavbarBrand, Nav } from "reactstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router";

const styles = { padding: "90px 30px 0px 30px" };
const Public: React.FC<any> = (props: any) => {
  const [openNavbar, setOpenNavbar] = useState(false);
  const navBarToggle = useRef(null);
  let location = useLocation();
  return (
    <div>
      <Navbar className="navbar-expand-md navbar-light bg-white fixed-top px-2">
        <NavbarBrand>
          <Link to="/offers">
            <img
              src={require("../assets/img/logo_worketik.png")}
              alt="Logo Worketik"
              className="worketik_logo"
            />
          </Link>
        </NavbarBrand>

        <NavbarToggler
          type="button"
          ref={navBarToggle}
          onClick={() => setOpenNavbar(!openNavbar)}
          className="px-2"
        >
          <span className="navbar-toggler-bar bar1" />
          <span className="navbar-toggler-bar bar2" />
          <span className="navbar-toggler-bar bar3" />
        </NavbarToggler>
        <Collapse
          isOpen={openNavbar}
          className="navbar-collapse"
          style={{ alignItems: "flex-end" }}
          id="navbarResponsive"
          navbar
        >
          <Nav navbar className="ml-auto">
            <NavItem to="/offers" exact={true}>
              Ofertas de empleo
            </NavItem>
            <NavItem to="/resume-registration" exact={true}>
              Registra tu CV
            </NavItem>
            <NavItem
              to={
                sessionStorage.getItem("companyToken") ? "/private" : "/login"
              }
              exact={true}
            >
              Zona organizaciones
            </NavItem>
            <li className="nav-item">
              <a href="https://www.worketik.com/blog/" className="nav-link ml-auto">Blog</a>
            </li>
            <NavItem to="/about">Sobre Worketik</NavItem>
            {sessionStorage.getItem("companyToken") && (
              <Link
                to="/"
                className="nav-link btn-magnify"
                onClick={() => sessionStorage.clear()}
              >
                Cerrar sesión <i className="nc-icon nc-share-66" />
              </Link>
            )}
          </Nav>
        </Collapse>
      </Navbar>

      <div className="container" style={styles}>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="fade" timeout={3000}>
            <Switch location={location}>
              <Route exact path="/about" component={About} />
              <Route
                exact
                path="/login"
                render={(routeProps: any) => (
                  <Login {...props} routeProps={routeProps} />
                )}
              ></Route>
              <Route
                exact
                path="/new-password"
                render={(routeProps) => (
                  <NewPassword {...props} routeProps={routeProps} />
                )}
              />
              <Route
                exact
                path="/resume-registration"
                component={ResumeRegistration}
              />
              <Route exact path="/terms-of-use" component={TermsOfUse} />
              <Route exact path="/ethical-code" component={EthicalCode} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/legal-warning" component={LegalWarning} />
              <Route exact path="/cookies-policy" component={CookiesPolicy} />
              <Route
                exact
                path="/social-media-policy"
                component={SocialMediaPolicy}
              />
              <Route>
                <OffersView {...props} />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <footer className="footer pt-4">
          <div className="container-fluid">
            <div className="row">
              <nav className="footer-nav col-xs-12 col-sm-12 col-md-12 col-lg-8 mb-2">
                <ul>
                  <NavItem to="/offers" exact={true}>
                    Ofertas de empleo
                  </NavItem>
                  <NavItem to="/resume-registration" exact={true}>
                    Registra tu CV
                  </NavItem>
                  <NavItem
                    to={
                      sessionStorage.getItem("companyToken")
                        ? "/private"
                        : "/login"
                    }
                    exact={true}
                  >
                    Zona organizaciones
                  </NavItem>
                  <li className="nav-item" style={{ border: "1px" }}>
                    <a className="nav-link ml-auto" href="https://www.worketik.com/blog/">Blog</a>
                  </li>
                  <NavItem to="/about">Sobre worketik</NavItem>
                </ul>
                <ul>
                  <small>
                    <Link to="/privacy-policy" target="_blank" className="px-1">
                      {" "}
                      Política de privacidad{" "}
                    </Link>
                    <Link to="/legal-warning" target="_blank" className="px-1">
                      {" "}
                      Aviso legal{" "}
                    </Link>
                    <Link to="/cookies-policy" target="_blank" className="px-1">
                      {" "}
                      Política de cookies{" "}
                    </Link>
                    <Link
                      to="/social-media-policy"
                      target="_blank"
                      className="px-1"
                    >
                      {" "}
                      Política de redes sociales{" "}
                    </Link>
                  </small>
                </ul>
              </nav>
              <div className="credits ml-auto">
                <div className="d-block">
                  <a
                    href="https://www.un.org/sustainabledevelopment/es/"
                    target="_blank"
                    className="mr-1"
                  >
                    <img
                      src={require("../assets/img/S_SDG_logo_2.png")}
                      alt="Logotipo Objetivos de desarrollo sostenible"
                      className="img-responsive"
                    />
                  </a>
                  <a
                    href="https://twitter.com/worketik"
                    target="_blank"
                    className="mr-1"
                  >
                    {" "}
                    <i className="fab fa-twitter fa-lg"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/worketik_portal/"
                    target="_blank"
                    className="mr-1"
                  >
                    {" "}
                    <i className="fab fa-instagram fa-lg"></i>
                  </a>
                  <a
                    href="https://fb.me/worketik"
                    target="_blank"
                    className="mr-1"
                  >
                    {" "}
                    <i className="fab fa-facebook fa-lg"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/worketikportal/"
                    target="_blank"
                  >
                    {" "}
                    <i className="fab fa-linkedin-in fa-lg"></i>
                  </a>
                </div>
                <div className="d-block pull-right mt-2">
                  <small className="text-gray">
                    <a
                      href="https://www.iconfinder.com/iconsets/healthy-life-line-live-long-and-prosper"
                      target="_blank"
                      className="text-gray"
                    >
                      Icons
                    </a>
                    <span className="text-gray">
                      , by Chanut is Industries /
                    </span>{" "}
                    <a
                      href="https://creativecommons.org/licenses/by/3.0/"
                      target="_blank"
                      className="text-gray"
                    >
                      CC BY 3.0
                    </a>
                    .
                  </small>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Public;
