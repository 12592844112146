import React from "react";
import { Form, Field } from "react-final-form";
import {
  required,
  validEmailFormat,
  validPhoneNumber,
  composeValidators
} from "../../utils/validations";

const Contact: React.FC<any> = (props: any) => {
  return (
    <div>
      <div className="card-header">
        <h5 className="card-title mb-0">Persona de contacto</h5>
      </div>
      <div className="card-body">
        <Form
          onSubmit={props.putContactData}
          initialValues={props.contactData}
          render={({
            handleSubmit,
            submitting,
            pristine,
            initialValues,
            form
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <Field
                      name="oid"
                      type="text"
                      autoComplete="off"
                      component="input"
                      defaultValue={initialValues.oid}
                      hidden
                    />
                    <label className="mb-0">Nombre y apellidos *</label>
                    <Field
                      className={
                        form.getFieldState("contactName") &&
                        form.getFieldState("contactName").error &&
                        form.getFieldState("contactName").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      name="contactName"
                      component="input"
                      type="text"
                      autoComplete="off"
                      validate={required}
                      defaultValue={initialValues.name}
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("contactName") &&
                        form.getFieldState("contactName").error}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Cargo en la organización *</label>
                    <Field
                      className={
                        form.getFieldState("position") &&
                        form.getFieldState("position").error &&
                        form.getFieldState("position").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      name="position"
                      component="input"
                      type="text"
                      autoComplete="off"
                      validate={required}
                      defaultValue={initialValues.position}
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("position") &&
                        form.getFieldState("position").error}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label className="mb-0">Correo electrónico *</label>
                    <Field
                      className={
                        form.getFieldState("email") &&
                        form.getFieldState("email").error &&
                        form.getFieldState("email").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      name="email"
                      component="input"
                      type="email"
                      validate={composeValidators(required, validEmailFormat)}
                      defaultValue={initialValues.email}
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("email") &&
                        form.getFieldState("email").error}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Teléfono *</label>
                    <Field
                      className={
                        form.getFieldState("phone") &&
                        form.getFieldState("phone").error &&
                        form.getFieldState("phone").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      name="phone"
                      component="input"
                      type="text"
                      autoComplete="off"
                      validate={composeValidators(required, validPhoneNumber)}
                      defaultValue={initialValues.phone}
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("phone") &&
                        form.getFieldState("phone").error}
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary btn-round"
                type="submit"
                disabled={submitting || pristine}
              >
                Guardar
              </button>
              <a href="/new-password" className="d-block">
                Cambiar la contraseña
              </a>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default Contact;
