import React from "react";
import YouTube from "react-youtube";

function _onReady(event: any) {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
}

const About: React.FC = () => {
  return (
    <div className="card-user card ">
      <div className="card-body">
        <h2 className="card-title h5">
          Worketik el portal búsqueda de empleo ético.{" "}
        </h2>
        <div className="text-center">
          <YouTube
            videoId="qnCFr3sT-cw"
            opts={{ height: "390", width: "640", playerVars: { autoplay: 1 } }}
            onReady={_onReady}
          />
        </div>
        <br />
        <p>
          Bienvenid@ al portal de empleo bidireccional, transparente y ético.
        </p>
        <div className="row"></div>
        <p>
          En Worketik nos comprometemos a publicar sólo aquellas
          vacantes/ofertas de empleo en las que las condiciones contractuales,
          de jornada y salario sean visibles, provengan de organizaciones en las que a
          tod@s nos gustaría trabajar, las que cuidan a sus empleados, les
          ofrecen beneficios sociales que tod@s queremos y tratan como a tod@s
          nos gustaría ser tratad@s. Procuraremos que durante el proceso de
          selección se informe sobre el estado de la candidatura en un plazo
          razonable y en caso de ser no continuar en el proceso ser informad@
          del motivo. Vigilaremos que las organizaciones no descarten ni discriminen
          en ningún proceso de selección por tener 20 o 50 años, llamarse María
          o Antonio, tener hijos o gato, o haber nacido aquí o en la China.
        </p>
        <p>
          Despídete de las ofertas de empleo fantasma, las que aparecen y
          desaparecen sin despedirse, las que a pesar de tener el perfil nunca
          revisan la candidatura, las que te citan para entrevista de empleo sin
          decirte las condiciones, para todas esas ya existen los otros
          portales.
        </p>
        <div className="col-sm-12 col-md-5">
          <img
            src={require("../assets/img/sobre_worketik.png")}
            alt="Logo Worketik"
            className="mt-2 mb-5"
          />
        </div>
        <p>
          Sabemos que todavía nos falta mucho por desarrollar, pero no podíamos
          quedarnos en el horno esperando, teníamos ganas de salir y empezar a
          poner los puntos sobre las íes. Así que gracias de verdad por tu
          gesto, si conseguimos que Worketik crezca con buenas organizaciones y buenas
          personas, juntos podremos conseguir cambiar las reglas del juego.
        </p>
        <p>
          Si tienes sugerencias, nos quieres decir algo (que sea bonito) podrás
          siempre ponerte en contacto con nosotros.
        </p>
        <p>Equipo Worketik</p>

        <p>
          Empresa comprometida con la{" "}
          <a
            href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/"
            target="_blank"
          >
            Agenda 2030
          </a>
          <br />
          <a
            href="https://www.un.org/sustainabledevelopment/es/"
            target="_blank"
          >
            <img
              src={require("../assets/img/S_SDG_logo.png")}
              alt="Logotipo Objetivos de desarrollo sostenible"
              className="mt-1"
            />
          </a>
        </p>
      </div>
    </div>
  );
};
export default About;
