import React from "react";

const EthicalCode: React.FC = () => (
  <div>
    <div className="card-user card p-3 mt-5">
      <div className=" card-header">
        <h5 className="card-title mb-2 text-center" id="registro">
          Nuesto código ético para las organizaciones
        </h5>
      </div>
      <div className="card-body">
        <p className="mb-4">
          Todas las organizaciones que publiquen sus ofertas en este portal
          deberán aceptar nuestro código ético, el cual las compromete a
          demostrar un comportamiento y una actuación responsables en la
          selección de personas, Aplicando principios objetivos y a la no
          exclusión ni discriminación de las personas por razones de sexo, edad,
          religión o cualquier otro tipo de prejuicio que pudiese ser
          discriminatorio, tanto en el trato con las personas que se inscriban
          en sus ofertas, como con las que pudiese contactar mediante el
          servicio de búsqueda directa.
        </p>

        <div className="row">
          <div className="col-1">
            <img
              src={require("../assets/img/ic_worketik.png")}
              alt="Logo Worketik"
              className="pull-right mr-2"
            />
          </div>
          <div className="col-11">
            <p>
              A no divulgar ni utilizar los datos de carácter personal sin
              previo consentimiento. Aplicando a título individual la normativa
              que existiera al respecto.
            </p>
          </div>
          <div className="col-1">
            <img
              src={require("../assets/img/ic_worketik.png")}
              alt="Logo Worketik"
              className="pull-right mr-2"
            />
          </div>
          <div className="col-11">
            <p>
              A la veracidad de los datos publicados tanto de la organización
              como de la vacante. Fomentando la transparencia tanto con relación
              a las responsabilidades del puesto, como en las condiciones
              contractuales y salariales, de manera pública y visible.
              Únicamente la organización podrá decidir no publicar su nombre en
              caso de que al hacerlo pudiera causar daño a su personal.
            </p>
          </div>
          <div className="col-1">
            <img
              src={require("../assets/img/ic_worketik.png")}
              alt="Logo Worketik"
              className="pull-right mr-2"
            />
          </div>
          <div className="col-11">
            <p>
              Gestionar las candidaturas recibidas con celeridad, por defecto se
              deberá dar respuesta sobre el estado de una candidatura en un
              plazo máximo de 15 días desde la recepción de misma y a utilizar
              como mínimo el apartado habilitado al respecto para efectuar dicha
              comunicación.
            </p>
          </div>
          <div className="col-1">
            <img
              src={require("../assets/img/ic_worketik.png")}
              alt="Logo Worketik"
              className="pull-right mr-2"
            />
          </div>
          <div className="col-11">
            <p>
              Comunicar a Worketik todas aquellas incidencias o mejorías que
              pudieran detectarse para que pudieran ser añadidas en nuestro
              proceso de mejora continua.
            </p>
          </div>

          <div className="col-1">
            <img
              src={require("../assets/img/ic_worketik.png")}
              alt="Logo Worketik"
              className="pull-right mr-2"
            />
          </div>
          <div className="col-11">
            <p>
              A requerir a las personas la correcta cumplimentación de la
              información solicitada por el portal y a la veracidad de esta.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EthicalCode;
