import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { any, number } from 'prop-types';

const Offers: React.FC = (props: any) => {
  return (
    <div className='col-xs-12 col-sm-12 col-md-7 col-lg-8'>
      <div className='card'>
        <div className='row'>
          <div className='col-lg-10 col-xs-12'>
            <img
              src={require('../../assets/img/banner.png')}
              alt='El primer portal de organizaciones que une talento con organizaciones responsables'
              className='img-responsive p-2'
            />
          </div>
        </div>
      </div>

      {props.offers.length === 0 ? null : props.offers.length !== 1 ? (
        <p className='text-muted mt-2'>
          Se han encontrado <b>{props.count}</b> ofertas de empleo con los
          filtros seleccionados
        </p>
      ) : (
        <p className='text-muted mt-2'>
          Se ha encontrado <b>{props.count}</b> oferta de empleo con los filtros
          seleccionados
        </p>
      )}

      {props.offers.length === 0 ? (
        <div className='card mb-2 mt-3'>
          <div className='card-body row'>
            <div className=' col-lg-2'>
              <img
                src={require('../../assets/img/ic_no_results.png')}
                alt='Logo'
                className='img-responsive'
              />
            </div>
            <div className='col-lg-10'>
              <h5 className='card-title'>
                {' '}
                No se han encontrado resultados con los filtros seleccionados
              </h5>
              <p className='mb-0'>Por favor, vuelve a intentarlo</p>
            </div>
          </div>
        </div>
      ) : null}

      {props.offers.map((item: any, i: any) => {
        return (
          <div key={i} className='card mb-3'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-2 col-md-4  col-sm-4 col-4 '>
                  <Link to={'/offers/offer-detail/' + item.oid}>
                    <img src={item.logoUrl} alt='Logo' className='img-fluid' />
                  </Link>
                </div>
                <div className='col-lg-10 col-md-12 pt-1'>
                  {/* <small className="text-muted mb-2">
                    Publicado hace {item.publishedDays}{" "}
                    {item.publishedDays === 1 ? "día" : "días"}
                  </small> */}
                  <h5 className='card-title mb-1'>
                    <Link
                      to={'/offers/offer-detail/' + item.oid}
                      className='text-dark'
                    >
                      {item.name}
                    </Link>
                  </h5>
                  <p className='mb-0'>
                    <Link
                      to={'/offers/company-detail/' + item.oid}
                      className='font-weight-bold'
                    >
                      {item.companyName}
                    </Link>{' '}
                    <br />
                    {item.municipality} ({item.province} - {item.country})<br />
                    {item.workingDay} - {item.contractModality}
                    <br />
                    Beneficios sociales:
                    <small className='text-muted'>
                      {' '}
                      {item.socialResponsibilities.join(', ')}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Pagination aria-label='Page navigation example'>
        {firstPage(props)}
        {previousPage(props)}
        {setPages(props)}
        {nextPage(props)}
        {lastPage(props)}
      </Pagination>
    </div>
  );
};

export default Offers;

function setPages(props: any) {
  let buttonsArray: any[] = [];
  for (var j = 1; j <= props.headers.totalPages; j++) {
    buttonsArray.push(j);
  }

  return buttonsArray.map((page: any, i) => {
    return (
      <PaginationItem key={page} active={props.headers.currentPage == page}>
        <PaginationLink
          className='page-link text-primary'
          onClick={(e: any) => {
            e.preventDefault();
            props.setPage(page);
          }}
        >
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  });
}

function firstPage(props: any) {
  if (props.headers.totalPages > 1) {
    return (
      <PaginationItem>
        <PaginationLink
          first
          className='page-link text-primary'
          onClick={(e: any) => {
            e.preventDefault();
            props.setPage(1);
          }}
        />
      </PaginationItem>
    );
  }
}

function previousPage(props: any) {
  if (
    props.headers.previousPageLink !== null &&
    props.headers.currentPage > 1
  ) {
    return (
      <PaginationItem>
        <PaginationLink
          previous
          className='page-link text-primary'
          onClick={(e: any) => {
            e.preventDefault();
            props.setPage(props.headers.currentPage - 1);
          }}
        >
          Previous
        </PaginationLink>
      </PaginationItem>
    );
  }
}

function nextPage(props: any) {
  if (
    props.headers.nextPageLink !== null &&
    props.headers.currentPage !== props.headers.totalPages
  ) {
    return (
      <PaginationItem>
        <PaginationLink
          next
          className='page-link text-primary'
          onClick={(e: any) => {
            e.preventDefault();
            props.setPage(props.headers.currentPage + 1);
          }}
        >
          Next
        </PaginationLink>
      </PaginationItem>
    );
  }
}

function lastPage(props: any) {
  if (props.headers.totalPages > 1) {
    return (
      <PaginationItem>
        <PaginationLink
          last
          className='page-link text-primary'
          onClick={(e: any) => {
            e.preventDefault();
            props.setPage(props.headers.totalPages);
          }}
        />
      </PaginationItem>
    );
  }
}
