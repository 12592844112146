import React, { useEffect, useState } from 'react';
import BuyPack from '../components/ContractService/BuyPack';
import Payment from '../components/ContractService/Payment';
import { getError } from '../utils/errors';
import { Alert } from 'reactstrap';
import { config } from '../utils/Constants';

export interface myContractedService {
  date: string;
  name: string;
  serviceType: number;
  price: number;
  units: number;
  availableUnits: number;
}

const emptyContractedService: myContractedService = {
  date: '',
  name: '',
  serviceType: 0,
  price: 0,
  units: 0,
  availableUnits: 0
};

const ContractService: React.FC = (props: any) => {
  const [contractedServices, setContractedServices] = useState([
    emptyContractedService
  ]);
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false
  });

  useEffect(() => {
    if (alert && alert.visible) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  const [loadingPacks, setLoadingPacks] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [packs, setPacks] = useState(null);
  const [refreshTable, setRefreshTable] = useState(null);

  useEffect(() => {
    fetch(`${config.apiURL}companies/${props.company.oid}/contractedservices`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      }
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setContractedServices(data.items);
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingTable(false));
  }, []);

  useEffect(() => {
    fetch(`${config.apiURL}companies/${props.company.oid}/contractedservices`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      }
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setContractedServices(data.items);
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingTable(false));
  }, [refreshTable]);

  useEffect(() => {
    fetch(`${config.apiURL}services/`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      }
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setPacks(data.items);
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingPacks(false));
  }, []);

  const contractServiceProps = {
    ...props,
    contractedServices,
    setContractedServices
  };

  return (
    <div>
      <Alert
        className='fixed-top text-center'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%'
        }}
        color={alert.color}
        isOpen={alert.visible}
      >
        {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
      </Alert>

      <BuyPack
        {...props}
        packs={packs}
        loading={loadingPacks}
        refreshTable={setRefreshTable}
        refreshCompany={props.refresher}
      />

      <Payment {...contractServiceProps} loading={loadingTable} />
    </div>
  );
};

export default ContractService;
