import React from "react";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { tsPropertySignature } from "@babel/types";
import Company from "../CompanyDetail/Company";

const WeekPack: React.FC = (props: any) => {
  return (
    <div className="row">
      {props.company && props.company.showWelcome === true ? (
        <div className="col-md-12">
          <div className="card-user card">
            <div className="card-body">
              <h4 className="mt-1 mb-1">Hola {props.company.person.name},</h4>
              <p className="mb-1">
                Estamos encantados de que te hayas unido a la familia, a partir
                de ahora vamos a trabajar para conseguir encontrar a esa persona
                que tu organización tanto necesita. Así que por favor acepta nuestro
                regalo de bienvenida y publica tu primera oferta ¡Gratis!
              </p>
              <Link className="btn btn-primary" to="/private/new-offer">
                Crea y publica una oferta
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      {props.offers && props.offers.length > 0 ? (
        <div className="col-md-12">
          <h4 className="mt-1 mb-2">
            Hola de nuevo {props.company.person.name}
          </h4>
        </div>
      ) : null}

      {props.offers &&
      props.offers.length === 0 &&
      props.company.showWelcome === false ? (
        <div className="col-md-12">
          <div className="card-user card">
            <div className="card-body">
              <h4 className="mt-1 mb-1">Hola {props.company.person.name},</h4>
              <p className="mb-1">
                ¡Qué lástima! Actualmente no tienes ninguna oferta de empleo
                publicada. Recuerda que el mejor talento te espera en Worketik,
                así que en cuanto tengas una nueva vacante de empleo, háznoslo
                saber. Estaremos encantados de ayudarte a encontrar a la persona
                ideal para {props.company.name}.
              </p>
              <Link className="btn btn-primary" to="/private/new-offer">
                Crea y publica una oferta
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      <div className="col-md-12">
        <div className="card-user card">
          <div className="card-body">
            <div className="row">
              <div className="col-4 col-sm-2 col-md-1 col-lg-1">
                <img
                  src={require(".././../assets/img/ic_doc.png")}
                  alt="Contratación de packs"
                  className="d-block mx-auto my-auto pb-2 img-fluid"
                />
              </div>

              <div className="col-1210 col-sm-10 col-md-8 col-lg-8">
                <h4 className="mt-1 mb-1">Packs de publicación por semanas </h4>
                <p className="mb-1">
                Nuestros packs te permiten publicar tus ofertas por semanas o procesos. Por ejemplo, puedes utilizar nuestro pack de publicación “We love Worketik” de 5 semanas para un mismo proceso o publicar cada semana una vacante nueva. Práctico ¿no?
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 text-center">
                <p className="font-weight-bold text-primary mb-0 mt-1">
                  {props.company.availableWeeks === 1
                    ? props.company.availableWeeks + " semana disponible"
                    : props.company.availableWeeks + " semanas disponibles"}
                </p>
                <Link
                  className="btn btn-primary"
                  to="/private/contract-service"
                >
                  Comprar pack publicación
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WeekPack;
