import React, { useState, useEffect } from 'react';
import WeekPack from '../components/MyOffers/WeekPack';
import Offers from '../components/MyOffers/Offers';
import { Alert } from 'reactstrap';
import { getError } from '../utils/errors';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { config } from '../utils/Constants';

const MyOffers: React.FC = (props: any) => {
  const [myOffers, setMyOffers] = useState(null);
  const [refreshOffersTable, setRefreshOffersTable] = useState(null);
  const [loadingOffers, setLoadingOffers] = useState(null);
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false
  });

  useEffect(() => {
    if (alert && alert.visible) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    setLoadingOffers(true);
    fetch(`${config.apiURL}offers/companies/${props.company.oid}/offers`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      }
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              setMyOffers(data.items);
            });
          } else {
            response.json().then((data: any) => {
              if (data.errors) {
                setAlert({
                  text: getError(data.errors),
                  color: 'danger',
                  visible: true
                });
              } else {
                setAlert({
                  text: getError(),
                  color: 'danger',
                  visible: true
                });
              }
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingOffers(false));
  }, [refreshOffersTable]);

  const deleteOffer = (offerID: any) => {
    setLoadingOffers(true);
    fetch(`${config.apiURL}companies/${props.company.oid}/offers/${offerID}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      }
    })
      .then(
        (response: any) => {
          if (response.ok) {
            setAlert({
              text: '¡Oferta eliminada!',
              color: 'success',
              visible: true
            });
            setRefreshOffersTable(new Date().getTime());
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingOffers(false));
  };

  const publish = (offerID: any) => {
    setLoadingOffers(true);
    fetch(`${config.apiURL}companies/${props.company.oid}/offers/${offerID}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        state: 2
        // publicationLength: 1,

        // startOn: new Date().toString()
      })
    })
      .then(
        (response: any) => {
          if (response.ok) {
            setAlert({
              text: '¡Oferta publicada!',
              color: 'success',
              visible: true
            });
            setRefreshOffersTable(new Date().getTime());
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingOffers(false));
  };

  const hide = (offerID: any) => {
    setLoadingOffers(true);
    fetch(`${config.apiURL}companies/${props.company.oid}/offers/${offerID}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        state: 3
      })
    })
      .then(
        (response: any) => {
          if (response.ok) {
            setAlert({
              text: '¡Oferta despublicada!',
              color: 'success',
              visible: true
            });
            setRefreshOffersTable(new Date().getTime());
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingOffers(false));
  };

  const closeOffer = (values: any) => {
    setLoadingOffers(true);
    fetch(
      `${config.apiURL}companies/${props.company.oid}/offers/${values.offerOid}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          state: 4,
          closeReason:
            values.closeReason == 2
              ? values.reason
              : 'Ya hemos encontrado candidatos para esta oferta'
        })
      }
    )
      .then(
        (response: any) => {
          if (response.ok) {
            setAlert({
              text: '¡Oferta cerrada!',
              color: 'success',
              visible: true
            });
            setRefreshOffersTable(new Date().getTime());
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoadingOffers(false));
  };

  useEffect(() => {
    if (
      props.routeProps.location.state &&
      props.routeProps.location.state.published === true &&
      props.routeProps.location.state.oid
    ) {
      publish(props.routeProps.location.state.oid);
      props.refresher(new Date().getTime());
    }
    setRefreshOffersTable(new Date().getTime());
  }, []);

  const offerProps = {
    ...props,
    deleteOffer,
    closeOffer,
    publish,
    hide
  };

  return (
    <div>
      <Alert
        className='fixed-top text-center'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%'
        }}
        color={alert.color}
        isOpen={alert.visible}
      >
        {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
      </Alert>
      <WeekPack {...props} offers={myOffers} />
      <LoadingOverlay
        active={loadingOffers}
        spinner={<SyncLoader loading={loadingOffers} color='#51cbce' />}
        text='Cargando...'
        styles={{
          overlay: (base: any) => ({
            ...base,
            borderRadius: '12px'
          })
        }}
      >
        <Offers {...offerProps} offers={myOffers} />
      </LoadingOverlay>
    </div>
  );
};

export default MyOffers;
