import React from "react";

const Company: React.FC = (props: any) => (
    <div className="card shadow-none mb-1">
        <div className=" card-header">
            <h5 className="card-title" id="registro">{props.name ? props.name : null}</h5>
        </div>
        <div className="card-body">

            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-2">
                    <img src={props.logoUrl} alt="Logo" className="img-responsive mb-2 company_logo" />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-10">
                    {props.organizationType ?
                        <p className="mb-2">Tipo de organización: <span className="font-weight-bold"> {props.organizationType.text}</span></p>
                        : null}
                    {props.sectors ?
                        <p className="mb-2">Sector: <span className="font-weight-bold"> {props.sectors.map((sector: any) => {
                            return sector.text
                        }).join(", ")}</span></p>
                        : null
                    }
                    {props.subsectors ?
                        <p className="mb-2">Subsector: <span className="font-weight-bold"> {props.subsectors.map((subsector: any) => {
                            return subsector.text
                        }).join(", ")}</span></p>
                        : null}
                    {props.companySize ?
                        <p className="mb-2">Tamaño organización: <span className="font-weight-bold"> {props.companySize.text}</span></p>
                        : null}
                    {props.createdOn ?
                        <p className="mb-2">Año de creación: <span className="font-weight-bold"> {props.createdOn}</span></p>
                        : null}
                    {props.languages && props.languages.length > 0 ?
                        <p className="mb-2">Idiomas hablados: <span className="font-weight-bold"> {props.languages.map((language: any) => {
                            return language.text
                        }).join(", ")}</span></p>
                        : null}
                    <p><b>Descripción de la organización:</b> <br /> {props.description ? props.description : null}</p>
                </div>

            </div>
        </div>
    </div>

);

export default Company; 
