import React from "react";
import { Link } from "react-router-dom";

const BasicInfo: React.FC = (props: any) => {
    return (
        <div className="card shadow-none ">
            <div className="card-body">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-8">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-3">
                                <img src={props.offerDetail.logoUrl} alt="Logo" className="img-responsive mb-2 company_logo" />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-9">
                                <p className="font-weight-bold text-primary mt-1 mb-2">{props.offerDetail.companyName ? props.offerDetail.companyName : null}</p>
                                {props.offerDetail.organizationType ? <p className="mb-2">Tipo de organización: <span className="font-weight-bold"> {props.offerDetail.organizationType.text}</span></p> : null}
                                <p className="mb-2">Sector: <span className="font-weight-bold"> {props.offerDetail.companySectors ? props.offerDetail.companySectors.map((sector: any) => {
                                    return sector.text
                                }).join(", ") : null}</span></p>
                                <p className="mb-2">Subsector: <span className="font-weight-bold"> {props.offerDetail.companySubsectors ? props.offerDetail.companySubsectors.map((subsector: any) => {
                                    return subsector.text
                                }).join(", ") : null}</span></p>
                            </div>
                        </div>
                    </div>

                    {
                        props.logged === false ?
                            <div className="col-xs-12 col-sm-12 col-md-4">
                                <Link to={"/offers/company-detail/" + props.offerDetail.oid} className="btn btn-outline-primary btn-block"> Ver perfil organización</Link>
                                <Link to={'/offers/offer-detail/' + props.offerDetail.oid + '/enrol'} className="btn btn-primary btn-block"> Incribirme en la oferta</Link>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
};

export default BasicInfo;
