import React from "react";

const CookiesPolicy: React.FC = () => (
    <div className="card-user card ">
        <div className=" card-header">
            <h5 className="card-title " id="registro">Política de cookies</h5>
        </div>
        <div className="card-body">
        <p className="costats"><b>INFORMACIÓN SOBRE COOKIES</b></p>
        <p className="costats">Debido a la entrada en vigor de la referente modificación de la “Ley de Servicios de la Sociedad de la Información” (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso del usuario de todas las páginas web que usan cookies prescindibles, antes de que éste navegue por ellas.</p>
        <p className="costats"><b>¿QUÉ SON LAS COOKIES?</b></p>
        <p className="costats">Las cookies y otras tecnologías similares tales como local shared objects, flash cookies o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para ofrecer un correcto funcionamiento del sitio.</p>
        <p className="costats">Utilizamos cookies propias y de terceros para mejorar nuestros servicios, personalizar nuestro sitio web, facilitar la navegación de nuestros usuarios, proporcionarle una mejor experiencia en el uso del sitio web, identificar problemas para mejorar el mismo, hacer mediciones y estadísticas de uso y mostrarle publicidad relacionada con sus preferencias mediante el análisis del uso del sitio web.</p>
        <p className="costats"><b>Consentimiento</b></p>
        <p className="costats">Le informamos de que podemos utilizar cookies en su equipo a condición de que Usted haya dado su consentimiento, salvo en los supuestos en los que las cookies sean necesarias para la navegación por nuestro sitio web. En caso de que Usted preste su consentimiento, podremos utilizar cookies que nos permitirán tener más información acerca de sus preferencias y personalizar nuestro sitio web de conformidad con sus intereses individuales.</p>
        <p className="costats"><b>En función de su duración las cookies pueden ser:</b></p>
        <p className="costats">
        <ul>
            <li><b>De sesión. </b>Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Estas cookies no quedan almacenadas en el ordenador del usuario cuando caduca la sesión o este cierra el navegador.</li>
            <li><b>Persistentes.</b>Son un tipo de cookies en el que los datos siguen almacenados en el ordenador del usuario y pueden ser accedidos y tratados cuando el usuario abandona la página web y cuando se vuelva a conectar a ella. Estas cookies pueden ser borradas en cualquier momento por el Usuario.</li>
        </ul>
        </p>
        <p className="costats"><b>En función de la entidad que las gestione:</b></p>
        <p className="costats">
        <ul>
            <li><b>Cookies propias: </b>Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el titular de la página web y desde la que se presta el servicio solicitado por el usuario.</li>
            <li><b>Cookies de tercero: </b>Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el titular de la página web desde la que se presta el servicio solicitado por el usuario, sino por otra entidad que trata los datos obtenidos través de las cookies. Asimismo, en el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio titular del sitio web pero la información que se recoja mediante éstas sea gestionada por un tercero, también serán consideradas como cookies de terceros.</li>
        </ul>
        </p>
        <p className="costats"><b>Según la finalidad perseguida:</b></p>
        <p className="costats">
        <ul>
            <li><b>Cookies técnicas.</b> Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</li>
            <li><b>Cookies de Geolocalización. </b>Se utilizan para saber en qué lugar se encuentra un usuario cuando solicita un servicio. Esta cookie es anónima y se usa por ejemplo para ofrecerle la información adecuada dependiendo del país en el que se encuentre.</li>
            <li><b>Cookies de personalización.</b>Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</li>
            <li><b>Cookies de análisis. </b>Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios del sitio web al que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad del sitio web, plataforma o aplicación y para la elaboración de perfiles de navegación de los usuarios de dicho sitio web, plataforma o aplicación, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios.</li>
            <li><b>Cookies publicitarias. </b>Son aquéllas que permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web.</li>
            <li><b>Cookies de publicidad comportamental. </b>Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que hay en la página web. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
        </ul>
        </p>
        <p className="costats"><b >COOKIES QUE SE UTILIZAN EN ESTE SITIO WEB</b></p>
        <p className="costats"><b>Listar los nombres de las cookies, tipo, propietario, función y vencimiento, por ejemplo:</b></p>
        <p className="costats">
        <ul>
            <li className="text-danger">PHPSESSID: cookie técnica y estrictamente necesaria que contiene el identificador de la sesión. Se elimina al cerrar el navegador.</li>
            <li className="text-danger">_lang: cookie técnica y estrictamente necesaria que contiene el idioma de la sesión. Se elimina al cerrar el navegador.</li>
            <li className="text-danger">ac_cookies: cookie técnica y estrictamente necesaria que contiene el valor de si se ha aceptado la instalación de cookies. Caduca en 1 año desde la última actualización.</li>
            <li className="text-danger">_ga: cookie de Google Analytics que habilita la función de control de visitas únicas. La primera vez que un usuario entre en el sitio web a través de un navegador se instalará esta cookie. Cuando este usuario vuelva a entrar en la web con el mismo navegador, la cookie considerará que es el mismo usuario. Solo en el caso de que el usuario cambie de navegador, se considerará otro usuario. Caduca a los 2 años desde la última actualización.</li>
            <li className="text-danger">_gat: Esta cookie se asocia con Google Analytics Universal. Se utiliza para limitar la velocidad de petición - la limitación de la recogida de datos en los sitios de alto tráfico. Caduca a los 10 minutos.</li>
            <li className="text-danger">_utma: cookie de Google Analytics que registra la fecha de la primera y última vez que el usuario vistió el sitio web. Caduca a los 2 años desde la última actualización.</li>
            <li className="text-danger">_utmb: cookie de Google Analytics que registra la hora de llegada a la página web. Caduca a los 30 minutos desde la última actualización.</li>
            <li className="text-danger">_utmc: cookie de Google Analytics utilizada para la interoperabilidad con el código de seguimiento urchin.js. Se elimina al cerrar el navegador.</li>
            <li className="text-danger">_utmt: cookie de Google Analytics. Esta cookie se utiliza para procesar el tipo de solicitud pedida por el usuario. Caduca al finalizar la sesión.</li>
            <li className="text-danger">_utmv: cookie de Google Analytics. Esta cookie se utiliza para segmentar datos demográficos. Caduca al finalizar la sesión.</li>
            <li className="text-danger">_utmz: cookie de Google Analytics que almacena la fuente de tráfico o una campaña para explicar cómo el usuario llegó al sitio web. Caduca a los 6 meses desde la última actualización.</li>
        </ul>
        </p>
        <p className="costats"><b>DESACTIVACIÓN DE LAS COOKIES</b></p>
        <p className="costats">El usuario puede, en cualquier momento, permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la modificación de los parámetros de configuración del navegador instalado en su ordenador:</p>
        <p className="costats">La desactivación de las cookies no impide la navegación por el sitio web, aunque el uso de algunos de sus servicios podrá ser limitado y, por tanto, su experiencia de navegación podrá ser menos satisfactoria.</p>
        <p className="costats">El usuario podrá retirar en cualquier momento su consentimiento relacionado con la Política de Cookies, y podrá eliminar las cookies almacenadas en su equipo a través de los ajustes y configuraciones de su navegador de Internet, indicados anteriormente.</p>
        <p className="costats"><b>CÓMO ELIMINAR LAS COOKIES</b></p>
        <p className="costats"><b>Chrome</b></p>
        <p className="costats">
            <ol>
                <li>Selecciona el icono de Herramientas.</li>
                <li>Haz clic en Configuración.</li>
                <li>Haz clic en Mostrar Opciones Avanzadas.</li>
                <li>En la sección "Privacidad" haz clic en Configuración de contenido.
                    <ul>
                        <li>Eliminar cookies: Haz clic en Todas las cookies y los datos de sitios…</li>
                        <li>No permitir que se almacenen cookies.</li>
                    </ul>
                </li>
                <li>Haz clic en Eliminar datos de navegación (vaciar la Caché).</li>
                <li>Cierra y reinicia el navegador.</li>
            </ol>
        </p>
        <p className="costats">Para más información sobre Chrome pulse aquí: <a href="http://support.google.com/chrome/answer/95647?hl=es" target="_blank">http://support.google.com/chrome/answer/95647?hl=es</a></p>
        <p className="costats"><b>Internet Explorer. Versión 11</b></p>
        <p className="costats">
            <ol>
                <li>Selecciona Herramientas | Opciones de Internet.</li>
                <li>Haz clic en la ficha General.</li>
                <li>En la sección "Historial de exploración", haz clic en Eliminar el historial de exploración al salir.</li>
                <li>Seleccionar Eliminar archivos.</li>
                <li>Seleccionar Eliminar cookies.</li>
                <li>Haz clic en Eliminar.</li>
                <li>Haz clic en Aceptar.</li>
                <li>Cierra y reinicia el navegador.</li>
            </ol>
        </p>
        <p className="costats">Para más información sobre Internet Explorer pulse aquí: <a href="http://windows.microsoft.com/es-419/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank">http://windows.microsoft.com/es-419/windows7/how-to-manage-cookies-in-internet-explorer-9</a></p>
        <p className="costats"><b>Firefox. Versión 18</b></p>
        <p className="costats">
            <ol>
                <li>Selecciona Firefox | Historial | Limpiar el historial reciente.</li>
                <li>Al lado de "Detalles", haz clic en la flecha hacia abajo.</li>
                <li>Selecciona las siguientes casillas de verificación: Cookies, Caché, Inicios de sesión activos</li>
                <li>Usando el "Intervalo de tiempo para borrar" en el menú desplegable, selecciona Todo.</li>
                <li>Haz clic en Borrar ahora.</li>
                <li>Cierra y reinicia el navegador.</li>
            </ol>
        </p>
        <p className="costats">Puede aceptar o rechazar las cookies individualmente en las Preferencias de Firefox, en la sección Historial disponible en Herramientas > Opciones > Privacidad.</p>
        <p className="costats">Para más información sobre Mozilla Firefox pulse aquí: <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies" target="_blank">https://www.mozilla.org/es-ES/privacy/websites/#cookies</a></p>
        <p className="costats"><b>Safari Versión 5.1</b></p>
        <p className="costats">        
        <ol>
            <li>Selecciona el icono de Safari / Editar | Restaurar Safari.</li>
            <li>Selecciona las siguientes casillas de verificación: Borrar el historial, Eliminar todos los datos de sitio web</li>
            <li>Haz clic en Restablecer.</li>
            <li>Cierra y reinicia el navegador.</li>
        </ol>
        </p>
        <p className="costats">Para más información sobre Safari pulse aquí:  <a href="http://support.apple.com/kb/PH5042" target="_blank">http://support.apple.com/kb/PH5042</a></p>
        <p className="costats"><b>Opera</b></p>
        <p className="costats">Opciones - Avanzado - Cookies.
        <br/>  Las opciones de cookies controlan el modo en que Opera los maneja y por lo tanto su aceptación o rechazo.</p>
        <p className="costats">Para más información sobre Ópera pulse aquí:  <a href="http://help.opera.com/Linux/10.60/es-ES/cookies.html" target="_blank">http://help.opera.com/Linux/10.60/es-ES/cookies.html</a></p>
        <p className="costats"><b>Otros navegadores</b></p>
        <p className="costats">Consulte la documentación del navegador que tenga instalado.</p>


        </div>
    </div>

);

export default CookiesPolicy; 
