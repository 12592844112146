import React, { useState, useRef } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router";
import CompanySocialBenefits from "../layouts/CompanySocialBenefits";

const Embedded: React.FC<any> = (props: any) => {
  let location = useLocation();
  return (
    <div>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={3000}>
          <Switch location={location}>
            <Route
              exact
              path="/embedded/company-social-benefits/:id"
              render={(routeProps) => (
                <CompanySocialBenefits {...props} routeProps={routeProps} />
              )}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Embedded;
