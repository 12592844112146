import { config } from './Constants';

export interface ApiResultsForTPV {
  ds_MerchantParameters: string;
  ds_Signature: string;
  ds_SignatureVersion: string;
}

export const EmptyResults: ApiResultsForTPV = {
  ds_MerchantParameters: '',
  ds_Signature: '',
  ds_SignatureVersion: ''
};

export const getTPVData = async (companyId: string, serviceOid: any) => {
  return fetch(
    `${config.apiURL}companies/${companyId}/payments/${serviceOid}`,
    {
      method: 'POST',

      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
        'Content-Type': 'application/json'
      }
    }
  );
};

export default getTPVData;
