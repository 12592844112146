import React, { useEffect, useLayoutEffect } from "react";
import { Field } from "react-final-form";

const SocialBenefits: React.FC = (props: any) => {
  const preparedLayout = (
    <div className="container">
      <div
        className="card-columns"
        onLoad={() => {
          if (props.loginLoadingSR) {
            props.setLoginLoadingSR(false);
          }
        }}
      >
        {//LLista totes les categories

        props.lists &&
          props.lists.SRGroupList &&
          props.lists.SRGroupList.map((category: any, i: any) => {
            return (
              <div className="card shadow-none " key={i}>
                <div className="card-body py-0">
                  <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
                      <img
                        src={require("../assets/img/ic_" +
                          category.oid +
                          ".png")}
                        alt="Logo Worketik"
                        className="d-block mb-2"
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                      <h6 className="mb-2">{category.text}</h6>
                      {/* LLista tots els items per categoria */}
                      {props.lists.SRList.length > 0 &&
                        props.lists.SRList.map((listItem: any, j: any) => {
                          if (listItem.parentOid === category.oid) {
                            return (
                              <div key={j}>
                                <div className="form-group mb-1">
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <Field
                                        checked={
                                          props.socialBenefitsData
                                            ? props.socialBenefitsData.find(
                                                (item: any) => {
                                                  return (
                                                    item.oid === listItem.oid
                                                  );
                                                }
                                              )
                                            : undefined
                                        }
                                        onChange={() => {
                                          if (
                                            props.socialBenefitsData.find(
                                              (item: any) => {
                                                return item.oid == listItem.oid;
                                              }
                                            )
                                          ) {
                                            props.setSocialBenefitsData(
                                              props.socialBenefitsData.filter(
                                                (item: any) =>
                                                  item.oid !== listItem.oid
                                              )
                                            );
                                          } else {
                                            props.setSocialBenefitsData(
                                              props.socialBenefitsData.concat(
                                                listItem
                                              )
                                            );
                                          }
                                        }}
                                        className="form-check-input"
                                        name={listItem.oid}
                                        type="checkbox"
                                        component="input"
                                      />{" "}
                                      <span className="form-check-sign"></span>
                                      <span className="text-muted">
                                        {listItem.text}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );

  return preparedLayout;
};
export default SocialBenefits;
