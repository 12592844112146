import React from "react";

const Offer: React.FC = (props: any) => {
    return (
        <div className="card-user card shadow-none ">
            <div className=" card-header pt-0">
                <h5 className="card-title mb-0" id="registro"><i className="nc-icon nc-alert-circle-i"></i> Datos de la oferta</h5>
            </div>
            <div className="card-body pb-0">
                
                <p className="mb-2">   Jornada laboral: <span className="font-weight-bold"> {props.workingDay ? props.workingDay.text : null}</span></p>
                {props.duration ? <p className="mb-2">   Duración: <span className="font-weight-bold"> {props.duration}</span></p> : null}
                <p className="mb-2">   Tipo de contrato: <span className="font-weight-bold"> {props.contractModality ? props.contractModality.text : ""}</span></p>
                {props.schedule ? <p className="mb-2">   Horario: <span className="font-weight-bold"> {props.schedule}</span></p> : null}
                <p className="mb-2">   Salario: <span className="font-weight-bold"> {props.minSalary ? props.minSalary : null}-{props.maxSalary ? props.maxSalary : null}€ {
                    props.salaryType ? props.salaryType === 0 ? <span>mes</span> : <span>año</span> : null
                }</span></p>
                <p className="mb-2">   Número de pagas anuales: <span className="font-weight-bold"> {props.payCount ? props.payCount : null}</span></p>
                <p className="mb-2">   Nº Vacantes: <span className="font-weight-bold"> {props.vacancies ? props.vacancies : null}</span></p>
            </div>
        </div>
    )
};

export default Offer;
