import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import arrayMutators, { remove } from 'final-form-arrays';
import { required, atLeastOne } from '../../utils/validations';
import {
  KillerQuestion,
  Answer,
  newQuestion
} from '../../models/KillerQuestion';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FieldArray } from 'react-final-form-arrays';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import { getError } from '../../utils/errors';
import { config } from '../../utils/Constants';

const questionTypeOptions = [
  { enumValue: 0, text: 'Campo de texto abierto' },
  { enumValue: 1, text: 'Respuesta única' },
  { enumValue: 2, text: 'Respuestas multiples' }
];

const answerValueOptions = [
  { enumValue: -1, text: 'Descartar' },
  { enumValue: 1, text: '+1' },
  { enumValue: 2, text: '+2' },
  { enumValue: 3, text: '+3' },
  { enumValue: 4, text: '+4' },
  { enumValue: 5, text: '+5' },
  { enumValue: 6, text: '+6' },
  { enumValue: 7, text: '+7' },
  { enumValue: 8, text: '+8' },
  { enumValue: 9, text: '+9' },
  { enumValue: 10, text: '+10' }
];

const KillerQuestions: React.FC<any> = (props: any) => {
  const [selectedQuestion, setSelectedQuestion] = useState(newQuestion);
  const [stateModal, setStateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(null);
  const [update, setUpdate] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false
  });

  const [killerQuestions, setKillerQuestions] = useState(null);

  const loadKillerQuestions = async () => {
    fetch(
      `${config.apiURL}offers/companies/${props.companyID}/offers/${props.offerID}`,
      {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        }
      }
    )
      .then(
        response => {
          if (response.ok) {
            response.json().then((data: any) => {
              setKillerQuestions(data.item.killerQuestions);
            });
          } else {
            response.json().then((data: any) => {
              setAlert({
                text: getError(data.item.errors),
                color: 'danger',
                visible: true
              });
            });
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoading(false));
  };

  //#region [effects]
  useEffect(() => {
    if (alert && alert.visible) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    loadKillerQuestions();
  }, [reload]);
  //#endregion

  const createOrModifyKillerQuestion = async (values: any) => {
    setLoading(true);
    console.log({
      ...values
    });

    fetch(
      `${config.apiURL}companies/${props.companyID}/offers/${
        props.offerID
      }/killerquestions${update ? '/' + values.oid : ''}`,
      {
        method: values.oid ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        },
        body: JSON.stringify({
          question: values.question,
          type: values.type.enumValue,

          options:
            values.type.enumValue != 0 && values.options.length
              ? values.options.map((option: any) => {
                  return {
                    option: option.option,
                    value: option.value.enumValue
                  };
                })
              : []
        })
      }
    )
      .then(
        response => {
          if (response.ok) {
            response.json().then((data: any) => {
              setAlert({
                text: update ? '¡Pregunta actualizada!' : '¡Pregunta añadida!',
                color: 'success',
                visible: true
              });
              setReload(new Date().getTime());
            });
          } else {
            response.json().then(
              (data: any) => {
                setAlert({
                  text: getError(data.errors),
                  color: 'danger',
                  visible: true
                });
              },
              () => {
                setAlert({
                  text: getError(),
                  color: 'danger',
                  visible: true
                });
              }
            );
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoading(false));
  };

  const deleteKillerQuestion = async (index: any) => {
    setLoading(true);
    fetch(
      `${config.apiURL}companies/${props.companyID}/offers/${props.offerID}/killerquestions/${killerQuestions[index].oid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        }
      }
    )
      .then(
        (response: any) => {
          if (response.ok) {
            setAlert({
              text: 'Pregunta borrada!',
              color: 'success',
              visible: true
            });
            setReload(new Date().getTime());
          } else {
            response.json().then(
              (data: any) => {
                setAlert({
                  text: getError(data.errors),
                  color: 'danger',
                  visible: true
                });
              },
              () => {
                setAlert({
                  text: getError(),
                  color: 'danger',
                  visible: true
                });
              }
            );
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => setLoading(false));
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={<SyncLoader loading={loading} color='#51cbce' />}
      text=''
      styles={{
        overlay: (base: any) => ({
          ...base,
          borderRadius: '12px'
        })
      }}
    >
      <div>
        <Alert
          className='fixed-top text-center'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%'
          }}
          color={alert.color}
          isOpen={alert.visible}
        >
          {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
        </Alert>
        <div className='card-user card shadow-none mb-0'>
          <div className=' card-header'>
            <h5 className='card-title mb-2' id='registro'>
              <i className='nc-icon nc-bullet-list-67'></i> Preguntas de
              filtrado
            </h5>
            <p>
              Las preguntas de filtrado te ayudan a detectar con mayor rapidez
              todo aquello que es importante para la posición. Verás que tienes
              3 tipos de preguntas a tu disposición, preguntas abiertas, de
              selección múltiple o selección única. Utilízalas para preguntar
              por una tecnología determinada, experiencia en un ámbito concreto,
              o realizar una pregunta que consideres interesante.
            </p>
            <a
              className='btn btn-primary mb-0'
              style={{ color: 'white' }}
              onClick={e => {
                e.preventDefault();
                setSelectedQuestion(newQuestion);
                setUpdate(false);
                setSelectedIndex(null);
                setStateModal(true);
              }}
            >
              + Añadir pregunta
            </a>
          </div>
          <div className='card-body'>
            <ul className='list-group list-group-flush'>
              {!loading &&
                killerQuestions &&
                killerQuestions.map(
                  (question: KillerQuestion, index: number) => {
                    return (
                      <li className='list-group-item pt-4' key={index}>
                        <div className='row'>
                          <div className='col-1'>
                            <img
                              src={require('../../assets/img/ic_question.png')}
                              alt='Logo'
                              className='img-responsive'
                            />
                          </div>

                          <div className='col'>
                            <p className='mb-2'>{question.question}</p>

                            {question.type === 0 && (
                              <div className='form-group'>
                                <textarea
                                  rows={1}
                                  readOnly
                                  className='form-control'
                                ></textarea>
                              </div>
                            )}

                            {question.type === 1 &&
                              question.answerOptions.length &&
                              question.answerOptions.map((option: any) => {
                                return (
                                  <div>
                                    <div className='form-check'>
                                      {option.value != -1 && (
                                        <span className='badge badge-success mr-2'>
                                          {' '}
                                          {option.value}
                                        </span>
                                      )}

                                      {option.value == -1 && (
                                        <span className='badge badge-danger mr-2'>
                                          {' '}
                                          &nbsp;
                                          <i
                                            className='fas fa-user-slash'
                                            data-toggle='tooltip'
                                            title='Descartar'
                                          ></i>{' '}
                                        </span>
                                      )}

                                      <label className='form-check-label'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='exampleRadios'
                                          readOnly
                                        />
                                        {option.option}
                                        <span className='circle'>
                                          <span className='check'></span>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}

                            {question.type === 2 &&
                              question.answerOptions.length &&
                              question.answerOptions.map((option: any) => {
                                return (
                                  <div className='form-group mb-1'>
                                    <div className='form-check'>
                                      <span className='badge badge-success mr-2'>
                                        {' '}
                                        {option.value}
                                      </span>
                                      <label className='form-check-label'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          value=''
                                          readOnly
                                        />
                                        {option.option}
                                        <span className='form-check-sign'>
                                          <span className='check'></span>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className='col-2'>
                            <a
                              data-toggle='tooltip'
                              title='Editar'
                              className='btn btn-outline-primary btn-sm p-1 mr-1 pull-right'
                              href=''
                              onClick={e => {
                                e.preventDefault();
                                setSelectedQuestion(question);
                                setUpdate(true);
                                setSelectedIndex(index);
                                setStateModal(true);
                              }}
                            >
                              {' '}
                              <i className='far fa-edit'></i>
                            </a>
                            <a
                              data-toggle='tooltip'
                              title='Eliminar'
                              className='btn btn-outline-primary btn-sm p-1 mr-1 pull-right'
                              href=''
                              onClick={e => {
                                e.preventDefault();
                                deleteKillerQuestion(index);
                              }}
                            >
                              {' '}
                              <i className='far fa-trash-alt'></i>
                            </a>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}

              {/* 
                  <p className="mb-2">
                    ¿Has utilizado el inglés en el ámbito laboral los dos
                    últimos años?
                  </p>
                  <div className="form-check">
                    <span className="badge badge-success mr-2"> +10</span>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                      />
                      Si, todos los días
                      <span className="circle">
                        <span className="check"></span>
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <span className="badge badge-success mr-2"> &nbsp;+7</span>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                      />
                      Sí, amenudo.
                      <span className="circle">
                        <span className="check"></span>
                      </span>
                    </label>
                  </div>

                 

                </div>
                
              </div>
            </li>
            */}
            </ul>
          </div>
        </div>

        <Modal
          size='xl'
          isOpen={stateModal}
          toggle={() => setStateModal(!stateModal)}
        >
          <ModalHeader toggle={() => setStateModal(!stateModal)}>
            Añadir pregunta
          </ModalHeader>
          <ModalBody>
            <div className=' card-body'>
              <Form
                mutators={{
                  ...arrayMutators
                }}
                initialValues={{
                  question: selectedQuestion.question,
                  oid: selectedQuestion.oid,
                  type: questionTypeOptions.find(item => {
                    return item.enumValue == selectedQuestion.type;
                  }),
                  options: selectedQuestion.answerOptions.length
                    ? selectedQuestion.answerOptions.map(answer => {
                        return {
                          ...answer,
                          value: answerValueOptions.find(item => {
                            return item.enumValue == answer.value;
                          })
                        };
                      })
                    : []
                }}
                onSubmit={(values, form) => {
                  createOrModifyKillerQuestion(values);

                  setTimeout(() => {
                    setUpdate(false);
                    setSelectedIndex(null);
                    form.reset(newQuestion);
                  }, 0);
                }}
                render={({
                  form: {
                    mutators: { push, pop, remove, update }
                  },
                  form,
                  handleSubmit,
                  initialValues,
                  pristine,
                  submitting,
                  valid,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col'>
                        <div className='form-group'>
                          <label>Texto de la pregunta *</label>
                          <Field
                            component='input'
                            type='text'
                            autoComplete='off'
                            validate={required}
                            name='question'
                            className={
                              form.getFieldState('question') &&
                              form.getFieldState('question').error &&
                              form.getFieldState('question').submitFailed
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                          />

                          <div className='invalid-feedback'>
                            {form.getFieldState('question') &&
                              form.getFieldState('question').error}
                          </div>
                        </div>
                      </div>
                      <div className='col-3'>
                        <div className='form-group'>
                          <label>Tipo de respuesta/s *</label>
                          <Field
                            validate={required}
                            className='form-control'
                            name='type'
                            render={(field: any) => {
                              return (
                                <div>
                                  <Typeahead
                                    emptyLabel={
                                      !questionTypeOptions
                                        ? 'Cargando...'
                                        : 'No se han encontrado resultados.'
                                    }
                                    inputProps={{
                                      className:
                                        field.meta.error &&
                                        field.meta.submitFailed
                                          ? 'form-control is-invalid'
                                          : 'form-control'
                                    }}
                                    labelKey={(option: any) => {
                                      return option.text
                                        ? option.text
                                        : undefined;
                                    }}
                                    filterBy={(option: any, props) => {
                                      if (props.selected.length) {
                                        // Display all the options if there's a selection.
                                        return true;
                                      }
                                      // Otherwise filter on some criteria.
                                      return (
                                        option.text
                                          .toLowerCase()
                                          .indexOf(props.text.toLowerCase()) !==
                                        -1
                                      );
                                    }}
                                    id='questionTypeTypeahead'
                                    placeholder='Selecciona...'
                                    options={questionTypeOptions}
                                    onChange={(value: any) => {
                                      field.input.onChange(value[0]);
                                      form.change('options', []);
                                    }}
                                    defaultSelected={
                                      initialValues.type &&
                                      initialValues.type.text
                                        ? [initialValues.type]
                                        : []
                                    }
                                  />
                                  {field.meta.error && field.meta.touched && (
                                    <div
                                      className='invalid-feedback'
                                      style={{ display: 'block' }}
                                    >
                                      {field.meta.error}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {values.type && values.type.enumValue !== 0 && (
                      <div>
                        <a
                          href=''
                          className='text-primari'
                          onClick={e => {
                            e.preventDefault();

                            push('options', undefined);
                          }}
                        >
                          + Añadir respuesta
                        </a>

                        <div className='mt-2'>
                          <ul className='list-group'>
                            <FieldArray name='options'>
                              {({ fields }) =>
                                fields.map((name, index) => {
                                  return (
                                    <li
                                      className='list-group-item p-1 pl-2 pr-2'
                                      key={name}
                                    >
                                      <Field
                                        name={`${name}.oid`}
                                        hidden
                                        value={`${name}.oid`}
                                        component='input'
                                        readOnly
                                      ></Field>
                                      <div className='row'>
                                        <div className='col-2'>
                                          <div className='form-group'>
                                            <label>Valor *</label>
                                            <Field
                                              validate={required}
                                              className='form-control'
                                              name={`${name}.value`}
                                              render={field => {
                                                return (
                                                  <div>
                                                    <Typeahead
                                                      emptyLabel={
                                                        'No se han encontrado resultados.'
                                                      }
                                                      inputProps={{
                                                        className:
                                                          field.meta.error &&
                                                          field.meta
                                                            .submitFailed
                                                            ? 'form-control is-invalid'
                                                            : 'form-control'
                                                      }}
                                                      labelKey={(
                                                        option: any
                                                      ) => {
                                                        return option.text
                                                          ? option.text
                                                          : undefined;
                                                      }}
                                                      filterBy={(
                                                        option: any,
                                                        props
                                                      ) => {
                                                        if (
                                                          option.enumValue ==
                                                            -1 &&
                                                          values.type
                                                            .enumValue != 1
                                                        ) {
                                                          return false;
                                                        }
                                                        if (
                                                          props.selected.length
                                                        ) {
                                                          // Display all the options if there's a selection.
                                                          return true;
                                                        }
                                                        // Otherwise filter on some criteria.
                                                        return (
                                                          option.text
                                                            .toLowerCase()
                                                            .indexOf(
                                                              props.text.toLowerCase()
                                                            ) !== -1
                                                        );
                                                      }}
                                                      onChange={value =>
                                                        field.input.onChange(
                                                          value[0]
                                                        )
                                                      }
                                                      options={
                                                        answerValueOptions
                                                      }
                                                      defaultSelected={
                                                        field.input.value
                                                          ? [field.input.value]
                                                          : []
                                                      }
                                                    ></Typeahead>

                                                    {field.meta.error &&
                                                      field.meta.touched && (
                                                        <div
                                                          className='invalid-feedback'
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                        >
                                                          {field.meta.error}
                                                        </div>
                                                      )}
                                                  </div>
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className='col'>
                                          <div className='form-group'>
                                            <label>
                                              Texto de la respuesta *
                                            </label>
                                            <Field
                                              validate={required}
                                              component='input'
                                              type='text'
                                              autoComplete='off'
                                              name={`${name}.option`}
                                              id={`${name}.option`}
                                              initialValue={
                                                initialValues.options[index]
                                                  ? initialValues.options[index]
                                                      .option
                                                  : ''
                                              }
                                              className={
                                                form.getFieldState(
                                                  `${name}.option`
                                                ) &&
                                                form.getFieldState(
                                                  `${name}.option`
                                                ).error &&
                                                form.getFieldState(
                                                  `${name}.option`
                                                ).submitFailed
                                                  ? 'form-control is-invalid'
                                                  : 'form-control'
                                              }
                                            />

                                            <div className='invalid-feedback'>
                                              {form.getFieldState(
                                                `${name}.option`
                                              ) &&
                                                form.getFieldState(
                                                  `${name}.option`
                                                ).error}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='col-1'>
                                          <a
                                            data-toggle='tooltip'
                                            title='Eliminar'
                                            className='btn btn-outline-primary btn-sm p-1 mr-1 pull-right'
                                            href=''
                                            onClick={e => {
                                              e.preventDefault();
                                              console.log(index);
                                              fields.remove(index);
                                            }}
                                          >
                                            {' '}
                                            <i className='far fa-trash-alt'></i>
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })
                              }
                            </FieldArray>
                          </ul>
                        </div>
                        {/* Resposta múltiple */}
                        {/* <div className="mt-2">
                        <ul className="list-group">
                          <li className="list-group-item p-1 pl-2 pr-2">
                            <div className="row">
                              <div className="col-2">
                                <div className="form-group">
                                  <label>Valor *</label>
                                  <select
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                  >
                                    <option>+10</option>
                                    <option>+9</option>
                                    <option>+8</option>
                                    <option>+7</option>
                                    <option>+6</option>
                                    <option>+5</option>
                                    <option>+4</option>
                                    <option>+3</option>
                                    <option>+2</option>
                                    <option>+1</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    Selecciona valor para continuar. <br />
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label>Texto de la respuesta *</label>
                                  <input type="text" className="form-control" />
                                  <div className="invalid-feedback">
                                    Rellene este campo para continuar. <br />
                                  </div>
                                </div>
                              </div>
                              <div className="col-1">
                                <a
                                  data-toggle="tooltip"
                                  title="Eliminar"
                                  className="btn btn-outline-primary btn-sm p-1 mr-1 pull-right"
                                  href="/private/new-offer"
                                >
                                  {" "}
                                  <i className="far fa-trash-alt"></i>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item p-1 pl-2 pr-2">
                            <div className="row">
                              <div className="col-2">
                                <div className="form-group">
                                  <label>Valor *</label>
                                  <select
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                  >
                                    <option>+10</option>
                                    <option>+9</option>
                                    <option>+8</option>
                                    <option>+7</option>
                                    <option>+6</option>
                                    <option>+5</option>
                                    <option>+4</option>
                                    <option>+3</option>
                                    <option>+2</option>
                                    <option>+1</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    Selecciona valor para continuar. <br />
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label>Texto de la respuesta *</label>
                                  <input type="text" className="form-control" />
                                  <div className="invalid-feedback">
                                    Rellene este campo para continuar. <br />
                                  </div>
                                </div>
                              </div>
                              <div className="col-1">
                                <a
                                  data-toggle="tooltip"
                                  title="Eliminar"
                                  className="btn btn-outline-primary btn-sm p-1 mr-1 pull-right"
                                  href="/private/new-offer"
                                >
                                  {" "}
                                  <i className="far fa-trash-alt"></i>
                                </a>
                              </div>
                            </div>
                          </li>

                          <div className="invalid-feedback">
                            Añade como mínimo una respuesta para continuar{" "}
                            <br />
                          </div>
                        </ul>
                      </div> */}
                      </div>
                    )}
                    <div className='mt-2'>
                      <Button
                        type='button'
                        className='btn btn-outline-primary mr-1'
                        onClick={() => setStateModal(false)}
                      >
                        {' '}
                        Cancelar
                      </Button>
                      <Button
                        type='submit'
                        className='btn btn-primary mr-1'
                        onClick={e => {
                          e.preventDefault();
                          form.submit();
                          if (valid) {
                            setStateModal(false);
                          }
                        }}
                      >
                        {' '}
                        Guardar y cerrar
                      </Button>
                      <Button
                        type='submit'
                        className='btn btn-primary '
                        onClick={e => {
                          e.preventDefault();
                          form.submit();
                        }}
                      >
                        {' '}
                        Guardar y añadir nueva
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </ModalBody>
        </Modal>

        <Button
          onClick={() => {
            props.finalize(
              props.offerID,
              props.companyID,
              props.history,
              killerQuestions
            );
          }}
        >
          FINALIZAR
        </Button>
      </div>
    </LoadingOverlay>
  );
};
export default KillerQuestions;
