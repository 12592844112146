import { address } from "./Address";
import { category } from "./Category";

export interface offer {
  oid: string;
  showDetailedInfo: boolean;
  name: string;
  address: address;
  vacancies: number;
  functionalLevel: category;
  workingDay: category;
  duration: string;
  contractModality: category;
  schedule: string;
  salaryType: number;
  minSalary: number;
  maxSalary: number;
  payCount: number;
  socialResponsibilities: category[];
  sectors: category[];
  subsectors: category[];
  functions: category[];
  experience: category;
  peopleInCharge: category;
  professions: category[];
  professionalSkills: category[];
  knowledges: category[];
  educationLevel: category;
  educationTitles: category[];
  averageGrade: string;
  languages: category[];
  professionalCards: category[];
  travelAvailability: boolean;
  drivingLicense: boolean;
  hobbies: category[];
  drivingLicenses: category[];
  vehicle: boolean;
  placeOfResidence: boolean;
  killerQuestions: [
    {
      oid: string;
      question: string;
      questionType: number;
      answerOptions: [
        {
          oid: string;
          option: string;
          value: number;
        }
      ];
    }
  ];
}

export const newOffer: offer = {
  oid: null,
  showDetailedInfo: false,
  name: "",
  address: null,
  vacancies: 1,
  functionalLevel: null,
  workingDay: null,
  duration: "",
  contractModality: null,
  schedule: "",
  salaryType: null,
  minSalary: null,
  maxSalary: null,
  payCount: null,
  socialResponsibilities: null,
  sectors: null,
  subsectors: null,
  functions: null,
  experience: null,
  peopleInCharge: null,
  professions: null,
  professionalSkills: null,
  knowledges: null,
  educationLevel: null,
  educationTitles: null,
  averageGrade: "",
  languages: null,
  professionalCards: null,
  travelAvailability: false,
  drivingLicense: false,
  hobbies: null,
  drivingLicenses: null,
  vehicle: false,
  placeOfResidence: false,
  killerQuestions: null
};

export interface offerListItem {
  oid: string;
  name: string;
  municipality: string;
  state: number;
  startOn: Date;
  finishOn: Date;
  totalCandidacies: number;
  unreadCandidacies: number;
  inProcessCandidacies: number;
  preselectedCandidacies: number;
  selectedCandidacies: number;
  discardedCandidacies: number;
  vacancies: number;
}
