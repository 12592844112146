import React, { useState, useEffect } from 'react';
import { Switch, Route, NavLink, Link, match } from 'react-router-dom';
import MyOffersContainer from '../layouts/MyOffers';
import OfferDetail from '../layouts/OfferDetail';
import Candidates from '../layouts/Candidates';
import CandidateDetail from '../layouts/CandidateDetail';
import NewOffer from '../layouts/NewOffer';

import PublishOffer from '../layouts/PublishOffer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router';

const checkActiveList = (match: any, location: any) => {
  if (!location) return false;
  const { pathname } = location;

  return pathname.includes('candidates');
};

const checkActiveDetail = (match: any, location: any) => {
  if (!location) return false;
  const { pathname } = location;

  return !pathname.includes('candidates');
};

const Offers: React.FC = (props: any) => {
  let location = useLocation();

  const [routeOid, setRouteOid] = useState(props.routeProps.location.state);

  useEffect(() => {
    setRouteOid(props.routeProps.location.state);
  }, [props.routeProps.location.state]);

  const offerOidProps = {
    ...props,
    setRouteOid,
  };
  return (
    <div>
      <Switch>
        <Route
          path={[
            '/private/offer-detail/:id',
            '/private/offer-detail/:id/candidates',
          ]}
          exact
        >
          <ul className='nav nav-pills mb-3'>
            <li className='nav-item'>
              <NavLink
                to={'/private/offer-detail/' + routeOid}
                className='nav-link nav-link-primary'
                isActive={checkActiveDetail}
              >
                Detalle oferta
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                isActive={checkActiveList}
                to={'/private/offer-detail/' + routeOid + '/candidates'}
                className='nav-link'
              >
                Candidatos inscritos
              </NavLink>
            </li>
          </ul>
        </Route>
      </Switch>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames='fade'
          timeout={{ enter: 300, exit: 300 }}
        >
          <Switch location={location}>
            <Route
              path='/private/new-offer/:id?'
              exact
              render={(routeProps) => (
                <NewOffer {...props} routeProps={routeProps} />
              )}
            />
            <Route
              path='/private/offer-detail/:id/candidates'
              exact
              render={(routeProps) => (
                <Candidates {...offerOidProps} routeProps={routeProps} />
              )}
            />
            <Route
              path='/private/offer-detail/:id/candidates/:idCandidate'
              render={(routeProps) => (
                <CandidateDetail {...props} routeProps={routeProps} />
              )}
            />
            <Route
              path={'/private/offer-detail/:id'}
              render={(routeProps) => (
                <OfferDetail {...offerOidProps} routeProps={routeProps} />
              )}
            />
            <Route
              path={'/private/publish/:id'}
              render={(routeProps) => (
                <PublishOffer {...props} routeProps={routeProps} />
              )}
            />
            <Route
              render={(routeProps) => (
                <MyOffersContainer {...props} routeProps={routeProps} />
              )}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Offers;
