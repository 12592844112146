import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import WrappedTypeahead from '../utils/WrappedTypeahead';
import { Form, Field, useField, useForm } from 'react-final-form';
import { Button } from 'reactstrap';
import arrayMutators from 'final-form-arrays';
import { atLeastOne, required } from '../utils/validations';
import { selectMultipleElements, selectSingleElement } from '../utils/selector';
import { config } from '../utils/Constants';
const Job: React.FC = (props: any) => {
  const [knowledgesList, setKnowledgesList] = useState([]);
  const [functionsList, setFunctionsList] = useState([]);

  useEffect(() => {
    if (props.offer.professions && props.offer.professions.length > 0) {
      fetch(
        `${config.apiURL}categories/8?parents=${props.offer.professions
          .map((profession: any) => {
            return profession.categoryOid
              ? profession.categoryOid
              : profession.oid;
          })
          .join(',')}`
      ).then((response: any) => {
        if (response.ok) {
          response.json().then((data: any) => {
            setFunctionsList(data.items);
          });
        }
      });

      fetch(
        `${config.apiURL}categories/36?parents=${props.offer.professions
          .map((profession: any) => {
            return profession.categoryOid
              ? profession.categoryOid
              : profession.oid;
          })
          .join(',')}`
      ).then((response: any) => {
        if (response.ok) {
          response.json().then((data: any) => {
            setKnowledgesList(data.items);
          });
        }
      });
    }
  }, []);

  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      initialValues={{
        companyOid: props.companyID,
        oid: props.offer.oid,
        sectors: props.offer.sectors
          ? selectMultipleElements(props.lists.sectorsList, props.offer.sectors)
          : [],
        subsectors: props.offer.subsectors
          ? selectMultipleElements(
              props.lists.subSectorsList,
              props.offer.subsectors
            )
          : [],
        professionalSkills: props.offer.professionalSkills
          ? selectMultipleElements(
              props.lists.professionalSkillList,
              props.offer.professionalSkills
            )
          : [],
        professions: props.offer.professions
          ? selectMultipleElements(
              props.lists.professionList,
              props.offer.professions
            )
          : [],
        experience: props.offer.experience
          ? [
              selectSingleElement(
                props.lists.experienceList,
                props.offer.experience
              )
            ]
          : undefined,
        peopleInCharge: props.offer.peopleInCharge
          ? [
              selectSingleElement(
                props.lists.peopleInCharge,
                props.offer.peopleInCharge
              )
            ]
          : undefined,
        functions: functionsList
          ? selectMultipleElements(functionsList, props.offer.functions)
          : [],
        knowledges: props.offer.knowledges
          ? selectMultipleElements(knowledgesList, props.offer.knowledges)
          : []
      }}
      onSubmit={(values: any) => {
        props.submitJob(
          props.stepper,
          props.setOfferID,
          values,
          props.companyID
        );
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        values,
        pristine,
        initialValues,
        invalid,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          <div className='card-user card shadow-none mb-0'>
            <div className=' card-header'>
              <h5 className='card-title mb-0' id='jobForm'>
                <i className='nc-icon nc-briefcase-24' /> Descripción del puesto
                de trabajo
              </h5>
            </div>
            <div className='card-body shadow-none'>
              <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-6'>
                  <div className='form-group'>
                    <label>Sector *</label>

                    <Field
                      validate={atLeastOne}
                      name='sectors'
                      isLoading={
                        !props.lists.sectorsList ||
                        props.lists.sectorsList.length == 0
                      }
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              isInvalid={field.meta.error && field.meta.touched}
                              id={field.input.name}
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              emptyLabel={'No se han encontrado resultados.'}
                              multiple
                              options={props.lists.sectorsList}
                              placeholder='Escribe para buscar...'
                              onChange={(value: any) => {
                                field.input.onChange(value);

                                let newSubsectors: any = [];
                                value.forEach((sector: any) => {
                                  form
                                    .getFieldState('subsectors')
                                    .value.forEach((subsector: any) => {
                                      if (subsector.parentOid == sector.oid) {
                                        newSubsectors.push(subsector);
                                      }
                                    });
                                });
                                form.change('subsectors', newSubsectors);
                              }}
                              defaultSelected={initialValues.sectors}
                            />
                            {field.meta.error && field.meta.touched && (
                              <div
                                className='invalid-feedback'
                                style={{ display: 'block' }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Subsector *</label>
                    <Field
                      className='form-control'
                      validate={atLeastOne}
                      name={'subsectors'}
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={'No se han encontrado resultados.'}
                              disabled={
                                !values.sectors || values.sectors.length < 1
                              }
                              isInvalid={field.meta.error && field.meta.touched}
                              maxResults={props.lists.subSectorsList.length}
                              paginate={false}
                              id={field.input.name}
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              filterBy={(option: any, props) => {
                                var include = false;

                                if (
                                  form.getFieldState('sectors') &&
                                  form.getFieldState('sectors').value
                                ) {
                                  var isFromSelectedSector = form
                                    .getFieldState('sectors')
                                    .value.find((sector: any) => {
                                      return sector.oid == option.parentOid;
                                    });

                                  var alreadySelected = field.input.value.find(
                                    (subsector: any) => {
                                      return subsector.oid == option.oid;
                                    }
                                  );

                                  include =
                                    isFromSelectedSector && !alreadySelected;
                                }

                                return (
                                  include &&
                                  option.text
                                    .toLowerCase()
                                    .indexOf(props.text.toLowerCase()) !== -1
                                );
                              }}
                              multiple
                              options={props.lists.subSectorsList}
                              placeholder='Escribe para buscar...'
                              onChange={(value: any) => {
                                field.input.onChange(value);
                              }}
                              selected={
                                form.getFieldState('subsectors') &&
                                form.getFieldState('subsectors').value
                                  ? form.getFieldState('subsectors').value
                                  : []
                              }
                            />

                            {field.meta.error && field.meta.touched && (
                              <div
                                className='invalid-feedback'
                                style={{ display: 'block' }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className='form-group'>
                    <label>Ocupaciones *</label>
                    <Field
                      type='select'
                      validate={atLeastOne}
                      name='professions'
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={'No se han encontrado resultados.'}
                              disabled={
                                props.offer.state !== 0 &&
                                props.offer.state !== 1
                              }
                              id={field.input.name}
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              multiple
                              options={props.lists.professionList}
                              placeholder='Escribe para buscar...'
                              onChange={(value: any) => {
                                field.input.onChange(value);
                              }}
                              defaultSelected={initialValues.professions}
                            />
                            {field.meta.error && field.meta.touched && (
                              <div
                                className='invalid-feedback'
                                style={{ display: 'block' }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='form-group'>
                        <label>Experiencia mínima *</label>
                        <Field
                          validate={atLeastOne}
                          name={'experience'}
                          render={(field: any) => {
                            return (
                              <div>
                                <Typeahead
                                  emptyLabel={
                                    'No se han encontrado resultados.'
                                  }
                                  inputProps={{
                                    className:
                                      field.meta.error &&
                                      field.meta.submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                  }}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  filterBy={(option: any, props) => {
                                    if (props.selected.length) {
                                      // Display all the options if there's a selection.
                                      return true;
                                    }
                                    // Otherwise filter on some criteria.
                                    return (
                                      option.text
                                        .toLowerCase()
                                        .indexOf(props.text.toLowerCase()) !==
                                      -1
                                    );
                                  }}
                                  id='experienceTypeahead'
                                  placeholder='Elige experiencia mínima...'
                                  options={props.lists.experienceList}
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  defaultSelected={initialValues.experience}
                                />
                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className='invalid-feedback'
                                    style={{ display: 'block' }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='form-group'>
                        <label>Personal a cargo *</label>

                        <Field
                          validate={atLeastOne}
                          name={'peopleInCharge'}
                          render={(field: any) => {
                            return (
                              <div>
                                <Typeahead
                                  emptyLabel={
                                    'No se han encontrado resultados.'
                                  }
                                  inputProps={{
                                    className:
                                      field.meta.error &&
                                      field.meta.submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                  }}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  filterBy={(option: any, props) => {
                                    if (props.selected.length) {
                                      // Display all the options if there's a selection.
                                      return true;
                                    }
                                    // Otherwise filter on some criteria.
                                    return (
                                      option.text
                                        .toLowerCase()
                                        .indexOf(props.text.toLowerCase()) !==
                                      -1
                                    );
                                  }}
                                  id='peopleInChargeTypeahead'
                                  placeholder='Elige personal a cargo...'
                                  options={props.lists.peopleInCharge}
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  defaultSelected={initialValues.peopleInCharge}
                                />
                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className='invalid-feedback'
                                    style={{ display: 'block' }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xs-12 col-sm-12 col-md-6'>
                  <div className='form-group'>
                    <label>Funciones</label>

                    <Field
                      name='functions'
                      render={(field: any) => {
                        return (
                          <div>
                            <WrappedTypeahead
                              url={`${config.apiURL}categories/8?parents=`}
                              disabled={
                                !values.professions ||
                                values.professions.length < 1
                              }
                              placeholder={'Escribe para buscar...'}
                              field={field}
                              values={values}
                              initialValues={initialValues.functions}
                              form={form}
                            ></WrappedTypeahead>
                       
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className='form-group'>
                    <label>Competencias *</label>
                    <Field
                      type='select'
                      validate={atLeastOne}
                      name='professionalSkills'
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={'No se han encontrado resultados.'}
                              id={field.input.name}
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              multiple
                              options={props.lists.professionalSkillList}
                              placeholder='Escribe para buscar...'
                              onChange={(value: any) => {
                                field.input.onChange(value);
                              }}
                              defaultSelected={initialValues.professionalSkills}
                            />
                            {field.meta.error && field.meta.touched && (
                              <div
                                className='invalid-feedback'
                                style={{ display: 'block' }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Conocimientos</label>
                    <Field
                      name='knowledges'
                      render={(field: any) => {
                        return (
                          <div>
                            <WrappedTypeahead
                              url={`${config.apiURL}categories/36?parents=`}
                              disabled={
                                !values.professions ||
                                values.professions.length < 1
                              }
                              placeholder={'Escribe para buscar...'}
                              field={field}
                              values={values}
                              initialValues={initialValues.knowledges}
                              form={form}
                            ></WrappedTypeahead>
                    
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <Button
                className='btn btn-primary'
                onClick={() => props.stepper.previous()}
              >
                Cancelar
              </Button>
              <Button
                disabled={submitting}
                className='btn btn-primary'
                type='submit'
              >
                Siguiente
              </Button>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default Job;
