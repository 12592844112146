import React, { useState, useEffect } from 'react';
import CandidatesComponent from '../components/Candidates';
import { config } from '../utils/Constants';

export interface myOfferCandidate {
  name: string;
  email: string;
  state: number;
}

const emptyCandidate: myOfferCandidate = {
  name: '',
  email: '',
  state: 0
};

const Candidates: React.FC = (props: any) => {
  props.setRouteOid(props.routeProps.match.params.id);
  const [offerCandidates, setOfferCandidates] = useState([emptyCandidate]);

  const getCandidates = async (state: any) => {
    const stateFilter = state !== null ? `?SearchQuery=state==${state}` : '';
    const link = `${config.apiURL}companies/${props.company.oid}/offers/${props.routeProps.match.params.id}/candidacies${stateFilter}`;

    const result = await fetch(link, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
      }
    });
    if (result.ok) {
      var response = await result.json();
      setOfferCandidates(response.items);
    }
  };

  useEffect(() => {
    getCandidates(null);
  }, []);

  const candidateProps = {
    ...props,
    offerCandidates,
    setOfferCandidates,
    getCandidates
  };

  return (
    <div>
      <CandidatesComponent {...candidateProps} />
    </div>
  );
};

export default Candidates;
