import React, { useState, useEffect } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { FormApi } from "final-form";

declare interface WageSlider {
  max?: number;
  min: number;
  form: FormApi;
  field: any;
  values: any;
  isReset: any;
}

const Slider: React.FC<WageSlider> = props => {
  const [salary, setSalary] = useState({
    min: 0,
    max: props.max
  });

  useEffect(
    () =>
      setSalary({
        min: 0,
        max: props.max
      }),
    [props.isReset]
  );

  return (
    <InputRange
      step={100}
      formatLabel={() => ""}
      maxValue={props.max}
      minValue={0}
      value={salary}
      onChange={(value: any) => {
        setSalary(value);
        props.field.input.onChange("salary", {
          min: value.min,
          max: value.max
        });
        props.form.change("salary", value);
      }}
    />
  );
};

export default Slider;
