import React from "react";

const PrivacyPolicy: React.FC = () => (
  <div className="card-user card ">
    <div className=" card-header">
      <h5 className="card-title " id="registro">
        Política de privacidad
      </h5>
    </div>
    <div className="card-body">
      <p className="costats">
        <b>Responsable del Tratamiento</b>
      </p>
      <p className="costats">
        La empresa responsable del tratamiento de sus datos en virtud de esta
        Política de privacidad es:
      </p>
      <p className="costats">
        WORKETIK PORTAL,S.L
        <br />
        NIF: B67485375
        <br />
        Domicilio: Av. Països Catalans, 41 - 08700 Igualada (Barcelona)
        <br />
        Dirección de correo electrónico: datos@worketik.com
        <br />
        Web: www.worketik.com
        <br />
        Teléfono: +34 644 107 016
        <br />
        Inscrita en el Registro Mercantil de Tomo 47015, Folio161, Hoja 538204,
        Inscripción 1a
      </p>
      <p className="costats">
        En WORKETIK PORTAL,S.L, reconocemos la importancia de proteger su
        información personal y estamos comprometidos a tratarla con
        responsabilidad y de conformidad con las leyes de protección de datos.
      </p>
      <p className="costats">
        La presente política de privacidad pretende regular todos los aspectos
        relativos al tratamiento de datos de los diferentes usuarios que navegan
        o facilitan sus datos personales a través de los diferentes formularios
        situados en la web.
      </p>
      <p className="costats">
        <b>Datos Personales</b>
      </p>
      <p className="costats">
        Un dato personal, es la información que le identifica o le hace
        identificable. A través de la web, en las casillas establecidas al
        efecto recogemos los datos personales que el usuario nos comunica:
        Nombre, apellidos, correo electrónico, teléfono, dirección postal,
        población, etc. así como todos aquellos datos que voluntariamente nos
        proporcione a través de cualquier de las redes sociales de las cuales el
        usuario está registrado. En este caso, la privacidad dependerá de la
        propia configuración establecida por el usuario así como de los términos
        y condiciones de la propia red social.
      </p>
      <p className="costats">
        La visita del usuario a la web no implica que tenga que facilitar
        ninguna información sobre sus datos personales. No obstante, en caso de
        facilitarlos, los datos serán tratados de forma lícita con sujeción en
        todo momento a los principios y derechos recogidos en el RGPD 2016/679
        de 27 de abril de 2016 y la LOPDGDD 3/2018 del 5 de diciembre.
      </p>
      <p className="costats">
        <b>Finalidad, duración y legitimación del tratamiento:</b>
      </p>
      <p className="costats">
        Los datos que el usuario nos proporcione los trataremos con las
        siguientes finalidades:
      </p>
      <p className="costats">
        <ul>
          <li>
            <p>
              {" "}
              Atender la solicitud o petición realizada por el usuario a través
              del <b>formulario de inscripción oferta</b> situado en la web.
              Recogemos y tratamos los datos personales del usuario para poder
              tramitar y gestionar su solicitud e inscripción a una oferta de
              trabajo que realiza a través de dicho formulario. Estos datos los
              conservaremos durante el tiempo necesario para dar cumplimiento a
              su petición y durante el tiempo establecido por la ley con una
              duración mínima de 3 años.
            </p>
            <p>
              La base de legitimación del tratamiento de los datos será el
              consentimiento que otorga el usuario al seleccionar la casilla de
              aceptación de nuestra política de privacidad antes de enviar la
              petición. El usuario tiene derecho a revocar su consentimiento en
              cualquier momento sin que ello afecte a la licitud del tratamiento
              basado en el consentimiento previo a su retirada.
            </p>
          </li>
          <li>
            <p>
              Dar de alta como "organización registrada" a través
              <b> del formulario de registro</b> situado en la web y que
              permitirá al usuario acceder a su área privada de servicios e
              informarle de nuestras actividades, pudiéndole remitir por
              cualquier medio electrónico comunicaciones comerciales de las
              novedades de nuestros servicios. Estos datos los conservaremos
              mientras el usuario no solicite su baja del registro y durante el
              tiempo establecido por la ley con una duración mínima de 3 años.
            </p>
            <p>
              La base de legitimación del tratamiento de los datos será el
              consentimiento que otorga el usuario al registrarse a través del
              formulario de registro y seleccionar la casilla de aceptación de
              nuestra política de privacidad antes de enviar su solicitud. El
              usuario tiene derecho a revocar su consentimiento en cualquier
              momento sin que ello afecte a la licitud del tratamiento basado en
              el consentimiento previo a su retirada.
            </p>
          </li>
          <li>
            <p>
              Mejorar su experiencia al navegar por la web. Estos datos los
              conservaremos mientras el usuario no revoque su consentimiento al
              tratamiento de los mismos eliminando las cookies y durante el
              tiempo establecido por la ley con una duración mínima de 3 años.
            </p>
            <p>
              La base de legitimación para mejorar su experiencia al navegar por
              la web es el consentimiento que otorga el usuario al aceptar las
              cookies. El usuario tiene derecho a revocar su consentimiento en
              cualquier momento sin que ello afecte a la licitud del tratamiento
              basado en el consentimiento previo a su retirada.
            </p>
          </li>
          <li>
            <p>
              Gestionar nuestras <b>redes sociales</b> y enviarle información
              sobre nuestras actividades y productos. Estos datos los
              conservaremos mientras el usuario no revoque su consentimiento al
              tratamiento de los mismos y durante el tiempo establecido por la
              ley con una duración mínima de 3 años.
            </p>
            <p>
              La base legítima para gestionar nuestras redes sociales y enviarle
              nuestras actividades y novedades, es el consentimiento que nos
              otorga para ello. El usuario tiene derecho a revocar su
              consentimiento en cualquier momento sin que ello afecte a la
              licitud del tratamiento basado en el consentimiento previo a su
              retirada.
            </p>
          </li>
        </ul>
      </p>
      <p className="costats">
        <b>
          Cesión o comunicación de datos personales y transferencias
          internacionales:
        </b>
      </p>
      <p className="costats">
        Sus datos no se comunicarán ni serán cedidos a terceros salvo que exista
        alguna obligación legal o aquellos prestadores vinculados al Responsable
        que actúan como encargados del tratamiento.
      </p>
      <p className="costats">
        No se realizan transferencias internacionales, para el caso en que en
        algún momento pudieran realizarse, se le comunicarán con el objeto de
        obtener su consentimiento.
      </p>
      <p className="costats">
        <b>Actualización de datos</b>
      </p>
      <p className="costats">
        Es importante que para que podamos mantener los datos personales
        actualizados, el usuario nos informe siempre que haya habido alguna
        modificación en ellos, en caso contrario, no respondemos de la veracidad
        de los mismos.
      </p>
      <p className="costats">
        El usuario garantiza que los datos personales facilitados son veraces
        garantizando que toda la información facilitada corresponde con la
        situación real, que está actualizada y es exacta, quedando obligado a
        comunicar cualquier modificación.
      </p>
      <p className="costats">
        <b>Datos de Terceros</b>
      </p>
      <p className="costats">
        Si el usuario facilita datos de terceras personas para cualquier
        finalidad a WORKETIK PORTAL,S.L, garantiza que ha obtenido estos datos
        de forma lícita, que ha informado previamente a los afectados,
        obteniendo su consentimiento para comunicarlos y que la información
        facilitada es exacta y veraz.
      </p>
      <p className="costats">
        <b>Carácter obligatorio de la información solicitada</b>
      </p>
      <p className="costats">
        Todos nuestros formularios cuentan con un asterisco (*) en los datos
        obligatorios. Si el usuario no facilita esos campos, o no marca la
        casilla de aceptación de la política de privacidad, no se permitirá el
        envío de la información.
      </p>
      <p className="costats">
        <b>Uso de las contraseñas</b>
      </p>
      <p className="costats">
        Para poder acceder al Área Privada, el USUARIO deberá “Iniciar Sesión” e
        incluir la dirección del correo electrónico y contraseña generada en el
        formulario de registro de acuerdo a las reglas de complejidad que se
        establezcan en cada momento en la web.
      </p>
      <p className="costats">
        Los usuarios son responsables de la adecuada custodia y confidencialidad
        de cualesquiera de los identificadores y/o contraseñas que hayan
        seleccionado en el registro, y se comprometen a no ceder su uso a
        terceros, ni permitir su acceso a personas ajenas.
      </p>
      <p className="costats">
        Asimismo, será obligación del usuario notificar de forma inmediata al
        Responsable cualquier hecho que permita el uso indebido de los
        identificadores y/o contraseñas, tales como el robo, el extravío, o el
        acceso no autorizado a los mismos, con el fin de proceder a su inmediata
        cancelación.
      </p>
      <p className="costats">
        <b>Derechos de los interesados:</b>
      </p>
      <p className="costats">
        Tiene derecho a acceder a sus datos y a obtener confirmación sobre su
        tratamiento, así como una copia de los datos personales objeto de
        tratamiento. Tiene derecho a actualizarlos y solicitar la rectificación
        de los datos que sean inexactos o solicitar la supresión cuando los
        datos no sean necesarios para los fines para los que fueron recabados.
        Puede solicitar la limitación en el tratamiento de sus datos y oponerse
        al tratamiento de los mismos revocando su consentimiento, así como
        ejercer el derecho a la portabilidad de los datos. Del mismo modo, tiene
        derecho a no ser objeto de decisiones basadas únicamente en el
        tratamiento automatizado de sus datos personales. Puede ejercer sus
        derechos contactando con nosotros Av. Països Catalans, 41 - 08700
        Igualada (Barcelona). Email: datos@worketik.com
      </p>
      <p className="costats">
        Si considera que sus derechos no se han atendido debidamente, tiene
        derecho a presentar una reclamación ante la autoridad de control en
        www.aepd.es
      </p>
      <p className="costats">
        <b>Tratamiento de datos de Menores de edad</b>
      </p>
      <p className="costats">
        Quien facilita los datos a través de los formularios de esta web y
        acepta su tratamiento declara ser mayor de 14 años, quedando prohibido
        el acceso y uso del portal a menores de dicha edad. Si en algún momento,
        el Responsable detecta que un menor de 14 años ha proporcionado datos de
        carácter personal, procederemos a la cancelación de los mismos.
        Asimismo, los padres o tutores podrán en cualquier caso dirigirse a
        WORKETIK PORTAL,S.L para bloquear la cuenta de acceso de los menores a
        su cargo que se hubieran registrado falseando su identidad.
      </p>
      <p className="costats">
        <b>Redes sociales</b>
      </p>
      <p className="costats">
        El objeto de las herramientas como Facebook, Twitter, Instagram, etc. u
        otras redes sociales es dar visibilidad y difusión a las actividades que
        desarrolla nuestra organización. Estas herramientas almacenan datos
        personales en los servidores de los servicios respectivos y se rigen por
        su propia política de privacidad. Se recomienda la revisión y la lectura
        de las condiciones de uso y política de privacidad de la red social en
        el momento de registrarse, teniendo en cuenta las diferentes
        posibilidades de configuración en relación al grado de privacidad del
        perfil de usuario en la red social.
      </p>
      <p className="costats">
        Asimismo, el Responsable se reserva el derecho de eliminar de sus redes
        sociales cualquier información publicada por terceros que vulnere la
        legalidad, incite a hacerlo o contenga mensajes que atenten contra la
        dignidad de personas o instituciones. Como también el derecho a bloquear
        o denunciar el perfil autor de estos mensajes.
      </p>
      <p className="costats">
        <b>Tratamiento de Cookies:</b>
      </p>
      <p className="costats">
        Una cookie es un pequeño archivo que se descarga y almacena en el
        ordenador del usuario cuando este accede a una página web. Las Cookies
        permiten a la web, entre otras cosas, almacenar y recuperar información
        sobre los hábitos de navegación del usuario o de su equipo y,
        dependiendo de la información que contengan y de la forma en que utilice
        su equipo, pueden utilizarse para reconocer al usuario.
      </p>
      <p className="costats">
        El usuario tiene la opción de impedir la generación de cookies, mediante
        la selección de la correspondiente opción en su programa de navegador.
        Puede obtener más información leyendo nuestra Política de Cookies.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
