import React, { useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Typeahead,
  AllTypeaheadOwnAndInjectedProps,
  TypeaheadModel
} from "react-bootstrap-typeahead";
import { myCompany } from "../../models/MyCompany";
import { Form, Field } from "react-final-form";
import { selectSingleElement } from "../../utils/selector";
import {
  required,
  validPhoneNumber,
  composeValidators
} from "../../utils/validations";

const spainID = "00000000-0000-0000-0000-0000000000a8";

const Location: React.FC<myCompany> = (props: any) => {
  const [modal, setModal] = useState(false);
  const provincesRef = useRef(null);
  const municipalitiesRef = useRef(null);
  const postalCodeRef = useRef(null);

  const [localInitialValues, setLocalInitialValues] = useState({
    id: 0,
    oid: null,
    name: null,
    country: null,
    province: null,
    municipality: null,
    postalCode: null,
    postalAddress: null,
    building: null,
    phoneNumber1: null,
    phoneNumber2: null
  });

  return (
    <div>
      <div className="card-user card">
        <div className="card-header">
          <h5 className="card-title mb-2">Ubicación/es</h5>
          <div className="mb-2">
            <a
              href=""
              onClick={(e: any) => {
                e.preventDefault();
                setModal(true);
                setLocalInitialValues({
                  id: 0,
                  oid: null,
                  name: null,
                  country: null,
                  province: null,
                  municipality: null,
                  postalCode: null,
                  postalAddress: null,
                  building: null,
                  phoneNumber1: null,
                  phoneNumber2: null
                });
              }}
            >
              + Añadir ubicación
            </a>
          </div>

          <table className="table">
            <tbody>
              {props.addresses.map((item: any, i: any) => {
                return (
                  <tr key={i}>
                    <th scope="row">{item.Id}</th>
                    <td>
                      <b>{item.name}</b>
                      <br />
                      {item.postalAddress}
                      <br />
                      {item.building}
                      {item.building && <br />}
                      {item.postalCode ? item.postalCode.text : "-"}{" "}
                      {item.municipality ? item.municipality.text : ""} (
                      {item.province ? item.province.text : ""}-{" "}
                      {item.country ? item.country.text : ""})<br />
                      {item.phoneNumber1}{" "}
                      {item.phoneNumber2 ? " - " + item.phoneNumber2 : null}
                    </td>
                    <td>
                      <a
                        href=""
                        className="btn btn-outline-primary btn-sm p-1 mr-1 pull-right"
                        onClick={(e: any) => {
                          e.preventDefault();

                          setModal(true);
                          setLocalInitialValues({
                            id: item.id,
                            oid: item.oid,
                            name: item.name,
                            country: selectSingleElement(
                              props.lists.countriesList,
                              item.country
                            ),
                            province: item.province.isCustomValue
                              ? selectSingleElement(
                                  props.lists.provincesList.concat(
                                    item.province
                                  ),
                                  item.province
                                )
                              : selectSingleElement(
                                  props.lists.provincesList,
                                  item.province
                                ),

                            municipality: item.municipality.isCustomValue
                              ? selectSingleElement(
                                  props.lists.municipalitiesList.concat(
                                    item.municipality
                                  ),
                                  item.municipality
                                )
                              : selectSingleElement(
                                  props.lists.municipalitiesList,
                                  item.municipality
                                ),
                            postalCode: {
                              oid: item.postalCode
                                ? item.postalCode.categoryOid
                                : null,
                              text: item.postalCode
                                ? item.postalCode.text
                                : null
                            },
                            postalAddress: item.postalAddress,
                            building: item.building,
                            phoneNumber1: item.phoneNumber1,
                            phoneNumber2: item.phoneNumber2
                          });
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </a>
                      {props.addresses.length > 1 && i != 0 ? (
                        <a
                          href=""
                          className="btn btn-outline-primary btn-sm p-1 mr-1 pull-right"
                          onClick={(e: any) => {
                            e.preventDefault();

                            props.deleteAddress(item.oid);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </a>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Form
        onSubmit={(values: any) => {
          setModal(!modal);
          values.oid == null
            ? props.postAddress(values)
            : props.putAddress(values);
        }}
        initialValues={localInitialValues}
        render={({ handleSubmit, form, values, initialValues, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader toggle={() => setModal(!modal)}>
                  Añadir ubicación
                </ModalHeader>
                <ModalBody>
                  <div>
                    <Field
                      name="oid"
                      type="text"
                      component="input"
                      defaultValue={props.addresses.oid}
                      hidden
                    />
                    <div className="form-group">
                      <label className="mb-0">Nombre ubicación *</label>
                      <Field
                        className={
                          form.getFieldState("name") &&
                          form.getFieldState("name").error &&
                          form.getFieldState("name").submitFailed
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="text"
                        autoComplete="off"
                        name="name"
                        component="input"
                        validate={required}
                        readOnly={initialValues.id == 1}
                      />
                      <div className="invalid-feedback">
                        {form.getFieldState("name") &&
                          form.getFieldState("name").error}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>País *</label>
                          <Field
                            type="select"
                            validate={required}
                            name="country"
                            render={(field: any) => {
                              return (
                                <div>
                                  <Typeahead
                                    emptyLabel={
                                      !props.lists.countriesList
                                        ? "Cargando..."
                                        : "No se han encontrado resultados."
                                    }
                                    inputProps={{
                                      className:
                                        field.meta.error &&
                                        field.meta.submitFailed
                                          ? "form-control is-invalid"
                                          : "form-control"
                                    }}
                                    filterBy={(option, props) => {
                                      if (props.selected.length) {
                                        // Display all the options if there's a selection.
                                        return true;
                                      }
                                      // Otherwise filter on some criteria.
                                      return (
                                        option.text
                                          .toLowerCase()
                                          .indexOf(props.text.toLowerCase()) !==
                                        -1
                                      );
                                    }}
                                    maxResults={
                                      props.lists.countriesList.length
                                    }
                                    paginate={false}
                                    id={field.input.name}
                                    labelKey={(option: any) => {
                                      return option.text
                                        ? option.text
                                        : undefined;
                                    }}
                                    options={props.lists.countriesList}
                                    placeholder="Selecciona..."
                                    onChange={(value: any) => {
                                      form.change("province", null);
                                      form.change("municipality", null);
                                      form.change("postalCode", null);
                                      field.input.onChange(value[0]);
                                      if (provincesRef.current) {
                                        provincesRef.current.clear();
                                      }
                                      if (municipalitiesRef.current) {
                                        municipalitiesRef.current.clear();
                                      }
                                      if (postalCodeRef.current) {
                                        postalCodeRef.current.clear();
                                      }
                                    }}
                                    defaultSelected={
                                      initialValues.country
                                        ? [initialValues.country]
                                        : []
                                    }
                                  />
                                  {field.meta.error && field.meta.touched && (
                                    <div
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    >
                                      {field.meta.error}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Provincia *</label>
                          {(values.country == null ||
                            values.country.oid === spainID) && (
                            <Field
                              type="select"
                              validate={required}
                              name="province"
                              render={(field: any) => {
                                return (
                                  <div>
                                    <Typeahead
                                      emptyLabel={
                                        !props.lists.provincesList
                                          ? "Cargando..."
                                          : "No se han encontrado resultados."
                                      }
                                      inputProps={{
                                        className:
                                          field.meta.error &&
                                          field.meta.submitFailed
                                            ? "form-control is-invalid"
                                            : "form-control"
                                      }}
                                      ref={provincesRef}
                                      id={field.input.name}
                                      labelKey={(option: any) => {
                                        return option.text
                                          ? option.text
                                          : undefined;
                                      }}
                                      filterBy={(option, props) => {
                                        if (props.selected.length) {
                                          // Display all the options if there's a selection.
                                          return true;
                                        }
                                        // Otherwise filter on some criteria.
                                        return (
                                          option.text
                                            .toLowerCase()
                                            .indexOf(
                                              props.text.toLowerCase()
                                            ) !== -1
                                        );
                                      }}
                                      options={props.lists.provincesList}
                                      placeholder="Selecciona..."
                                      onChange={(value: any) => {
                                        field.input.onChange(value[0]);

                                        form.change("municipality", null);
                                        form.change("postalCode", null);

                                        if (municipalitiesRef.current) {
                                          municipalitiesRef.current.clear();
                                        }
                                        if (postalCodeRef.current) {
                                          postalCodeRef.current.clear();
                                        }
                                      }}
                                      defaultSelected={
                                        field.meta.pristine &&
                                        initialValues.province
                                          ? [initialValues.province]
                                          : initialValues.province
                                          ? [initialValues.province]
                                          : []
                                      }
                                    />
                                    {field.meta.error && field.meta.touched && (
                                      <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                      >
                                        {field.meta.error}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          )}
                          {values.country && values.country.oid !== spainID && (
                            <Field
                              validate={required}
                              render={(field: any) => {
                                return (
                                  <div>
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      defaultValue={
                                        field.meta.pristine &&
                                        initialValues.oid !== null
                                          ? initialValues.province.text.toString()
                                          : undefined
                                      }
                                      className={
                                        form.getFieldState("province") &&
                                        form.getFieldState("province").error &&
                                        form.getFieldState("province")
                                          .submitFailed
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      onChange={(e: any) => {
                                        e.target.value
                                          ? field.input.onChange({
                                              oid: "",
                                              text: e.target.value
                                            })
                                          : field.input.onChange("");
                                      }}
                                    ></input>
                                    <div className="invalid-feedback">
                                      {form.getFieldState("province") &&
                                        form.getFieldState("province").error}
                                    </div>
                                  </div>
                                );
                              }}
                              name="province"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-0">Dirección postal *</label>
                      <Field
                        name="postalAddress"
                        component="input"
                        type="text"
                        autoComplete="off"
                        validate={required}
                        className={
                          form.getFieldState("postalAddress") &&
                          form.getFieldState("postalAddress").error &&
                          form.getFieldState("postalAddress").submitFailed
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      <div className="invalid-feedback">
                        {form.getFieldState("postalAddress") &&
                          form.getFieldState("postalAddress").error}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-0">
                        Edificio, Puerta, apartamento... (Opcional)
                      </label>
                      <Field
                        name="building"
                        component="input"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Municipio *</label>
                          {(values.country == null ||
                            values.country.oid === spainID) && (
                            <Field
                              validate={required}
                              type="select"
                              name="municipality"
                              render={(field: any) => {
                                return (
                                  <div>
                                    <Typeahead
                                      emptyLabel={
                                        !props.lists.municipalitiesList
                                          ? "Cargando..."
                                          : "No se han encontrado resultados."
                                      }
                                      inputProps={{
                                        className:
                                          field.meta.error &&
                                          field.meta.submitFailed
                                            ? "form-control is-invalid"
                                            : "form-control"
                                      }}
                                      ref={municipalitiesRef}
                                      maxResults={
                                        props.lists.municipalitiesList.length
                                      }
                                      paginate={false}
                                      id={field.input.name}
                                      filterBy={(
                                        option: any,
                                        props: AllTypeaheadOwnAndInjectedProps<
                                          TypeaheadModel
                                        >
                                      ) => {
                                        var include = false;

                                        if (
                                          values.province != null &&
                                          option != null &&
                                          values.province.oid ===
                                            option.parentOid
                                        ) {
                                          include =
                                            props.selected.length > 0 ||
                                            option.text
                                              .toLowerCase()
                                              .indexOf(
                                                props.text.toLowerCase()
                                              ) !== -1;
                                        }

                                        return include;
                                      }}
                                      labelKey={(option: any) => {
                                        return option.text
                                          ? option.text
                                          : undefined;
                                      }}
                                      options={props.lists.municipalitiesList}
                                      placeholder="Selecciona..."
                                      onChange={(value: any) => {
                                        field.input.onChange(value[0]);
                                        form.change("postalCode", null);

                                        if (postalCodeRef.current) {
                                          postalCodeRef.current.clear();
                                        }
                                      }}
                                      defaultSelected={
                                        field.meta.pristine &&
                                        initialValues.municipality &&
                                        values.country &&
                                        values.country.oid &&
                                        values.province &&
                                        values.province.oid
                                          ? [initialValues.municipality]
                                          : []
                                      }
                                      disabled={
                                        !values.province ||
                                        values.province.length < 1
                                      }
                                    />
                                    {field.meta.error && field.meta.touched && (
                                      <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                      >
                                        {field.meta.error}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          )}
                          {values.country && values.country.oid !== spainID && (
                            <Field
                              validate={required}
                              render={(field: any) => {
                                return (
                                  <div>
                                    <input
                                    autoComplete="off"
                                      type="text"
                                      defaultValue={
                                        field.meta.pristine &&
                                        initialValues.oid !== null
                                          ? initialValues.municipality.text.toString()
                                          : undefined
                                      }
                                      className={
                                        form.getFieldState("municipality") &&
                                        form.getFieldState("municipality")
                                          .error &&
                                        form.getFieldState("municipality")
                                          .submitFailed
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      onChange={(e: any) => {
                                        e.target.value
                                          ? field.input.onChange({
                                              oid: "",
                                              text: e.target.value
                                            })
                                          : field.input.onChange("");
                                      }}
                                    ></input>
                                    <div className="invalid-feedback">
                                      {form.getFieldState("municipality") &&
                                        form.getFieldState("municipality")
                                          .error}
                                    </div>
                                  </div>
                                );
                              }}
                              name="municipality"
                            />
                          )}
                          <div className="invalid-feedback">
                            Seleccione una opción para continuar.
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Codigo postal *</label>
                          {(!values.country ||
                            values.country.oid === spainID) && (
                            <Field
                              type="select"
                              name="postalCode"
                              validate={required}
                              render={(field: any) => {
                                return (
                                  <div>
                                    <Typeahead
                                      inputProps={{
                                        className:
                                          field.meta.error &&
                                          field.meta.submitFailed
                                            ? "form-control is-invalid"
                                            : "form-control"
                                      }}
                                      emptyLabel={
                                        !props.lists.postalCodesList
                                          ? "Cargando..."
                                          : "No se han encontrado resultados."
                                      }
                                      ref={postalCodeRef}
                                      maxResults={
                                        props.lists.postalCodesList.length
                                      }
                                      paginate={false}
                                      id={field.input.name}
                                      filterBy={(option: any) => {
                                        if (values.municipality != null) {
                                          return (
                                            values.municipality.oid ===
                                            option.parentOid
                                          );
                                        } else {
                                          return true;
                                        }
                                      }}
                                      labelKey={(option: any) => {
                                        return option.text
                                          ? option.text
                                          : undefined;
                                      }}
                                      options={props.lists.postalCodesList}
                                      placeholder="Selecciona..."
                                      onChange={(value: any) => {
                                        field.input.onChange(value[0]);
                                      }}
                                      defaultSelected={
                                        field.meta.pristine &&
                                        values.country &&
                                        values.country.oid &&
                                        values.province &&
                                        values.province.oid &&
                                        values.municipality &&
                                        values.municipality.oid &&
                                        initialValues.postalCode
                                          ? [initialValues.postalCode]
                                          : []
                                      }
                                      disabled={
                                        !values.country ||
                                        !values.country.oid ||
                                        !values.province ||
                                        !values.province.oid ||
                                        !values.municipality ||
                                        !values.municipality.oid
                                      }
                                    />
                                    {field.meta.error && field.meta.touched && (
                                      <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                      >
                                        {field.meta.error}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          )}
                          {values.country && values.country.oid !== spainID && (
                            <Field
                              validate={required}
                              render={(field: any) => {
                                return (
                                  <div>
                                    <input
                                    autoComplete="off"
                                      type="text"
                                      defaultValue={
                                        field.meta.pristine &&
                                        initialValues.oid !== null
                                          ? initialValues.postalCode.text.toString()
                                          : undefined
                                      }
                                      className={
                                        form.getFieldState("postalCode") &&
                                        form.getFieldState("postalCode")
                                          .error &&
                                        form.getFieldState("postalCode")
                                          .submitFailed
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      onChange={(e: any) => {
                                        e.target.value
                                          ? field.input.onChange({
                                              oid: "",
                                              text: e.target.value
                                            })
                                          : field.input.onChange("");
                                      }}
                                    ></input>
                                    <div className="invalid-feedback">
                                      {form.getFieldState("postalCode") &&
                                        form.getFieldState("postalCode").error}
                                    </div>
                                  </div>
                                );
                              }}
                              name="postalCode"
                            />
                          )}
                          <div className="invalid-feedback">
                            Rellene este campo para continuar
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Teléfono 1 *</label>
                          <Field
                          autoComplete="off"
                            type="tel"
                            name="phoneNumber1"
                            id="phone"
                            className={
                              form.getFieldState("phoneNumber1") &&
                              form.getFieldState("phoneNumber1").error &&
                              form.getFieldState("phoneNumber1").submitFailed
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            component="input"
                            validate={composeValidators(
                              required,
                              validPhoneNumber
                            )}
                          />

                          <div className="invalid-feedback">
                            {form.getFieldState("phoneNumber1") &&
                              form.getFieldState("phoneNumber1").error}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Teléfono 2 (Opcional)</label>
                          <Field
                          autoComplete="off"
                            validate={validPhoneNumber}
                            type="tel"
                            name="phoneNumber2"
                            id="phone"
                            className={
                              form.getFieldState("phoneNumber2") &&
                              form.getFieldState("phoneNumber2").error &&
                              form.getFieldState("phoneNumber2").submitFailed
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            component="input"
                          />
                          <div className="invalid-feedback">
                            {form.getFieldState("phoneNumber2") &&
                              form.getFieldState("phoneNumber2").error}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="btn-outline-primary"
                    onClick={() => setModal(!modal)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={submitting}
                    className="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    {initialValues ? "Guardar" : "Añadir"}
                  </Button>{" "}
                </ModalFooter>
              </Modal>
            </form>
          );
        }}
      />
    </div>
  );
};
export default Location;
