import ProfileView from "./views/Profile";
import MyOffersView from "./views/MyOffers";

export default function getRoutes(mode: string) {
  return mode === "CANDIDATO" ? null : ArrayEmp;
}

const ArrayEmp = [
  {
    isMenuItem: true,
    linkName: "",
    linkValue: "/private",
    path: "/private/my-offers",
    name: "Ofertas",
    icon: "nc-icon nc-paper",
    component: MyOffersView,
    layout: "/private"
  },
  {
    isMenuItem: false,
    linkName: "",
    linkValue: "/private/my-offers",
    path: "/private/offer-detail",
    name: "Ofertas",
    icon: "nc-icon nc-paper",
    component: MyOffersView,
    layout: "/private"
  },
  {
    isMenuItem: false,
    linkName: "",
    linkValue: "/private/my-offers",
    path: "/private",
    name: "Ofertas",
    icon: "nc-icon nc-paper",
    component: MyOffersView,
    layout: "/private"
  },
  {
    isMenuItem: false,
    linkName: "",
    linkValue: "/private/my-offers",
    path: "/private/new-offer",
    name: "Ofertas",
    icon: "nc-icon nc-paper",
    component: MyOffersView,
    layout: "/private"
  },
  {
    isMenuItem: true,
    linkName: "",
    linkValue: "",
    path: "/private/profile",
    name: "Perfil",
    icon: "nc-icon nc-shop",
    component: ProfileView,
    layout: "/private"
  },
  {
    isMenuItem: false,
    linkName: "",
    linkValue: "/private/profile",
    path: "/private/contract-service",
    name: "Perfil",
    icon: "nc-icon nc-shop",
    component: ProfileView,
    layout: "/private"
  }
];
