declare interface Error {
  code: number;
  message: string;
}

const errors: any[] = [
  {
    //BadToken
    message:
      "Lo sentimos, la página a la que intentas acceder no existe o no tienes permisos para visualizarla.",
    code: 1
  },
  {
    //OrganizationNotAuthorized
    message:
      "Lo sentimos, la página a la que intentas acceder no existe o no tienes permisos para visualizarla.",
    code: 2
  },
  {
    //UserNotAuthorized
    message:
      "Lo sentimos, la página a la que intentas acceder no existe o no tienes permisos para visualizarla.",
    code: 3
  },
  {
    //PasswordNotValidated
    message: "El correo electrónico y/o la contraseña es/son incorrectas.",
    code: 4
  },
  {
    //ItemNotAuthorized
    message:
      "Lo sentimos, la página a la que intentas acceder no existe o no tienes permisos para visualizarla.",
    code: 5
  },
  {
    //PasswordConfirmationError
    message: "Las contraseñas no coinciden, introdúzcalas de nuevo por favor.",
    code: 6
  },
  {
    //UserNotActivated
    message:
      "Cuenta aún no activa, por favor, accede a tu correo para activarla.",
    code: 7
  },
  {
    //UserAlreadyActivated
    message:
      "Tu cuenta ya ha sido activada. Ya puedes acceder a la zona de organizaciones.",
    code: 8
  },
  {
    //UserIsInactive
    message:
      "Cuenta aún no activa, por favor, accede a tu correo para activarla.",
    code: 9
  },
  {
    //NoJsonFoundInBody
    message:
      "Se ha producido un error. Si persiste, ponte en contacto con nosotros y te ayudaremos.",
    code: 10
  },
  {
    //UserNameIsRequired
    message: "Añade tu nombre y apellidos",
    code: 20
  },
  {
    //PasswordIsRequired
    message: "Añade la contraseña",
    code: 21
  },
  {
    //UserNotFound
    message: "No se ha encontrado este usuario",
    code: 22
  },
  {
    //UserAlreadyExists
    message: "Ya existe una cuenta con éste correo electrónico. ",
    code: 23
  },
  {
    // CompanyIsRequired
    message: "Añade el nombre de la organización",
    code: 101
  },
  {
    // AddressIsRequired
    message: "Añade la dirección postal",
    code: 102
  },
  {
    //PersonIsRequired
    message: "Añade una persona de contacto",
    code: 103
  },
  {
    //NameIsRequired
    message: "Añade su nombre",
    code: 104
  },
  {
    //PhoneNumberIsRequired
    message: "Añade un teléfono",
    code: 105
  },
  {
    //CountryOidIsRequired
    message: "Añade el país",
    code: 106
  },
  {
    //DescriptionIsRequired
    message: "Añade una descripción",
    code: 107
  },
  {
    //CreatedOnIsRequired
    message: "Añade el año de creación",
    code: 108
  },
  {
    //SectorsIsRequired
    message: "Selecciona uno o más sectores",
    code: 109
  },
  {
    //SubsectorsIsRequired
    message: "Selecciona uno o más subsectores",
    code: 110
  },
  {
    //LanguagesIsRequired
    message: "Selecciona uno o más subsectores",
    code: 111
  },
  {
    //CompanySizeIsRequired
    message: "Añade el tamaño de la organización",
    code: 112
  },
  {
    //OrganizationTypeIsRequired
    message: "Selecciona un tipo de organización",
    code: 113
  },
  {
    //SocialResponsibilitiesIsRequired
    message: "Selecciona uno o más beneficios sociales",
    code: 114
  },
  {
    //UrlIsRequired
    message: "Añade una URL",
    code: 115
  },

  {
    //ContractedServiceIsRequired
    message: "Selecciona un pack de publicación",
    code: 118
  },

  {
    //NoAvailableWeeks
    message:
      "Actualmente no dispones de semanas de publicación de oferta/s. Consulta nuestros packs.",
    code: 122
  },
  {
    //CountryIsRequired
    message: "Añade el país",
    code: 123
  },
  {
    //CountryIsRequired
    message: "Añade el país",
    code: 124
  },
  {
    //CountryIsRequired
    message: "Añade el país",
    code: 125
  },
  {
    //CountryIsRequired
    message: "Añade el país",
    code: 126
  },
  {
    //FileIsRequired
    message: "Añade un documento",
    code: 127
  },
  {
    //StateIsRequired
    message: "Selecciona un estado",
    code: 128
  },
  {
    //IdIsRequired
    message: "Añade el CIF",
    code: 129
  },
  {
    //CompanyNotFound
    message: "No se ha encontrado esta organización",
    code: 201
  },
  {
    //LinkNotFound
    message: "No se ha encontrado este enlace",
    code: 202
  },
  {
    //AddressNotFound
    message: "No se ha encontrado esta ubicación",
    code: 203
  },
  // {
  //   ServiceTypeNotFound
  //   message: "No se ha encontrado ",
  //   code: 204
  // },
  {
    //PaymentNotFound
    message: "No se ha encontrado el pago",
    code: 205
  },
  {
    //OfferNotFound
    message: "No se ha encontrado la oferta",
    code: 206
  },
  {
    //KillerQuestionNotFound
    message: "No se ha encontrado ésta pregunta",
    code: 207
  },
  {
    //LogoNotFound
    message: "No se ha encontrado el logo",
    code: 208
  },
  {
    //CandidacyNotFound
    message: "No se ha encontrado ésta candidatura",
    code: 209
  },
  {
    //DocumentNotFound
    message: "No se ha encontrado éste documento",
    code: 210
  },
  {
    //CantDeleteAddress
    message:
      "No se puede eliminar la ubicación ya que se està utilizando en una de las ofertas de empleo",
    code: 300
  },
  {
    //UnknownError
    message:
      "Se ha producido un error. Si persiste, ponte en contacto con nosotros y te ayudaremos.",
    code: 999
  }
];

export function getError(codes?: any[]): string {
  let messages: string[] = [];

  if (codes == undefined || codes.length < 1) {
    codes = [999];
  }

  codes.forEach((apiError: any) => {
    let errorMessage = errors.some((error: Error) => {
      return error.code == apiError.code;
    })
      ? errors.find(listItem => listItem.code == apiError.code).message
      : "UnregisteredError";

    messages.push(errorMessage);
  });

  return messages.join(", ");
}
