import React, { useState, useEffect } from 'react';
import BuyPack from '../components/PublishOffer/BuyPack';
import PublishOfferComponent from '../components/PublishOffer/PublishOffer';
import { Alert } from 'reactstrap';
import { getError } from '../utils/errors';
import { config } from '../utils/Constants';

const PublishOffer: React.FC = (props: any) => {
  console.log(props.routeProps.match.params.id);
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false
  });

  const [loadingOfferData, setLoadingOfferData] = useState(true);
  const [offer, setOffer] = useState(null);

  useEffect(() => {
    if (props.routeProps.location.state) {
      setOffer(props.routeProps.location.state);
      setLoadingOfferData(false);
    } else if (props.routeProps.match.params.id) {
      fetch(`${config.apiURL}offers/companies/${props.company.oid}/offers`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        }
      })
        .then(
          (response: any) => {
            if (response.ok) {
              response.json().then((data: any) => {
                setOffer(
                  data.items.find(
                    (item: any) => item.oid == props.routeProps.match.params.id
                  )
                );
              });
            } else {
              response.json().then((data: any) => {
                if (data.errors) {
                  setAlert({
                    text: getError(data.errors),
                    color: 'danger',
                    visible: true
                  });
                } else {
                  setAlert({
                    text: getError(),
                    color: 'danger',
                    visible: true
                  });
                }
              });
            }
          },
          () => {
            setAlert({
              text: getError(),
              color: 'danger',
              visible: true
            });
          }
        )
        .then(() => setLoadingOfferData(false));
    } else {
      setLoadingOfferData(false);
      setAlert({
        text: getError(),
        color: 'danger',
        visible: true
      });
    }
  }, []);

  const postPublication = (values: any) => {
    fetch(
      `${config.apiURL}companies/${props.company.oid}/offers/${values.oid}/publications`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          publicationLength: values.publicationLength[0].split(' ')[0],
          startOn: new Date(values.startOn),
          showDetailedInfo: true
        })
      }
    ).then(
      (response: any) => {
        if (response.ok) {
          setAlert({
            text: '¡Oferta publicada!',
            color: 'success',
            visible: true
          });

          props.routeProps.history.push('/private', {
            published: true,
            oid: values.oid
          });
        } else {
          response.json().then((data: any) => {
            setAlert({
              text: getError(data.errors),
              color: 'danger',
              visible: true
            });
          });
        }
      },
      () => {
        setAlert({
          text: getError(),
          color: 'danger',
          visible: true
        });
      }
    );
  };

  return (
    <div>
      <Alert
        className='fixed-top text-center'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%'
        }}
        color={alert.color}
        isOpen={alert.visible}
      >
        {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
      </Alert>

      {(!props.company.availableWeeks || props.company.availableWeeks < 1) && (
        <div>
          <div
            className='alert alert-danger alert-dismissible fade show'
            role='alert'
          >
            <i className='fas fa-exclamation-triangle'></i> Actualmente no hay
            semanas de publicación disponibles. Consulta nuestros packs.
            <button
              type='button'
              className='close'
              data-dismiss='alert'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>

          <BuyPack {...props} />
        </div>
      )}

      <PublishOfferComponent
        {...props}
        loading={loadingOfferData}
        offer={offer}
        publish={postPublication}
      />
    </div>
  );
};

export default PublishOffer;
