import React, { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import arrayMutators from 'final-form-arrays';
import { Form, Field } from 'react-final-form';
import {
  composeValidators,
  required,
  validEmailFormat,
  validPhoneNumber,
  atLeastOne,
  validFileFormat,
  validFileSize,
} from '../utils/validations';
import { Typeahead } from 'react-bootstrap-typeahead';
import LanguagesTypeAhead from '../components/LanguagesTableTypeAhead';
import { config } from '../utils/Constants';
import { Link } from 'react-router-dom';
import { Tooltip, Alert } from 'reactstrap';
import { getError } from '../utils/errors';
import { FieldArray } from 'react-final-form-arrays';
function _onReady(event: any) {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
}

const ResumeRegistration: React.FC = () => {
  const documentRef = useRef(null);
  const [file, setFile] = useState(null);
  const [lists, setLists] = useState({
    sectorsList: [],
    subSectorsList: [],
    educationLevelList: [],
    languageList: [],
    languageLevelList: [],
  });
  const [alert, setAlert] = useState({
    text: '',
    color: '',
    visible: false,
  });
  const [success, setSuccess] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [spinnerProps, setSpinnerProps] = useState({
    loading: false,
    text: 'Cargando...',
  });
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (alert && alert.visible) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    setSpinnerProps({ loading: true, text: 'Cargando el formulario...' });
    Promise.all([
      fetch(`${config.apiURL}categories/sector`),
      fetch(`${config.apiURL}categories/subsector`),
      fetch(`${config.apiURL}categories/educationLevel`),
      fetch(`${config.apiURL}categories/language`),
      fetch(`${config.apiURL}categories/languageLevel`),
    ]).then((categories) => {
      Promise.all([
        categories[0].json(),
        categories[1].json(),
        categories[2].json(),
        categories[3].json(),
        categories[4].json(),
      ]).then((data) => {
        setLists({
          sectorsList: data[0].items,
          subSectorsList: data[1].items,
          educationLevelList: data[2].items,
          languageList: data[3].items,
          languageLevelList: data[4].items,
        });
        setSpinnerProps({ loading: false, text: '' });
      });
    });
  }, []);

  const register = (values: any) => {
    setSpinnerProps({ loading: true, text: 'Presentando candidatura...' });
    let body = new FormData();
    body.append('file', file);
    body.append(
      'data',
      JSON.stringify({
        name: values.name,
        email: values.email,
        educationLevelOid: values.educationLevel,
        SectorOid: values.sectors[0].oid,
        SubsectorOid: values.subsectors[0].oid,
        sendMarketingInformation: values.sendMarketingInformation,
        accept: values.accept,
        phone: values.phone,
        languages:
          values.languages && values.languages.length
            ? values.languages.map((item: any) => {
                return {
                  language: {
                    categoryOid: item.language.oid,
                  },

                  level: {
                    categoryOid: item.level.oid,
                  },
                };
              })
            : undefined,
      })
    );

    fetch(`${config.apiURL}candidates`, {
      method: 'POST',
      body: body,
    }).then((response: any) => {
      setSpinnerProps({ loading: false, text: '' });
      if (response.ok) {
        response.json().then((data: any) => {
          setSuccess(true);
          setAlert({
            text: '¡Se ha enviado correctamente!',
            color: 'success',
            visible: true,
          });
        });
      } else {
        setSuccess(false);
        setAlert({
          text: getError(),
          color: 'danger',
          visible: true,
        });
      }
    });
  };

  return (
    <div className='content'>
      <Alert
        className='fixed-top text-center'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
        }}
        color={alert.color}
        isOpen={alert.visible}
      >
        {alert.color == 'danger' ? alert.text : <b>{alert.text}</b>}
      </Alert>
      <div className='card-user card '>
        <div className='card-body'>
          <h2 className='card-title h5'>Registra tu CV en Worketik </h2>

          <p>
            Si quieres formar parte del primer portal de empleo pensado en las
            pesonas adjunta ahora mismo tú CV y ayudanos a mostrar a las
            empresas que hay otra manera de hacer las cosas.
          </p>
          <br />
          <div className='text-center'>
            <YouTube
              containerClassName='iframe-container'
              videoId='qnCFr3sT-cw'
              opts={{
                height: 'auto',
                width: 'auto',
                playerVars: { autoplay: 1 },
              }}
              onReady={_onReady}
            />
          </div>
          <br />
          <div className='col-sm-12 col-md-7'>
            <img
              src={require('../assets/img/sobre_worketik.png')}
              alt='Logo Worketik'
              className='mt-2 mb-5'
            />
          </div>
          <div className='col-md-12'>
            <LoadingOverlay
              active={spinnerProps.loading}
              spinner={
                <SyncLoader loading={spinnerProps.loading} color='#51cbce' />
              }
              text={spinnerProps.text}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  borderRadius: '12px',
                }),
              }}
            >
              <div className='card-user card shadow-none mb-0'>
                <Form
                  mutators={{
                    ...arrayMutators,
                  }}
                  onSubmit={register}
                  render={({
                    handleSubmit,
                    submitFailed,
                    form,
                    values,
                    submitting,
                    form: {
                      mutators: { push, pop },
                    },
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label className='mb-0'>Nombre y apellidos *</label>
                            <Field
                              name='name'
                              component='input'
                              className={
                                form.getFieldState('name') &&
                                form.getFieldState('name').error &&
                                form.getFieldState('name').touched
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              autoComplete='off'
                              type='text'
                              validate={required}
                            />
                            <div className='invalid-feedback'>
                              {form.getFieldState('name') &&
                                form.getFieldState('name').error}
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className='mb-0'>Correo electrónico *</label>
                            <Field
                              validate={composeValidators(
                                required,
                                validEmailFormat
                              )}
                              component='input'
                              className={
                                form.getFieldState('email') &&
                                form.getFieldState('email').error &&
                                form.getFieldState('email').touched
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name='email'
                              type='email'
                            />
                            <div className='invalid-feedback'>
                              {form.getFieldState('email') &&
                                form.getFieldState('email').error}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-4'>
                          <div className='form-group'>
                            <label className='mb-0'>Teléfono *</label>
                            <Field
                              validate={composeValidators(
                                required,
                                validPhoneNumber
                              )}
                              className={
                                form.getFieldState('phone') &&
                                form.getFieldState('phone').error &&
                                form.getFieldState('phone').touched
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name='phone'
                              component='input'
                              type='text'
                              autoComplete='off'
                            />
                            <div className='invalid-feedback'>
                              {form.getFieldState('phone') &&
                                form.getFieldState('phone').error}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Sector *</label>
                            <Field
                              validate={atLeastOne}
                              type='select'
                              name='sectors'
                              render={(field: any) => {
                                return (
                                  <div>
                                    <Typeahead
                                      isLoading={
                                        !lists.sectorsList ||
                                        lists.sectorsList.length == 0
                                      }
                                      emptyLabel={
                                        !lists.sectorsList ||
                                        lists.sectorsList.length == 0
                                          ? 'Cargando...'
                                          : 'No se han encontrado resultados.'
                                      }
                                      isInvalid={
                                        field.meta.error && field.meta.touched
                                      }
                                      id={field.input.name}
                                      labelKey={(option: any) => {
                                        return option.text
                                          ? option.text
                                          : undefined;
                                      }}
                                      multiple
                                      options={lists.sectorsList}
                                      placeholder='Selecciona...'
                                      onChange={(value: any) => {
                                        field.input.onChange(value);

                                        if (
                                          form.getFieldState('subsectors').value
                                        ) {
                                          let newSubsectors: any = [];
                                          value.forEach((sector: any) => {
                                            form
                                              .getFieldState('subsectors')
                                              .value.forEach(
                                                (subsector: any) => {
                                                  if (
                                                    subsector.parentOid ==
                                                    sector.oid
                                                  ) {
                                                    newSubsectors.push(
                                                      subsector
                                                    );
                                                  }
                                                }
                                              );
                                          });
                                          form.change(
                                            'subsectors',
                                            newSubsectors
                                          );
                                        }
                                      }}
                                    />
                                    {field.meta.error && field.meta.touched && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {field.meta.error}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            />
                            <div className='invalid-feedback'>
                              Seleccione una opción para continuar.
                            </div>
                          </div>

                          <div className='form-group'>
                            <label>Subsector</label>
                            <Field
                              validate={atLeastOne}
                              type='select'
                              name='subsectors'
                              render={(field: any) => {
                                return (
                                  <div>
                                    <Typeahead
                                      isLoading={
                                        !lists.subSectorsList ||
                                        lists.subSectorsList.length == 0
                                      }
                                      emptyLabel={
                                        !lists.subSectorsList ||
                                        lists.subSectorsList.length == 0
                                          ? 'Cargando...'
                                          : 'No se han encontrado resultados.'
                                      }
                                      isInvalid={
                                        field.meta.error && field.meta.touched
                                      }
                                      maxResults={lists.subSectorsList.length}
                                      paginate={false}
                                      id={field.input.name}
                                      labelKey={(option: any) => {
                                        return option.text
                                          ? option.text
                                          : undefined;
                                      }}
                                      filterBy={(option: any, props) => {
                                        var include = false;

                                        if (
                                          form.getFieldState('sectors') &&
                                          form.getFieldState('sectors').value
                                        ) {
                                          var isFromSelectedSector = form
                                            .getFieldState('sectors')
                                            .value.find((sector: any) => {
                                              return (
                                                sector.oid == option.parentOid
                                              );
                                            });

                                          var alreadySelected =
                                            field.input.value &&
                                            field.input.value.find(
                                              (subsector: any) => {
                                                return (
                                                  subsector.oid == option.oid
                                                );
                                              }
                                            );

                                          include =
                                            isFromSelectedSector &&
                                            !alreadySelected;
                                        }

                                        return (
                                          include &&
                                          option.text
                                            .toLowerCase()
                                            .indexOf(
                                              props.text.toLowerCase()
                                            ) !== -1
                                        );
                                      }}
                                      selected={
                                        form.getFieldState('subsectors') &&
                                        form.getFieldState('subsectors').value
                                          ? form.getFieldState('subsectors')
                                              .value
                                          : []
                                      }
                                      multiple
                                      options={lists.subSectorsList}
                                      placeholder='Selecciona...'
                                      onChange={(value: any) => {
                                        field.input.onChange(value);
                                      }}
                                    />
                                    {field.meta.error && field.meta.touched && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {field.meta.error}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          </div>

                          <div className='form-group'>
                            <label className='mb-0'>Nivel de estudios *</label>
                            <Field
                              className={
                                form.getFieldState('educationLevel') &&
                                form.getFieldState('educationLevel').error &&
                                form.getFieldState('educationLevel').touched
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              validate={atLeastOne}
                              name='educationLevel'
                              render={(field: any) => {
                                return (
                                  <div>
                                    <Typeahead
                                      isLoading={
                                        !lists.educationLevelList.length
                                      }
                                      emptyLabel={
                                        !lists.educationLevelList.length
                                          ? 'Cargando...'
                                          : 'No se han encontrado resultados.'
                                      }
                                      id={field.input.name}
                                      labelKey={(option: any) => {
                                        return option.text;
                                      }}
                                      options={lists.educationLevelList}
                                      placeholder='Elige un nivel de estudios...'
                                      onChange={(value: any) => {
                                        form.change(
                                          'educationLevel',
                                          value.length ? value[0].oid : null
                                        );
                                      }}
                                    />
                                    {field.meta.error && field.meta.touched && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {field.meta.error}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          </div>

                          <div className='form-group'>
                            <label>Idioma/s</label>
                            <br />
                            <a
                              data-toggle='tooltip'
                              title='Añadir'
                              className='btn btn-outline-primary btn-sm p-1 mr-1 mt-0'
                              onClick={(e) => {
                                e.preventDefault();
                                push('languages', undefined);
                              }}
                            >
                              <i className='fas fa-plus' /> Añadir idioma
                            </a>
                            <table className='table'>
                              <thead>
                                <tr>
                                  <th scope='col'>
                                    <small>Idioma</small>
                                  </th>
                                  <th scope='col'>
                                    <small>Nivel</small>
                                  </th>
                                  <th scope='col' />
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name='languages'>
                                  {({ fields }) =>
                                    fields.map((name, index) => {
                                      return (
                                        <tr key={name}>
                                          <td>
                                            <Field
                                              validate={required}
                                              name={`${name}.language`}
                                              render={(field) => {
                                                return (
                                                  <LanguagesTypeAhead
                                                    id={`${name}.language`}
                                                    field={field}
                                                    disabled={false}
                                                    index={index}
                                                    placeholder={
                                                      'Elige un idioma...'
                                                    }
                                                    list={lists.languageList}
                                                  />
                                                );
                                              }}
                                            />
                                          </td>

                                          <td>
                                            <Field
                                              validate={required}
                                              name={`${name}.level`}
                                              render={(field) => {
                                                return (
                                                  <LanguagesTypeAhead
                                                    id={`${name}.level`}
                                                    field={field}
                                                    disabled={
                                                      !values.languages[
                                                        index
                                                      ] ||
                                                      !values.languages[index]
                                                        .language
                                                    }
                                                    index={index}
                                                    placeholder={
                                                      'Elige nivel...'
                                                    }
                                                    list={
                                                      lists.languageLevelList
                                                    }
                                                  />
                                                );
                                              }}
                                            />
                                          </td>

                                          <td
                                            style={{
                                              verticalAlign: 'top',
                                            }}
                                          >
                                            <div>
                                              <a
                                                data-toggle='tooltip'
                                                title='Eliminar'
                                                className='btn btn-outline-primary btn-sm p-1 mr-1'
                                                href=''
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  e.preventDefault();

                                                  fields.remove(index);
                                                }}
                                              >
                                                <i className='far fa-trash-alt' />{' '}
                                                Eliminar
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  }
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>

                          <div className='form-group'>
                            <label className='mb-0'>
                              Documento currículum{' '}
                            </label>{' '}
                            <i
                              style={{ color: '#51cbce' }}
                              className='fas fa-info-circle'
                              id='fileTooltip'
                            />
                            <Tooltip
                              placement='right'
                              isOpen={tooltipOpen}
                              target='fileTooltip'
                              toggle={toggle}
                              style={{ backgroundColor: '#51cbce' }}
                              hideArrow={true}
                            >
                              Añade un documento word o pdf que no supere los
                              4MB.
                            </Tooltip>
                            <Field
                              name='file'
                              validate={composeValidators(
                                required,
                                validFileFormat,
                                validFileSize
                              )}
                              render={(field) => {
                                return (
                                  <div>
                                    <div className='custom-file'>
                                      <input
                                        title='BUSCAR'
                                        ref={documentRef}
                                        type='file'
                                        className='custom-file-input'
                                        id='customFile'
                                        onChange={(
                                          event: React.ChangeEvent<
                                            HTMLInputElement
                                          >
                                        ) => {
                                          setFile(event.target.files[0]);
                                          field.input.onChange(
                                            event.target.files[0]
                                          );
                                        }}
                                      />
                                      <label className='custom-file-label'>
                                        {file ? file.name : 'Examinar...'}
                                      </label>
                                      <label
                                        htmlFor={'customFile'}
                                        className=''
                                      >
                                        <span>{'Buscar'}</span>
                                      </label>
                                      {file && (
                                        <small
                                          id='delete'
                                          className='form-text text-muted'
                                        >
                                          <a
                                            onClick={(e) => {
                                              e.preventDefault();

                                              documentRef.current.value = null;
                                              setFile(null);
                                              form.change('file', null);
                                            }}
                                          >
                                            Eliminar
                                          </a>
                                        </small>
                                      )}
                                    </div>

                                    {field.meta.error && field.meta.touched && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {field.meta.error}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            ></Field>
                          </div>
                          <p className='text-muted'>Política de privacidad</p>
                          <p className='text-muted mt-4'>
                            {' '}
                            WORKETIK PORTAL,S.L como responsable del tratamiento
                            tratará tus datos con la finalidad de dar respuesta
                            a petición de inscripción a una oferta de trabajo.
                            Puedes acceder, rectificar y suprimir tus datos, así
                            como ejercer otros derechos consultando la
                            información adicional y detallada sobre protección
                            de datos en nuestra{' '}
                            <Link to='/privacy-policy' target='_blank'>
                              política de privacidad
                            </Link>{' '}
                          </p>
                          <div className='form-group mb-1'>
                            <div className='form-check mb-3'>
                              <label className='form-check-label'>
                                <Field
                                  validate={(value) =>
                                    value
                                      ? undefined
                                      : 'Debes aceptar las condiciones para continuar.'
                                  }
                                  name='accept'
                                  component='input'
                                  className='form-check-input'
                                  type='checkbox'
                                />
                                <span className='form-check-sign'></span>
                                <span className='text-muted'>
                                  He leído y acepto las condiciones contenidas
                                  en la{' '}
                                  <Link to='/privacy-policy' target='_blank'>
                                    política de privacidad
                                  </Link>{' '}
                                  sobre el tratamiento de mis datos para
                                  gestionar mi petición de inscripción en la
                                  oferta de trabajo.
                                  {values.accept}
                                  {form.getFieldState('accept') &&
                                    form.getFieldState('accept').error &&
                                    form.getFieldState('accept')
                                      .submitFailed && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {form.getFieldState('accept').error}
                                      </div>
                                    )}
                                </span>
                              </label>
                            </div>
                          </div>
                          <p className='text-muted'>
                            {' '}
                            Nos gustaría que nos prestaras tu consentimiento
                            para:
                          </p>
                          <div className='form-group mb-3'>
                            <div className='form-check'>
                              <label className='form-check-label'>
                                <Field
                                  className='custom-control-input'
                                  name='sendMarketingInformation'
                                  component='input'
                                  type='checkbox'
                                />{' '}
                                <span className='form-check-sign'></span>
                                <span className='text-muted'>
                                  Enviarte información comercial sobre los
                                  productos, servicios, novedades de WORKETIK
                                  PORTAL,S.L
                                </span>
                                {values.sendMarketingInformation}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mt-4'>
                        <button
                          type='submit'
                          className='btn-round btn btn-primary pull-right'
                          disabled={submitting}
                        >
                          Enviar
                        </button>
                      </div>
                    </form>
                  )}
                />
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResumeRegistration;
