import React from "react";
import { Link } from "react-router-dom";
const SocialMediaPolicy: React.FC = () => (
      <div className="card-user card ">
          <div className=" card-header">
              <h5 className="card-title " id="registro">Política de redes sociales</h5>
          </div>
          <div className="card-body">
          <p className="costats font-weight-bold">1. Información sobre el responsable del tratamiento de los datos alojados en la página oficial de WORKETIK PORTAL,S.L, en la red social</p>
          <p className="costats">De conformidad al deber de información establecido en el Reglamento (UE) 2016/679 de 27 de abril (GDPR) y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD) WORKETIK PORTAL,S.L, en adelante el Responsable desea poner en conocimiento de los usuarios de esta red social la política respecto del tratamiento y protección de los datos de carácter personal de aquellas personas que voluntariamente acceden y utilizan la página oficial del RESPONSABLE, en la misma. El acceso y uso por el usuario de esta página oficial del RESPONSABLE, supone que acepta expresamente esta 
          <Link to="/privacy-policy" target="_blank">
                                        {" "}
                                        política de privacidad
                                    </Link>{" "} y que presta su consentimiento para el tratamiento de sus datos personales según lo previsto en la misma.</p>
          <p className="costats font-weight-bold">2. Datos de menores de edad o incapaces</p>
          <p className="costats">El acceso y registro en esta red social está prohibido a los menores de catorce (14) años, de forma que igualmente queda prohibido el acceso y uso de los menores de catorce (14 años) a la página oficial del RESPONSABLE, en la misma. Por su parte, si el usuario es incapaz, el RESPONSABLE, advierte que será necesaria la asistencia consentida del titular de la patria potestad o tutela del usuario o su representante legal para el acceso y uso de esta página oficial del RESPONSABLE,. este quedará expresamente exonerada de cualquier responsabilidad que pudiera derivarse del uso de su página oficial por menores e incapaces siendo dicha responsabilidad de sus representantes legales en cada caso.</p>
          <p className="costats font-weight-bold">3. Identificación de la razón social del responsable del fichero</p>
          <p className="costats">El usuario queda informado de que el RESPONSABLE, con domicilio social en Av. Països Catalans, 41 - 08700 Igualada (Barcelona), ostenta la condición de responsable de los ficheros y tratamientos a través de los que se recogen y almacenan los datos personales del usuario como consecuencia del registro y uso de la página oficial del RESPONSABLE, en esta red social, sin perjuicio de los tratamientos de los que es responsable la entidad titular de la red social en la que se encuentra nuestra página oficial.</p>
          <p className="costats font-weight-bold">4. Finalidades a que se destinan los datos de carácter personal, información y consentimiento</p>
          <p className="costats">Los datos personales proporcionados voluntariamente por el usuario al RESPONSABLE, a través de su página oficial formarán parte de un tratamiento de datos responsabilidad del RESPONSABLE, con la finalidad de ofrecerle información del RESPONSABLE, por e-mail, fax, SMS, MMS, comunidades sociales o cualesquier otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicación comerciales, envío de newsletter a aquellos usuarios que se suscriban, intercambiar información con el RESPONSABLE, y otros usuarios y establecer comunicación con terceros.</p>
          <p className="costats">Desde el momento en que el usuario utiliza la presente página oficial presta su consentimiento libre, inequívoco, específico, informado y expreso para el tratamiento de sus datos personales por el RESPONSABLE, para la correcta prestación de dichos servicios de acuerdo con lo establecido en la presente política.</p>
          <p className="costats">El usuario es libre de utilizar la página oficial del RESPONSABLE, en esta red social, por lo que en caso en que el usuario no esté de acuerdo con el tratamiento de sus datos con dichos fines, no deberá utilizarla ni proporcionar sus datos personales.</p>
          <p className="costats">A través de esta página oficial del RESPONSABLE, el usuario puede compartir textos, fotos, vídeos y otro tipo de información y/o contenidos que estarán sujetos tanto a la presente política como a las Normas y Condiciones de la Plataforma. El usuario será responsable de que todos los contenidos que publique respeten la legislación vigente, la presente política y las Normas de la Plataforma.</p>
          <p className="costats">El usuario únicamente podrá publicar en esta página oficial del RESPONSABLE, datos personales, fotografías e informaciones u otros contenidos cuya titularidad y propiedad le pertenezcan o respecto de las cuales ostente la autorización de terceros.</p>
          <p className="costats">El RESPONSABLE, tendrá derecho a eliminar de la presente página oficial –de forma unilateral y sin previa comunicación ni autorización del usuario- cualesquiera contenidos publicados por el usuario cuando el usuario infrinja o vulnere la legislación vigente, las normas establecidas en la presente política y las Normas de la Plataforma.</p>
          <p className="costats font-weight-bold">5. Identificación de los destinatarios respecto de los que el RESPONSABLE, tenga previsto la realización de cesiones o comunicaciones de datos</p>
          <p className="costats">Se advierte al usuario que toda la información y contenidos que publique en la página oficial del RESPONSABLE, en la red social podrá ser conocida por los restantes usuarios de la página oficial y de la plataforma de red social. En consecuencia, toda la información y contenidos publicados por el usuario en la página oficial del RESPONSABLE, en esta red social será objeto de comunicación de al resto de los usuarios por la propia naturaleza del servicio.</p>
          <p className="costats">El RESPONSABLE únicamente tiene prevista la realización de cesiones o comunicaciones de datos que en razón de la normativa vigente deba realizar a jueces, tribunales, administraciones públicas y autoridades competentes de carácter administrativo.</p>
          <p className="costats font-weight-bold">6. Otros terceros prestadores de servicios</p>
          <p className="costats">El RESPONSABLE, advierte al usuario de que esta entidad sólo es responsable y garantiza la confidencialidad, seguridad y tratamiento de los datos conforme a la presente política, respecto de los datos de carácter personal que recoja del usuario a través de la presente página oficial en esta red social, no teniendo ningún tipo de responsabilidad respecto de los tratamientos y posteriores utilizaciones de los datos personales que pudieran efectuarse tanto por el titular de la red social como por terceros prestadores de servicios de la sociedad de la información que pudiesen acceder a tales datos en razón de la prestación de sus servicios o ejercicio de su actividad, terceros que establezcan hiperenlaces a la red social, ni de aquellos responsables a quienes a través de hiperenlaces el RESPONSABLE, remite a los usuarios adheridos a la presente página oficial.</p>
          <p className="costats font-weight-bold">7. Calidad de los datos</p>
          <p className="costats">El RESPONSABLE, advierte al usuario de que, salvo la existencia de una representación legalmente constituida, ningún usuario puede utilizar la identidad de otra persona y comunicar sus datos personales, por lo que el usuario en todo momento deberá tener en cuenta que solo puede proporcionar datos personales correspondientes a su propia identidad y que sean adecuados, pertinentes, actuales, exactos y verdaderos. En cualquier caso, el usuario deberá respetar la privacidad de terceros, sean éstos usuarios o no de la red social o de la página oficial del RESPONSABLE, en la misma.</p>
          <p className="costats font-weight-bold">8. Derechos que asisten al Interesado:</p>
          <ul className="costats">
            <li>Derecho a retirar el consentimiento en cualquier momento.</li>
            <li>Derecho de acceso, rectificación, portabilidad y supresión de sus datos y a la limitación u oposición a su tratamiento.</li>
            <li>Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es.) si considera que el tratamiento no se ajusta a la normativa vigente.</li>
          </ul>
          <p className="costats font-weight-bold">9. Otra información de interés</p>
          <p className="costats">El RESPONSABLE, podrá modificar y/o sustituir en cualquier momento la presente política que, según los casos, sustituirá, completará y/o modificará la actualmente publicada aquí recogida. Por ello el usuario deberá acceder periódicamente a las mismas con el fin de mantenerse actualizado.</p>
          <p className="costats">El usuario puede contactar con el RESPONSABLE, mediante el servicio de mensajería interna de la red social o a través de las siguientes direcciones y datos de contacto:</p>

  
              <p className="costats">
  <b>WORKETIK PORTAL,S.L</b>
  <br/>Av. Països Catalans, 41 - 08700 Igualada (Barcelona)
  <br/>datos@worketik.com
  </p>
  <p className="costats">Asimismo el usuario puede consultar las normas y políticas publicadas por el titular de la red social para el uso de la misma.</p>
          </div>
      </div>
  
  );
  
  export default SocialMediaPolicy; 
  