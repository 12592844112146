import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const WrappedTypeahead: React.FC<any> = (props: any) => {
  const getList = async (setter: any) => {
    if (
      props.form.getFieldState("professions").value &&
      props.form.getFieldState("professions").value.length > 0
    ) {
      const result = await fetch(
        props.url +
          props.form
            .getFieldState("professions")
            .value.map((item: any) => item.oid)
            .join(",")
      );

      if (result.ok) {
        var response = await result.json();
        setter(response.items);
      }
    } else {
      setter([]);
    }
  };

  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getList(setList).then(() => setLoading(false));
  }, [props.values.professions]);

  return (
    <Typeahead
      isInvalid={props.field.meta.error && props.field.meta.touched}
      id={props.field.input.name}
      labelKey={(option: any) => {
        return option.text ? option.text : undefined;
      }}
      emptyLabel={!list ? "Cargando..." : "No se han encontrado resultados."}
      isLoading={loading}
      multiple
      options={list || []}
      placeholder={props.placeholder}
      onChange={(value: any) => {
        props.field.input.onChange(value);
      }}
      disabled={props.disabled}
      selected={
        props.field.input && props.field.input.value
          ? props.field.input.value
          : props.initialValues
      }
    />
  );
};
export default WrappedTypeahead;
