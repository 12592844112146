import React from "react";

const Education: React.FC = (props: any) => (

    <div className="card-user card shadow-none ">
        <div className=" card-header pt-0">
            <h5 className="card-title mb-0 " id="registro"><i className="nc-icon nc-hat-3"></i> Requisitos candidato/a</h5>
        </div>
        <div className="card-body pb-0">
            <p className="mb-2">Nivel de estudios requerido: <span className="font-weight-bold"> {props.educationLevel ? props.educationLevel.text : null}</span></p>
            {props.educationTitles ?
                <p className="mb-2">Titulación/es: <span className="font-weight-bold"> {props.educationTitles.map((educationTitle: any) => {
                    return educationTitle.text
                }).join(", ")}</span></p>
                : null}
            {props.averageGrade ? <p className="mb-2">Nota media superior a: <span className="font-weight-bold"> {props.averageGrade}</span></p> : null}
            {props.professionalCards ?
                <p className="mb-2">Otro/s título/s: <span className="font-weight-bold"> {props.professionalCards.map((professionalCard: any) => {
                    return professionalCard.text
                }).join(", ")}</span></p>
                : null}
            <p className="mb-2">Idiomas: <span className="font-weight-bold"> {props.languages && props.languages.map((language: any) => {
                return language.language.text + " (" + language.level.text + ")"
            }).join(", ")}</span></p>
            <p className="mb-2">Experiencia mínima: <span className="font-weight-bold"> {props.experience ? props.experience.text : null}</span></p>
            {props.hobbies ?
                <p className="mb-2">Pasiones y/o aficiones: <span className="font-weight-bold"> {props.hobbies.map((hobby: any) => {
                    return hobby.text
                }).join(", ")}</span></p>
                : null}
            {props.countries !== null ?
                <p className="mb-2">Preferiblemente de: <span className="font-weight-bold"> {props.countries ? props.countries.map((country: any) => {
                    return country.text
                }).join(", ") : null} {props.provinces !== null ? " - " : null} {props.provinces ? props.provinces.map((province: any) => {
                    return province.text
                }).join(", ") : null} {props.municipalities !== null ? " - " : null} {props.municipalities ? props.municipalities.map((municipality: any) => {
                    return municipality.text
                }).join(", ") : null}</span></p> : null}
        </div>
    </div>
);

export default Education;
