import React, { useEffect, useState, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Route, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import arrayMutators from 'final-form-arrays';
import { getCategories } from '../utils/GetCategories';
import SocialBenefitsComponent from '../components/SocialBenefits';
import queryString from 'query-string';
import { getError } from '../utils/errors';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import {
  required,
  atLeastOne,
  validEmailFormat,
  validPhoneNumber,
  composeValidators,
  matchingPasswords,
  minSixChars,
  validCIFFormat,
  CIFAvailable
} from '../utils/validations';
import { config } from '../utils/Constants';

const Login: React.FC = (loginProps: any) => {
  const values = queryString.parse(loginProps.routeProps.location.search);
  const [socialBenefitsData, setSocialBenefitsData] = useState([]);
  const [registeredSuccessfully, setRegisteredSuccessfully] = useState(false);
  const [loginLoadingSR, setLoginLoadingSR] = useState(true);
  const provincesRef = useRef(null);
  const postalCodeRef = useRef(null);
  const municipalitiesRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(null);
  useEffect(() => {
    if (alert && alert.visible && !alert.dismiss) {
      window.setTimeout(() => {
        setAlert({ visible: false, color: '', text: '' });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (values.access_token) {
      const activateUser = async () => {
        await fetch(
          `${config.apiURL}activateaccount?access_token=${values.access_token}`,
          {
            method: 'POST'
          }
        ).then(
          (response: any) => {
            if (response.ok) {
              setAlert({
                text: '¡Tu cuenta se ha activado!',
                color: 'success',
                visible: true
              });
            } else {
              return response.json().then(
                (data: any) => {
                  setAlert({
                    text: getError(data.errors),
                    color: 'danger',
                    visible: true
                  });
                },
                () => {
                  setAlert({
                    text: getError(),
                    color: 'danger',
                    visible: true
                  });
                }
              );
            }
          },
          () => {
            setAlert({
              text: getError(),
              color: 'danger',
              visible: true
            });
          }
        );
      };
      activateUser();
    }
  }, []);

  const [lists, setLists] = useState({
    sectorsList: [],
    subSectorsList: [],
    countriesList: [],
    SRGroupList: [],
    SRList: [],
    provinceList: [],
    municipalityList: [],
    postalCodeList: []
  });

  useEffect(() => {
    Promise.all([
      fetch(`${config.apiURL}categories/sector`),
      fetch(`${config.apiURL}categories/subsector`),
      fetch(`${config.apiURL}categories/country`),
      fetch(`${config.apiURL}categories/SocialResponsibilityGroup`),
      fetch(`${config.apiURL}categories/SocialResponsibility`),

      fetch(`${config.apiURL}categories/province`),
      fetch(`${config.apiURL}categories/municipality`),
      fetch(`${config.apiURL}categories/postalcode`)
    ]).then(categories => {
      Promise.all([
        categories[0].json(),
        categories[1].json(),
        categories[2].json(),
        categories[3].json(),
        categories[4].json(),
        categories[5].json(),
        categories[6].json(),
        categories[7].json()
      ]).then(data => {
        setLists({
          sectorsList: data[0].items,
          subSectorsList: data[1].items,
          countriesList: data[2].items,
          SRGroupList: data[3].items,
          SRList: data[4].items,
          provinceList: data[5].items,
          municipalityList: data[6].items,
          postalCodeList: data[7].items
        });
      });
    });
  }, []);

  const postLogin = (values: any) => {
    loginProps.setMainSpinner(true);
    fetch(`${config.apiURL}companylogin`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        username: values.email,
        password: values.password
      })
    })
      .then(
        (response: any) => {
          if (response.ok) {
            response.json().then((data: any) => {
              sessionStorage.setItem('companyToken', data.item.token);
              loginProps.UpdateAuthentication();
            });
          } else {
            response.json().then(
              (data: any) => {
                setAlert({
                  text: getError(data.errors),
                  color: 'danger',
                  visible: true
                });
              },
              () => {
                setAlert({
                  text: getError(),
                  color: 'danger',
                  visible: true
                });
              }
            );
          }
        },
        () => {
          setAlert({
            text: getError(),
            color: 'danger',
            visible: true
          });
        }
      )
      .then(() => loginProps.setMainSpinner(false));
  };

  const postRegister = (values: any) => {
    const sectorsArray: any[] = values.sectors.map((item: any) => {
      return { categoryOid: item.oid };
    });

    const subsectorsArray: any[] = values.subsectors.map((item: any) => {
      return { categoryOid: item.oid };
    });

    const SRArray: any[] = socialBenefitsData.map((item: any) => {
      return { categoryOid: item.oid };
    });

    fetch(`${config.apiURL}companies`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        company: {
          name: values.name,
          id: values.id,
          sectors: sectorsArray,
          subsectors: subsectorsArray,
          sendMarketingInformation: values.sendMarketingInformation,
          socialResponsibilities: SRArray
        },
        address: {
          phoneNumber1: values.phoneNumber1,
          countryId: values.countryId,

          municipalityId:
            values.countryId === '00000000-0000-0000-0000-0000000000a8'
              ? values.municipalityId
              : null,
          postalCodeId:
            values.countryId === '00000000-0000-0000-0000-0000000000a8'
              ? values.postalCodeId
              : null,

          province:
            values.countryId === '00000000-0000-0000-0000-0000000000a8'
              ? null
              : values.province,
          municipality:
            values.countryId === '00000000-0000-0000-0000-0000000000a8'
              ? null
              : values.municipality,
          postalCode:
            values.countryId === '00000000-0000-0000-0000-0000000000a8'
              ? null
              : values.postalCode,

          postalAddress: values.postalAddress
        },
        user: {
          name: values.contactName,
          position: values.position,
          email: values.email,
          phone: values.phone,
          password: values.password,
          confirmPassword: values.confirmPassword
        }
      })
    }).then((response: any) => {
      if (response.ok) {
        setRegisteredSuccessfully(true);
        setAlert({
          text:
            'La infromación ha sido enviada con éxito, pronto recibirás un correo electrónico para activar tu cuenta.',
          color: 'success',
          visible: true
        });
      } else {
        return response.json().then((data: any) => {
          setAlert({
            text: getError(data.errors),
            color: 'danger',
            visible: true
          });
        });
      }
    });
  };

  const postRecoverPassword = (values: any) => {
    fetch(`${config.apiURL}recoverpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userName: values.userName
      })
    });
  };

  return (
    <Route
      render={(props: any) => {
        return (
          <div>
            {alert && alert.color === 'danger' && (
              <Alert
                toggle={() => {
                  setAlert({ visible: false, color: '', text: '' });
                }}
                className='fixed-top text-center'
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%'
                }}
                color={alert.color}
                isOpen={alert.visible}
              >
                {alert.text}
              </Alert>
            )}
            {alert && alert.color !== 'danger' && (
              <Alert
                className='fixed-top text-center'
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%'
                }}
                color={alert.color}
                isOpen={alert.visible}
              >
                <b> {alert.text}</b>
              </Alert>
            )}

            <div className='card-user card p-3' id='login'>
              <div className='card-body'>
                <Form
                  onSubmit={postLogin}
                  render={({ handleSubmit, form, submitting, values }) => (
                    <div className='row'>
                      <div className='pr-1 col-md-6 text-center'>
                        <img
                          src={require('../assets/img/login.png')}
                          alt='Logo Worketik'
                          className='d-block login_img mx-auto'
                        />
                        <h2 className='h5 mb-4'>
                          Crea tu cuenta y muestra al mundo todo lo que tu
                          organización tiene por ofrecer
                        </h2>
                      </div>
                      <div className='col-md-6 px-5 pt-2'>
                        <h5 className='my-2'>Acceso para organizaciones</h5>
                        <form onSubmit={handleSubmit}>
                          <div className='form-group'>
                            <label className='mb-0'>Correo electrónico</label>
                            <Field
                              className='form-control'
                              name='email'
                              component='input'
                              type='email'
                            />
                            <div className='invalid-feedback'>
                              Rellene este campo para continuar.
                              <br />
                              Escriba una dirección de correo electrónico válida
                              con una @ y un punto.
                              <br />
                              No hemos podido encontrar ninguna cuenta con este
                              correo electrónico. ¿Quiere{' '}
                              <a href='#registro' title='Registro'>
                                registrarse?{' '}
                              </a>
                            </div>
                          </div>
                          <div className='form-group mb-2'>
                            <label className='mb-0'>Contraseña</label>
                            <Field
                              className='form-control'
                              name='password'
                              component='input'
                              type='password'
                            />
                            <div className='invalid-feedback'>
                              Rellene este campo para continuar.
                              <br />
                              Escriba una contraseña válida de mínimo 6
                              caracteres. <br />
                              La contraseña no es correcta. Recuerde que puede{' '}
                              <Link to='/new-password'>
                                recuperar la contraseña.
                              </Link>
                            </div>
                          </div>
                          {!props.authenticated && (
                            <div>
                              <button
                                type='submit'
                                className='btn-round btn btn-primary'
                                disabled={submitting}
                              >
                                Acceder
                              </button>
                            </div>
                          )}
                          {/* <div><Link to="/new-password" > ¿Olvidaste tu contraseña?</Link></div> */}
                          <div>
                            <a
                              href=''
                              onClick={(e: any) => {
                                e.preventDefault();
                                setModal(true);
                              }}
                            >
                              ¿Olvidaste tu contraseña?
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            <Form
              onSubmit={postRecoverPassword}
              render={({
                handleSubmit,
                form,
                values,
                submitting,
                pristine
              }) => (
                <form>
                  <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                    <ModalHeader toggle={() => setModal(!modal)}>
                      ¿Olvidaste tu contraseña?
                    </ModalHeader>
                    <ModalBody>
                      <div className='card-body'>
                        <p>
                          Introduce el correo electrónico que utilizas para
                          iniciar sesión en Worketik y te mandaremos un enlace
                          para que puedas establecer una nueva contraseña
                        </p>
                        <form action=''>
                          <div className='form-group row'>
                            <div className='col-lg-6'>
                              <label className='mb-0'>Correo electrónico</label>
                              <Field
                                name='userName'
                                component='input'
                                type='username'
                                data-minlength='6'
                                className='form-control'
                              />
                              <div className='invalid-feedback'>
                                Rellene este campo para continuar. <br />
                                Escriba una dirección de correo electrónico
                                válida con una @ y un punto. <br />
                                No hemos podido encontrar ninguna cuenta con
                                este correo electrónico.
                              </div>
                            </div>
                          </div>
                          <button
                            disabled={submitting || pristine}
                            className='btn btn-primary'
                            type='submit'
                            onClick={(e: any) => {
                              e.preventDefault();
                              handleSubmit();
                              setModal(!modal);
                            }}
                          >
                            Enviar enlace
                          </button>
                          <button
                            className='btn btn-outline-primary'
                            onClick={(e: any) => {
                              e.preventDefault();
                              setModal(!modal);
                            }}
                          >
                            Cancelar
                          </button>
                        </form>
                      </div>
                    </ModalBody>
                  </Modal>
                </form>
              )}
            />

            <Form
              onSubmit={postRegister}
              mutators={{
                ...arrayMutators
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                values,
                hasValidationErrors
              }) => (
                <LoadingOverlay
                  active={submitting}
                  spinner={<SyncLoader loading={true} color='#51cbce' />}
                  text='Conectando...'
                  styles={{
                    overlay: (base: any) => ({
                      ...base,
                      borderRadius: '12px'
                    })
                  }}
                >
                  <div className='card-user card p-3 mt-5'>
                    <div className=' card-header'>
                      <h5 className='card-title mb-3' id='registro'>
                        Registra tu organización
                      </h5>
                    </div>
                    <div className='card-body'>
                      <form onSubmit={handleSubmit}>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <h6 className='card-title'>
                              Datos de la organización
                            </h6>

                            <div className='row'>
                              <div className='col-lg-6'>
                                <div className='form-group'>
                                  <label className='mb-0'>
                                    Nombre de la organización *
                                  </label>
                                  <Field
                                    validate={required}
                                    className={
                                      form.getFieldState('name') &&
                                      form.getFieldState('name').error &&
                                      form.getFieldState('name').submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='name'
                                    component='input'
                                    type='text'
                                    autoComplete='off'
                                  />

                                  <div className='invalid-feedback'>
                                    {form.getFieldState('name') &&
                                      form.getFieldState('name').error}
                                  </div>
                                </div>
                                <div className='form-group'>
                                  <label>Sector *</label>
                                  <Field
                                    validate={atLeastOne}
                                    type='select'
                                    name='sectors'
                                    render={(field: any) => {
                                      return (
                                        <div>
                                          <Typeahead
                                            isLoading={
                                              !lists.sectorsList ||
                                              lists.sectorsList.length == 0
                                            }
                                            emptyLabel={
                                              !lists.sectorsList ||
                                              lists.sectorsList.length == 0
                                                ? 'Cargando...'
                                                : 'No se han encontrado resultados.'
                                            }
                                            isInvalid={
                                              field.meta.error &&
                                              field.meta.touched
                                            }
                                            id={field.input.name}
                                            labelKey={(option: any) => {
                                              return option.text
                                                ? option.text
                                                : undefined;
                                            }}
                                            multiple
                                            options={lists.sectorsList}
                                            placeholder='Selecciona...'
                                            onChange={(value: any) => {
                                              field.input.onChange(value);
                                              console.log(
                                                form.getFieldState('subsectors')
                                                  .value
                                              );
                                              if (
                                                form.getFieldState('subsectors')
                                                  .value
                                              ) {
                                                let newSubsectors: any = [];
                                                value.forEach((sector: any) => {
                                                  form
                                                    .getFieldState('subsectors')
                                                    .value.forEach(
                                                      (subsector: any) => {
                                                        if (
                                                          subsector.parentOid ==
                                                          sector.oid
                                                        ) {
                                                          newSubsectors.push(
                                                            subsector
                                                          );
                                                        }
                                                      }
                                                    );
                                                });
                                                form.change(
                                                  'subsectors',
                                                  newSubsectors
                                                );
                                              }
                                            }}
                                          />
                                          {field.meta.error &&
                                            field.meta.touched && (
                                              <div
                                                className='invalid-feedback'
                                                style={{ display: 'block' }}
                                              >
                                                {field.meta.error}
                                              </div>
                                            )}
                                        </div>
                                      );
                                    }}
                                  />
                                  <div className='invalid-feedback'>
                                    Seleccione una opción para continuar.
                                  </div>
                                </div>

                                <div className='form-group'>
                                  <label>Subsector *</label>
                                  <Field
                                    validate={atLeastOne}
                                    type='select'
                                    name='subsectors'
                                    render={(field: any) => {
                                      return (
                                        <div>
                                          <Typeahead
                                            isLoading={
                                              !lists.subSectorsList ||
                                              lists.subSectorsList.length == 0
                                            }
                                            emptyLabel={
                                              !lists.subSectorsList ||
                                              lists.subSectorsList.length == 0
                                                ? 'Cargando...'
                                                : 'No se han encontrado resultados.'
                                            }
                                            isInvalid={
                                              field.meta.error &&
                                              field.meta.touched
                                            }
                                            maxResults={
                                              lists.subSectorsList.length
                                            }
                                            paginate={false}
                                            id={field.input.name}
                                            labelKey={(option: any) => {
                                              return option.text
                                                ? option.text
                                                : undefined;
                                            }}
                                            filterBy={(option: any, props) => {
                                              var include = false;

                                              if (
                                                form.getFieldState('sectors') &&
                                                form.getFieldState('sectors')
                                                  .value
                                              ) {
                                                var isFromSelectedSector = form
                                                  .getFieldState('sectors')
                                                  .value.find((sector: any) => {
                                                    return (
                                                      sector.oid ==
                                                      option.parentOid
                                                    );
                                                  });

                                                var alreadySelected =
                                                  field.input.value &&
                                                  field.input.value.find(
                                                    (subsector: any) => {
                                                      return (
                                                        subsector.oid ==
                                                        option.oid
                                                      );
                                                    }
                                                  );

                                                include =
                                                  isFromSelectedSector &&
                                                  !alreadySelected;
                                              }

                                              return (
                                                include &&
                                                option.text
                                                  .toLowerCase()
                                                  .indexOf(
                                                    props.text.toLowerCase()
                                                  ) !== -1
                                              );
                                            }}
                                            selected={
                                              form.getFieldState(
                                                'subsectors'
                                              ) &&
                                              form.getFieldState('subsectors')
                                                .value
                                                ? form.getFieldState(
                                                    'subsectors'
                                                  ).value
                                                : []
                                            }
                                            multiple
                                            options={lists.subSectorsList}
                                            placeholder='Selecciona...'
                                            onChange={(value: any) => {
                                              field.input.onChange(value);
                                            }}
                                          />
                                          {field.meta.error &&
                                            field.meta.touched && (
                                              <div
                                                className='invalid-feedback'
                                                style={{ display: 'block' }}
                                              >
                                                {field.meta.error}
                                              </div>
                                            )}
                                        </div>
                                      );
                                    }}
                                  />
                                </div>

                                <div className='row'>
                                  <div className='col-lg-6'>
                                    <div className='form-group'>
                                      <label className='mb-0'>
                                        CIF / NIF *
                                      </label>
                                      <Field
                                        validate={composeValidators(
                                          required,
                                          validCIFFormat,
                                          CIFAvailable
                                        )}
                                        className={
                                          form.getFieldState('id') &&
                                          form.getFieldState('id').error &&
                                          form.getFieldState('id').submitFailed
                                            ? 'form-control is-invalid'
                                            : 'form-control'
                                        }
                                        name='id'
                                        component='input'
                                        type='text'
                                        autoComplete='off'
                                      />
                                      <div className='invalid-feedback'>
                                        {form.getFieldState('id') &&
                                          form.getFieldState('id').error}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-lg-6'>
                                    <div className='form-group'>
                                      <label className='mb-0'>
                                        Teléfono de la organización *
                                      </label>
                                      <Field
                                        validate={composeValidators(
                                          required,
                                          validPhoneNumber
                                        )}
                                        className={
                                          form.getFieldState('phoneNumber1') &&
                                          form.getFieldState('phoneNumber1')
                                            .error &&
                                          form.getFieldState('phoneNumber1')
                                            .submitFailed
                                            ? 'form-control is-invalid'
                                            : 'form-control'
                                        }
                                        name='phoneNumber1'
                                        component='input'
                                        type='text'
                                        autoComplete='off'
                                      />
                                      <div className='invalid-feedback'>
                                        {form.getFieldState('phoneNumber1') &&
                                          form.getFieldState('phoneNumber1')
                                            .error}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='col-lg-6'>
                                <div className='form-group'>
                                  <label className='mb-0'>
                                    Dirección postal *
                                  </label>
                                  <Field
                                    validate={required}
                                    className={
                                      form.getFieldState('postalAddress') &&
                                      form.getFieldState('postalAddress')
                                        .error &&
                                      form.getFieldState('postalAddress')
                                        .submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='postalAddress'
                                    component='input'
                                    type='text'
                                    autoComplete='off'
                                  />
                                  <div className='invalid-feedback'>
                                    {form.getFieldState('postalAddress') &&
                                      form.getFieldState('postalAddress').error}
                                  </div>
                                </div>

                                <div className='form-group'>
                                  <label className='mb-0'>País *</label>
                                  <Field
                                    validate={required}
                                    name='countryId'
                                    render={(field: any) => {
                                      return (
                                        <div>
                                          <Typeahead
                                            isLoading={
                                              !lists.countriesList.length
                                            }
                                            emptyLabel={
                                              !lists.countriesList.length
                                                ? 'Cargando...'
                                                : 'No se han encontrado resultados.'
                                            }
                                            inputProps={{
                                              className:
                                                field.meta.error &&
                                                field.meta.submitFailed
                                                  ? 'form-control is-invalid'
                                                  : 'form-control'
                                            }}
                                            maxResults={
                                              lists.countriesList.length
                                            }
                                            paginate={false}
                                            id={field.input.name}
                                            labelKey={(option: any) => {
                                              return option.text;
                                            }}
                                            options={lists.countriesList}
                                            placeholder='Selecciona...'
                                            onChange={(value: any) => {
                                              if (value.length > 0) {
                                                form.change(
                                                  'countryId',
                                                  value[0].oid
                                                );
                                              } else {
                                                form.change('countryId', null);
                                              }

                                              form.change('province', null);
                                              form.change('municipality', null);
                                              form.change('postalCode', null);
                                              if (provincesRef.current) {
                                                provincesRef.current.clear();
                                              }
                                              if (municipalitiesRef.current) {
                                                municipalitiesRef.current.clear();
                                              }
                                              if (postalCodeRef.current) {
                                                postalCodeRef.current.clear();
                                              }
                                            }}
                                            filterBy={(option, props) => {
                                              if (props.selected.length) {
                                                // Display all the options if there's a selection.
                                                return true;
                                              }
                                              // Otherwise filter on some criteria.
                                              return (
                                                option.text
                                                  .toLowerCase()
                                                  .indexOf(
                                                    props.text.toLowerCase()
                                                  ) !== -1
                                              );
                                            }}
                                          />
                                          {field.meta.error &&
                                            field.meta.touched && (
                                              <div
                                                className='invalid-feedback'
                                                style={{ display: 'block' }}
                                              >
                                                {field.meta.error}
                                              </div>
                                            )}
                                        </div>
                                      );
                                    }}
                                  />
                                </div>

                                <div className='form-group'>
                                  <label className='mb-0'>Provincia *</label>

                                  {(values.countryId == null ||
                                    values.countryId ==
                                      '00000000-0000-0000-0000-0000000000a8') && (
                                    <Field
                                      validate={required}
                                      name='province'
                                      render={(field: any) => {
                                        {
                                          return (
                                            <div>
                                              <Typeahead
                                                isLoading={
                                                  (!lists.provinceList ||
                                                    lists.provinceList.length ==
                                                      0) &&
                                                  values.countryId
                                                }
                                                emptyLabel={
                                                  !lists.provinceList ||
                                                  lists.provinceList.length == 0
                                                    ? 'Cargando...'
                                                    : 'No se han encontrado resultados.'
                                                }
                                                ref={provincesRef}
                                                disabled={!values.countryId}
                                                inputProps={{
                                                  className:
                                                    field.meta.error &&
                                                    field.meta.submitFailed
                                                      ? 'form-control is-invalid'
                                                      : 'form-control'
                                                }}
                                                id={field.input.name}
                                                labelKey={(option: any) => {
                                                  return option.text;
                                                }}
                                                filterBy={(option, props) => {
                                                  if (props.selected.length) {
                                                    // Display all the options if there's a selection.
                                                    return true;
                                                  }
                                                  // Otherwise filter on some criteria.
                                                  return (
                                                    option.text
                                                      .toLowerCase()
                                                      .indexOf(
                                                        props.text.toLowerCase()
                                                      ) !== -1
                                                  );
                                                }}
                                                options={lists.provinceList}
                                                placeholder='Selecciona...'
                                                onChange={(value: any) => {
                                                  if (value.length > 0) {
                                                    form.change(
                                                      'province',
                                                      value[0].oid
                                                    );
                                                  } else {
                                                    form.change(
                                                      'province',
                                                      null
                                                    );
                                                  }

                                                  form.change(
                                                    'municipalityId',
                                                    null
                                                  );
                                                  form.change(
                                                    'postalCodeId',
                                                    null
                                                  );
                                                  if (
                                                    municipalitiesRef.current
                                                  ) {
                                                    municipalitiesRef.current.clear();
                                                  }
                                                  if (postalCodeRef.current) {
                                                    postalCodeRef.current.clear();
                                                  }
                                                }}
                                              />
                                              {field.meta.error &&
                                                field.meta.submitFailed && (
                                                  <div
                                                    className='invalid-feedback'
                                                    style={{
                                                      display: 'block'
                                                    }}
                                                  >
                                                    {field.meta.error}
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        }
                                      }}
                                    />
                                  )}

                                  {values.countryId != null &&
                                    values.countryId !=
                                      '00000000-0000-0000-0000-0000000000a8' && (
                                      <div>
                                        <Field
                                          validate={required}
                                          name='province'
                                          className='form-control'
                                          component='input'
                                          type='text'
                                          defaultValue=''
                                          autoComplete='off'
                                        />
                                        <div className='invalid-feedback'>
                                          {form.getFieldState('name') &&
                                            form.getFieldState('name').error}
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div className='row'>
                                  <div className='col-md-8'>
                                    <div className='form-group'>
                                      <label className='mb-0'>
                                        Municipio *
                                      </label>

                                      {(!values.countryId ||
                                        values.countryId ===
                                          '00000000-0000-0000-0000-0000000000a8') && (
                                        <Field
                                          validate={required}
                                          name='municipalityId'
                                          render={(field: any) => {
                                            {
                                              return (
                                                <div>
                                                  <Typeahead
                                                    isLoading={
                                                      (!lists.municipalityList ||
                                                        lists.municipalityList
                                                          .length == 0) &&
                                                      values.province
                                                    }
                                                    emptyLabel={
                                                      !lists.municipalityList ||
                                                      lists.municipalityList
                                                        .length == 0
                                                        ? 'Cargando...'
                                                        : 'No se han encontrado resultados.'
                                                    }
                                                    ref={municipalitiesRef}
                                                    inputProps={{
                                                      className:
                                                        field.meta.error &&
                                                        field.meta.submitFailed
                                                          ? 'form-control is-invalid'
                                                          : 'form-control'
                                                    }}
                                                    maxResults={
                                                      lists.municipalityList
                                                        .length
                                                    }
                                                    paginate={false}
                                                    id={field.input.name}
                                                    filterBy={(
                                                      option,
                                                      props
                                                    ) => {
                                                      if (
                                                        props.selected.length &&
                                                        values.province ==
                                                          option.parentOid
                                                      ) {
                                                        // Display all the options if there's a selection.
                                                        return true;
                                                      }
                                                      // Otherwise filter on some criteria.
                                                      return (
                                                        option.text
                                                          .toLowerCase()
                                                          .indexOf(
                                                            props.text.toLowerCase()
                                                          ) !== -1 &&
                                                        values.province ==
                                                          option.parentOid
                                                      );
                                                    }}
                                                    labelKey={(option: any) => {
                                                      return option.text;
                                                    }}
                                                    options={
                                                      lists.municipalityList
                                                    }
                                                    placeholder='Selecciona...'
                                                    onChange={(value: any) => {
                                                      if (value.length > 0) {
                                                        form.change(
                                                          'municipalityId',
                                                          value[0].oid
                                                        );
                                                      } else {
                                                        form.change(
                                                          'municipalityId',
                                                          null
                                                        );
                                                      }

                                                      form.change(
                                                        'postalCodeId',
                                                        null
                                                      );

                                                      if (
                                                        postalCodeRef.current
                                                      ) {
                                                        postalCodeRef.current.clear();
                                                      }
                                                    }}
                                                    disabled={
                                                      values.province
                                                        ? false
                                                        : true
                                                    }
                                                  />
                                                  {field.meta.error &&
                                                    field.meta.touched && (
                                                      <div
                                                        className='invalid-feedback'
                                                        style={{
                                                          display: 'block'
                                                        }}
                                                      >
                                                        {field.meta.error}
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            }
                                          }}
                                        />
                                      )}

                                      {values.countryId != null &&
                                        values.countryId !=
                                          '00000000-0000-0000-0000-0000000000a8' && (
                                          <div>
                                            <Field
                                              validate={required}
                                              name='municipality'
                                              className='form-control'
                                              component='input'
                                              type='text'
                                              defaultValue=''
                                              autoComplete='off'
                                            />
                                            <div className='invalid-feedback'>
                                              {form.getFieldState('name') &&
                                                form.getFieldState('name')
                                                  .error}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className='col-md-4'>
                                    <div className='form-group'>
                                      <label className='mb-0'>
                                        Código postal *
                                      </label>

                                      {(!values.countryId ||
                                        values.countryId ==
                                          '00000000-0000-0000-0000-0000000000a8') && (
                                        <Field
                                          validate={required}
                                          name='postalCodeId'
                                          render={(field: any) => {
                                            return (
                                              <div>
                                                <Typeahead
                                                  ref={postalCodeRef}
                                                  inputProps={{
                                                    className:
                                                      field.meta.error &&
                                                      field.meta.submitFailed
                                                        ? 'form-control is-invalid'
                                                        : 'form-control'
                                                  }}
                                                  maxResults={
                                                    lists.postalCodeList.length
                                                  }
                                                  paginate={false}
                                                  id={field.input.name}
                                                  filterBy={(option: any) => {
                                                    if (
                                                      values.municipalityId !=
                                                      null
                                                    ) {
                                                      return (
                                                        values.municipalityId ===
                                                        option.parentOid
                                                      );
                                                    } else {
                                                      return true;
                                                    }
                                                  }}
                                                  labelKey={(option: any) => {
                                                    return option.text;
                                                  }}
                                                  options={lists.postalCodeList}
                                                  placeholder='Selecciona...'
                                                  onChange={(value: any) => {
                                                    if (value.length > 0) {
                                                      form.change(
                                                        'postalCodeId',
                                                        value[0].oid
                                                      );
                                                    } else {
                                                      form.change(
                                                        'postalCodeId',
                                                        null
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    values.municipalityId
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                {field.meta.error &&
                                                  field.meta.touched && (
                                                    <div
                                                      className='invalid-feedback'
                                                      style={{
                                                        display: 'block'
                                                      }}
                                                    >
                                                      {field.meta.error}
                                                    </div>
                                                  )}
                                              </div>
                                            );
                                          }}
                                        />
                                      )}
                                      {values.countryId != null &&
                                        values.countryId !=
                                          '00000000-0000-0000-0000-0000000000a8' && (
                                          <div>
                                            <Field
                                              validate={required}
                                              name='postalCode'
                                              className='form-control'
                                              component='input'
                                              type='text'
                                              defaultValue=''
                                              autoComplete='off'
                                            />
                                            <div className='invalid-feedback'>
                                              {form.getFieldState('name') &&
                                                form.getFieldState('name')
                                                  .error}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-lg-12'>
                            <h6 className='card-title mt-3'>
                              Persona de contacto
                            </h6>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='mb-0'>
                                    Nombre y apellidos *
                                  </label>
                                  <Field
                                    className={
                                      form.getFieldState('contactName') &&
                                      form.getFieldState('contactName').error &&
                                      form.getFieldState('contactName')
                                        .submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='contactName'
                                    component='input'
                                    type='text'
                                    autoComplete='off'
                                    validate={required}
                                  />
                                  <div className='invalid-feedback'>
                                    {form.getFieldState('contactName') &&
                                      form.getFieldState('contactName').error}
                                  </div>
                                </div>

                                <div className='form-group'>
                                  <label className='mb-0'>
                                    Cargo en la organización *
                                  </label>
                                  <Field
                                    className={
                                      form.getFieldState('position') &&
                                      form.getFieldState('position').error &&
                                      form.getFieldState('position')
                                        .submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='position'
                                    component='input'
                                    type='text'
                                    autoComplete='off'
                                    validate={required}
                                  />
                                  <div className='invalid-feedback'>
                                    {form.getFieldState('position') &&
                                      form.getFieldState('position').error}
                                  </div>
                                </div>

                                <div className='form-group'>
                                  <label className='mb-0'>Teléfono *</label>
                                  <Field
                                    validate={composeValidators(
                                      required,
                                      validPhoneNumber
                                    )}
                                    className={
                                      form.getFieldState('phone') &&
                                      form.getFieldState('phone').error &&
                                      form.getFieldState('phone').submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='phone'
                                    component='input'
                                    type='text'
                                    autoComplete='off'
                                  />
                                  <div className='invalid-feedback'>
                                    {form.getFieldState('phone') &&
                                      form.getFieldState('phone').error}
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='mb-0'>
                                    Correo electrónico *
                                  </label>
                                  <Field
                                    validate={composeValidators(
                                      required,
                                      validEmailFormat
                                    )}
                                    className={
                                      form.getFieldState('email') &&
                                      form.getFieldState('email').error &&
                                      form.getFieldState('email').submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='email'
                                    component='input'
                                    type='email'
                                  />
                                  <div className='invalid-feedback'>
                                    {form.getFieldState('email') &&
                                      form.getFieldState('email').error}
                                  </div>
                                </div>

                                <div className='form-group'>
                                  <label className='mb-0'>Contraseña *</label>
                                  <Field
                                    autoComplete={false}
                                    className={
                                      form.getFieldState('password') &&
                                      form.getFieldState('password').error &&
                                      form.getFieldState('password')
                                        .submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='password'
                                    component='input'
                                    type='password'
                                    validate={composeValidators(
                                      required,
                                      minSixChars
                                    )}
                                  />
                                  <div className='invalid-feedback'>
                                    {form.getFieldState('password') &&
                                      form.getFieldState('password').error}
                                  </div>
                                </div>
                                <div className='form-group'>
                                  <label className='mb-0'>
                                    Repite la contraseña *
                                  </label>
                                  <Field
                                    className={
                                      form.getFieldState('confirmPassword') &&
                                      form.getFieldState('confirmPassword')
                                        .error &&
                                      form.getFieldState('confirmPassword')
                                        .submitFailed
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    name='confirmPassword'
                                    component='input'
                                    type='password'
                                    validate={composeValidators(
                                      required,
                                      matchingPasswords
                                    )}
                                  />
                                  <div className='invalid-feedback'>
                                    {form.getFieldState('confirmPassword') &&
                                      form.getFieldState('confirmPassword')
                                        .error}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-lg-12'>
                            <LoadingOverlay
                              active={loginLoadingSR}
                              spinner={
                                <SyncLoader loading={true} color='#51cbce' />
                              }
                              text='Cargando...'
                              styles={{
                                overlay: (base: any) => ({
                                  ...base,
                                  background: 'transparent',
                                  color: 'black'
                                })
                              }}
                            >
                              <h6 className='card-title my-4'>
                                Beneficios sociales de la organización
                              </h6>
                              <div className='card-body'>
                                <SocialBenefitsComponent
                                  {...loginProps}
                                  socialBenefitsData={socialBenefitsData}
                                  setSocialBenefitsData={setSocialBenefitsData}
                                  loginLoadingSR={loginLoadingSR}
                                  setLoginLoadingSR={setLoginLoadingSR}
                                  lists={lists}
                                />

                                {/* Beneficios Sociales */}
                              </div>
                            </LoadingOverlay>
                          </div>

                          <div className='col-lg-12 mt-2 '>
                            <p className='text-muted'>
                              {' '}
                              WORKETIK PORTAL,S.L como responsable del
                              tratamiento tratará tus datos con la finalidad de
                              gestionar y tramitar tu registro como
                              organización. Puedes acceder, rectificar y
                              suprimir tus datos, así como ejercer otros
                              derechos consultando la información adicional y
                              detallada sobre protección de datos en nuestra{' '}
                              <Link to='/privacy-policy' target='_blank'>
                                política de privacidad
                              </Link>
                              {'.'}
                            </p>
                            <div className='form-group mb-1'>
                              <div className='form-check'>
                                <label className='form-check-label'>
                                  <Field
                                    validate={required}
                                    className='form-check-input'
                                    name='authority'
                                    component='input'
                                    type='checkbox'
                                  />{' '}
                                  <span className='form-check-sign'></span>
                                  <span className='text-muted'>
                                    Tengo la autoridad suficiente para publicar
                                    en esta web
                                  </span>
                                  {values.authority}
                                  {form.getFieldState('authority') &&
                                    form.getFieldState('authority').error &&
                                    form.getFieldState('authority')
                                      .submitFailed && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {form.getFieldState('authority').error}
                                      </div>
                                    )}
                                </label>
                              </div>
                            </div>
                            <div className='form-group mb-1'>
                              <div className='form-check'>
                                <label className='form-check-label'>
                                  <Field
                                    validate={value =>
                                      value
                                        ? undefined
                                        : 'Debes aceptar las condiciones para continuar.'
                                    }
                                    className='form-check-input'
                                    name='privacity'
                                    component='input'
                                    type='checkbox'
                                  />{' '}
                                  <span className='form-check-sign'></span>
                                  <span className='text-muted'>
                                    He leído y acepto las condiciones contenidas
                                    en la
                                    <Link to='/privacy-policy' target='_blank'>
                                      {' '}
                                      política de privacidad
                                    </Link>{' '}
                                    sobre el tratamiento de mis datos para
                                    gestionar mi registro como organización.
                                  </span>
                                  {values.privacity}
                                  {form.getFieldState('privacity') &&
                                    form.getFieldState('privacity').error &&
                                    form.getFieldState('privacity')
                                      .submitFailed && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {form.getFieldState('privacity').error}
                                      </div>
                                    )}
                                </label>
                              </div>
                            </div>
                            <div className='form-group mb-3'>
                              <div className='form-check'>
                                <label className='form-check-label'>
                                  <Field
                                    validate={value =>
                                      value
                                        ? undefined
                                        : 'Debes aceptar las condiciones para continuar.'
                                    }
                                    className='custom-control-input'
                                    name='termsOfUseAndEthicalCode'
                                    component='input'
                                    type='checkbox'
                                  />{' '}
                                  <span className='form-check-sign'></span>
                                  <span className='text-muted'>
                                    He leído y acepto las
                                    <Link to='/terms-of-use' target='_blank'>
                                      {' '}
                                      condiciones generales de contratación
                                    </Link>{' '}
                                    y el{' '}
                                    <Link to='/ethical-code' target='_blank'>
                                      {' '}
                                      compromiso ético
                                    </Link>{' '}
                                    de Worketik.
                                  </span>
                                  {values.termsOfUseAndEthicalCode}
                                  {form.getFieldState(
                                    'termsOfUseAndEthicalCode'
                                  ) &&
                                    form.getFieldState(
                                      'termsOfUseAndEthicalCode'
                                    ).error &&
                                    form.getFieldState(
                                      'termsOfUseAndEthicalCode'
                                    ).submitFailed && (
                                      <div
                                        className='invalid-feedback'
                                        style={{ display: 'block' }}
                                      >
                                        {
                                          form.getFieldState(
                                            'termsOfUseAndEthicalCode'
                                          ).error
                                        }
                                      </div>
                                    )}
                                </label>
                              </div>
                            </div>
                            <p className='text-muted'>
                              {' '}
                              Nos gustaría que nos prestaras tu consentimiento
                              para:
                            </p>
                            <div className='form-group mb-3'>
                              <div className='form-check'>
                                <label className='form-check-label'>
                                  <Field
                                    className='custom-control-input'
                                    name='sendMarketingInformation'
                                    component='input'
                                    type='checkbox'
                                  />{' '}
                                  <span className='form-check-sign'></span>
                                  <span className='text-muted'>
                                    Enviarte información comercial sobre los
                                    productos, servicios, novedades de WORKETIK
                                    PORTAL,S.L
                                  </span>
                                  {values.sendMarketingInformation}
                                </label>
                              </div>
                            </div>
                            <button
                              type='button'
                              className='btn-round btn btn-primary'
                              disabled={
                                loginLoadingSR ||
                                submitting ||
                                registeredSuccessfully
                              }
                              onClick={() => {
                                form.submit();

                                if (hasValidationErrors) {
                                  setAlert({
                                    text:
                                      'Oh oh, algo no ha ido bien... Revisa los campos del formulario!',
                                    color: 'danger',
                                    visible: true
                                  });
                                }
                              }}
                            >
                              Registrar la organización
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </LoadingOverlay>
              )}
            />
          </div>
        );
      }}
    />
  );
};

export default Login;
