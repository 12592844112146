import React, { useState, useEffect } from "react";
import SocialBenefits from "../components/OfferDetail/SocialBenefits";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";
import { config } from "../utils/Constants";
const CompanyDetail: React.FC = (props: any) => {
  const getCompanyDetail = async (setter: any) => {
    setLoading(true);
    const result = await fetch(
      `${config.apiURL}companies/${props.routeProps.match.params.id}`
    );
    if (result.ok) {
      var response = await result.json();
      setter(response.item);
    }
    setLoading(false);
  };

  const [companyDetail, setCompanyDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyDetail(setCompanyDetail);
  }, []);

  const SRProps = {
    ...props,
    companyDetail,
  };

  return (
    <div>
      <LoadingOverlay
        active={loading}
        spinner={<SyncLoader loading={loading} color="#51cbce" />}
        text={"Cargando..."}
        styles={{
          overlay: (base: any) => ({
            ...base,
            borderRadius: "12px",
          }),
        }}
      >
        <div className="row">
          <div className="col-md-12">
            {companyDetail && <SocialBenefits {...SRProps} />}
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default CompanyDetail;
