import React from 'react';
import { Link } from 'react-router-dom';
import { myOfferCandidate } from '../layouts/Candidates';

const Candidates: React.FC<myOfferCandidate> = (props: any) => {
  return (
    <div>
      {props.offerCandidates.length > 0 && (
        <div className='card-user card '>
          <div className=' card-header'>
            <h5 className='card-title mb-0' id='registro'>
              Listado de candidatos inscritos
            </h5>
          </div>
          <div className='card-body'>
            <ul className='nav nav-tabs mb-3'>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark'
                  href=''
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.getCandidates(null);
                  }}
                >
                  Todos/as
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark'
                  href=''
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.getCandidates(0);
                  }}
                >
                  <i
                    className='fas fa-clock text-warning'
                    data-toggle='tooltip'
                    title='Por leer'
                  ></i>{' '}
                  Por leer
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark'
                  href=''
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.getCandidates(1);
                  }}
                >
                  <i
                    className='fas fa-tag text-warning'
                    data-toggle='tooltip'
                    title='En proceso'
                  ></i>{' '}
                  En proceso
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark'
                  href=''
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.getCandidates(2);
                  }}
                >
                  {' '}
                  <i
                    className='fas fa-check text-success'
                    data-toggle='tooltip'
                    title='Seleccionados/as'
                  ></i>{' '}
                  Seleccionados
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark'
                  href=''
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.getCandidates(3);
                  }}
                >
                  {' '}
                  <i
                    className='fas fa-trophy text-success'
                    data-toggle='tooltip'
                    title='Finalistas'
                  ></i>{' '}
                  Finalistas
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark'
                  href=''
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.getCandidates(4);
                  }}
                >
                  {' '}
                  <i
                    className='fas fa-user-slash text-danger'
                    data-toggle='tooltip'
                    title='Descartados/as'
                  ></i>{' '}
                  Descartados
                </a>
              </li>
            </ul>

            {/* 
                            <div className="btn-group btn-group-toggle" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { props.getCandidates(null) }}> Todos/as</button>
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { props.getCandidates(0) }}>
                                    <i className="fas fa-clock " data-toggle="tooltip" title="Por leer" ></i> Por leer
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { props.getCandidates(1) }}>
                                    <i className="fas fa-tag " data-toggle="tooltip" title="En proceso"></i> En proceso
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { props.getCandidates(2) }}>
                                    <i className="fas fa-check " data-toggle="tooltip" title="Seleccionados/as"></i> Seleccionados
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { props.getCandidates(3) }}>
                                    <i className="fas fa-trophy " data-toggle="tooltip" title="Finalistas"></i> Finalistas
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { props.getCandidates(4) }}>
                                    <i className="fas fa-user-slash " data-toggle="tooltip" title="Descartados/as"></i> Descartados
                                </button>
                            </div>
 */}
            <div className='table-responsive table-hover'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Fecha ins.</th>
                    <th>Nombre y apellidos</th>
                    <th>Estado</th>

                    <th>Preguntas de filtrado</th>
                    <th>Correo electrónico</th>
                  </tr>
                </thead>
                <tbody>
                  {props.offerCandidates.map((item: any, i: any) => {
                    return (
                      <tr>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>
                          <Link
                            to={
                              '/private/offer-detail/' +
                              props.routeProps.match.params.id +
                              '/candidates/' +
                              item.oid
                            }
                            data-toggle='tooltip'
                            title='Ver candidato'
                            className='text-dark'
                          >
                            {item.name}
                          </Link>
                        </td>
                        <td>
                          {item.state === 0 ? (
                            <span className='p-2 '>
                              {' '}
                              <i
                                className='fas fa-sm fa-clock text-muted'
                                data-toggle='tooltip'
                                title='Por leer'
                              ></i>{' '}
                              Por leer
                            </span>
                          ) : null}
                          {item.state === 1 ? (
                            <span className='p-2 '>
                              {' '}
                              <i
                                className='fas fa-sm fa-tag text-muted '
                                data-toggle='tooltip'
                                title='En proceso'
                              ></i>{' '}
                              En proceso
                            </span>
                          ) : null}
                          {item.state === 2 ? (
                            <span className='p-2 '>
                              <i
                                className='fas fa-sm fa-check text-muted'
                                data-toggle='tooltip'
                                title='Seleccionado/a'
                              ></i>{' '}
                              Seleccionado/a
                            </span>
                          ) : null}
                          {item.state === 3 ? (
                            <span className='p-2 '>
                              {' '}
                              <i
                                className='fas fa-sm fa-trophy text-muted'
                                data-toggle='tooltip'
                                title='Finalista'
                              ></i>{' '}
                              Finalista
                            </span>
                          ) : null}
                          {item.state === 4 ? (
                            <span className='p-2 '>
                              {' '}
                              <i
                                className='fas fa-sm fa-user-slash text-muted'
                                data-toggle='tooltip'
                                title='Descartado'
                              ></i>{' '}
                              Descartado
                            </span>
                          ) : null}
                          <br />
                          <small>
                            {item.discardedReason === 1
                              ? 'No ha superado el test.'
                              : null}
                          </small>
                          <small>
                            {item.discardedReason === 2
                              ? 'No cumple uno o más requisitos de la oferta.'
                              : null}
                          </small>
                          <small>
                            {item.discardedReason === 3
                              ? 'Hay otras candidaturas que se ajustan mejor al perfil.'
                              : null}
                          </small>
                          <small>
                            {item.discardedReason === 4
                              ? 'No superada la entrevista'
                              : null}
                          </small>
                          <small>
                            {item.discardedReason === 5 ? item.reason : null}
                          </small>
                        </td>
                        <td>
                          {item.result}/{item.total}
                        </td>
                        <td>{item.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {props.offerCandidates.length == 0 && (
        <div className='card-user card '>
          <div className=' card-body'>
            <h5 className='card-title'>
              {' '}
              Aún no hay candidatos inscritos en ésta oferta. Te avisaremos por
              correo cuando un nuevo candidato se inscriba.
            </h5>
            <p className='mb-0'>
              Recuerda que para que tu oferta sea visible, puedes publicarla
              mediante el boton "publicar" del listado de ofertas.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Candidates;
