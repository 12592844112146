import React, { useState } from "react";
import { Field } from "react-final-form";

const SocialBenefits: React.FC = (props: any) => {
  return (
    <div className="card-user card shadow-none">
      <div className=" card-header pt-0">
        <h5 className="card-title " id="registro">
          Beneficios sociales
        </h5>
      </div>

      <div className="card-body pb-4">
        <div className="container">
          <div className="card-columns">
            {props.lists.SRGroupList.map((group: any, i: any) => {
              if (
                props.companySocialBenefits.find(
                  (companySocialBenefit: any) => {
                    return companySocialBenefit.parentOid == group.oid;
                  }
                )
              ) {
                return (
                  <div className="card shadow-none" key={i}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
                          <img
                            src={require("../../assets/img/ic_" +
                              group.oid +
                              ".png")}
                            alt="Logo Worketik"
                            className="d-block mb-2"
                          />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                          <h6 className="mb-2">{group.text}</h6>
                          {props.companySocialBenefits.map(
                            (companyBenefit: any, j: any) => {
                              if (companyBenefit.parentOid === group.oid) {
                                return (
                                  <div key={j}>
                                    <div className="form-group mb-1">
                                      <div className="form-check">
                                        <label className="form-check-label">
                                          <Field
                                            checked={
                                              props.value.find((item: any) => {
                                                return (
                                                  item.categoryOid ===
                                                  companyBenefit.categoryOid
                                                );
                                              })
                                                ? true
                                                : false
                                            }
                                            className="form-check-input"
                                            name={companyBenefit.oid}
                                            type="checkbox"
                                            component="input"
                                            onChange={() => {
                                              if (
                                                props.value.find(
                                                  (item: any) => {
                                                    return (
                                                      item.categoryOid ===
                                                      companyBenefit.categoryOid
                                                    );
                                                  }
                                                )
                                              ) {
                                                props.remove(
                                                  "socialResponsibilities",
                                                  props.value.indexOf(
                                                    props.value.find(
                                                      (item: any) =>
                                                        item.categoryOid ===
                                                        companyBenefit.categoryOid
                                                    )
                                                  )
                                                );
                                              } else {
                                                props.push(
                                                  "socialResponsibilities",
                                                  companyBenefit
                                                );
                                              }
                                            }}
                                          />{" "}
                                          <span className="form-check-sign" />
                                          <span className="text-muted">
                                            {companyBenefit.text}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialBenefits;
