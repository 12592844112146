import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { Router, Switch, Route, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { ProtectedRouteProps, ProtectedRoute } from "./utils/PrivateRoute";
import PublicView from "./views/Public";
import WarningView from "./views/Warning";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation, useHistory } from "react-router-dom";
import EmbeddedView from "./views/Embedded";

const trackingId = "UA-93158435-2";

const App: React.FC = () => {
  let location = useLocation();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("");
  const [consent, setConsent] = useState(
    (document.cookie.match(/^(?:.*;)?\s*consent-we\s*=\s*([^;]+)(?:.*)?$/) || [
      ,
      null,
    ])[1]
  );

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    mode,
    authenticationPath: "/login",
    UpdateAuthentication: null,
  };

  useEffect(() => {
    if (consent) {
      ReactGA.initialize("UA-93158435-2");
      ReactGA.pageview(window.location.pathname + window.location.search);
      history.listen((location, action) => {
        ReactGA.pageview(location.pathname + location.search);
      });
    }
  }, []);

  useEffect(() => {
    if (consent) {
      ReactGA.initialize("UA-93158435-2");
      ReactGA.pageview(window.location.pathname + window.location.search);
      history.listen((location, action) => {
        ReactGA.pageview(location.pathname + location.search);
      });
    }
  }, [consent]);

  return (
    <Route
      render={(props: any) => {
        defaultProtectedRouteProps.UpdateAuthentication = () => {
          setMode("EMPRESA");
          props.history.push("/private");
        };

        const appProps = {
          ...defaultProtectedRouteProps,
        };

        return (
          <LoadingOverlay
            active={
              loading ||
              (!consent && props.location.pathname !== "/cookies-policy")
            }
            spinner={<SyncLoader loading={loading} color="#51cbce" />}
            text={
              loading
                ? "Cargando..."
                : "Debe aceptar la política de cookies para seguir navegando"
            }
          >
            <div>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="fade"
                  timeout={{ enter: 300, exit: 300 }}
                >
                  <Switch location={location}>
                    <Route path="/private">
                      <ProtectedRoute {...appProps} path="/private" />
                    </Route>
                    <Route path="/embedded">
                      <EmbeddedView {...appProps} setMainSpinner={setLoading} />
                    </Route>
                    <Route
                      path={[
                        "/",
                        "/offers",
                        "/resume-registration",
                        "/login",
                        "/about",
                        "/privacy-policy",
                        "/legal-warning",
                        "/cookies-policy",
                        "/social-media-policy",
                        "/terms-of-use",
                        "/ethical-code",
                      ]}
                    >
                      <PublicView {...appProps} setMainSpinner={setLoading} />
                    </Route>
                    {/* <Route>
                      <WarningView
                        {...appProps}
                        history={history}
                        setMainSpinner={setLoading}
                      />
                    </Route> */}
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
            <CookieConsent
              cookieName={"consent-we"}
              expires={365}
              acceptOnScroll={false}
              style={{
                zIndex: 100000,
                backgroundColor: "#51cbce",
                color: "#fff",
              }}
              buttonStyle={{ backgroundColor: "#fff", color: "#51cbce" }}
              buttonText="Acepto"
              buttonClasses={"btn btn-primary"}
              onAccept={() => {
                ReactGA.initialize(trackingId, {
                  standardImplementation: true,
                });
                setConsent("true");
              }}
            >
              Utilizamos cookies propias y de terceros para mejorar nuestros
              servicios y mostrarle publicidad relacionada con sus preferencias
              mediante el análisis de sus hábitos de navegación. Puede cambiar
              la configuración u obtener más información en nuestra{" "}
              <Link
                to="/cookies-policy"
                target={"_blank"}
                style={{ color: "#fff" }}
              >
                <b>"Política de Cookies"</b>.
              </Link>
            </CookieConsent>
          </LoadingOverlay>
        );
      }}
    />
  );
};

export default App;
