import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FieldRenderProps } from 'react-final-form';
import { useEffect } from 'react';

declare interface LanguagesTableTypeAheadProps {
  field: FieldRenderProps<any, HTMLElement>;
  index: number;
  list: any[];
  disabled: boolean;
  defaultSelected?: any;
  id: any;
  placeholder: string;
}

const LanguagesTableTypeAhead: React.FC<LanguagesTableTypeAheadProps> = (
  props
) => {
  const [refresh, setRefresh] = React.useState(null);
  const [value, setValue] = React.useState(props.defaultSelected);

  useEffect(() => {
    console.log(props.defaultSelected);
  }, [refresh]);

  return (
    <div>
      <Typeahead
        inputProps={{
          className:
            props.field.meta.error && props.field.meta.submitFailed
              ? 'form-control is-invalid'
              : 'form-control',
        }}
        filterBy={(option: any, props) => {
          if (props.selected.length) {
            // Display all the options if there's a selection.
            return true;
          }
          // Otherwise filter on some criteria.
          return (
            option.text.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
          );
        }}
        isLoading={!props.list || props.list.length < 0}
        emptyLabel={
          !props.list || props.list.length == 0
            ? 'Cargando...'
            : 'No se han encontrado resultados.'
        }
        disabled={props.disabled}
        labelKey={(option: any) => {
          return option.text ? option.text : undefined;
        }}
        id={props.id}
        placeholder={props.placeholder}
        options={props.list}
        onChange={(value: any) => {
          props.field.input.onChange(value[0]);
          setRefresh(new Date().getTime());
        }}
        selected={props.field.input.value ? [props.field.input.value] : []}
      />

      <div
        className='invalid-feedback'
        style={{
          display: 'inline-block',
          visibility:
            props.field.meta.error && props.field.meta.submitFailed
              ? 'visible'
              : 'hidden',
        }}
      >
        {props.field.meta.error}
      </div>
    </div>
  );
};

export default LanguagesTableTypeAhead;
