import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form, Field } from "react-final-form";
import { Button } from "reactstrap";
import { selectSingleElement } from "../../utils/selector";
import SocialBenefits from "./SocialBenefits";
import arrayMutators from "final-form-arrays";
const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce(
    (error: any, validator: any) => error || validator(value),
    undefined
  );
const required = (value: any) =>
  value ? undefined : "Rellene este campo para continuar.";

const payCountRange = (value: any) =>
  value && value >= 12 && value <= 365
    ? undefined
    : "Por favor, introduzca un nuúmero entre 12 y 365 para continuar";

const Offer: React.FC<any> = (props: any) => {
  return (
    <Form
      mutators={{ ...arrayMutators }}
      initialValues={{
        companyOid: props.companyID ? props.companyID : null,
        name: props.offer.name,
        address: props.offer.address,
        description: props.offer.description,
        vacancies: props.offer.vacancies,
        functionalLevel: selectSingleElement(
          props.lists.functionalLevelList,
          props.offer.functionalLevel
        ),
        workingDay: selectSingleElement(
          props.lists.workingDayList,
          props.offer.workingDay
        ),

        duration: props.offer.duration,
        contractModality: selectSingleElement(
          props.lists.contractModalityList,
          props.offer.contractModality
        ),
        schedule: props.offer.schedule,
        salaryType:
          props.offer.salaryType === 0 || props.offer.salaryType === 1
            ? [
                {
                  key: props.offer.salaryType,
                  text: props.offer.salaryType == 0 ? "Mensual" : "Anual"
                }
              ]
            : [],
        minSalary: props.offer.minSalary,
        maxSalary: props.offer.maxSalary,
        payCount: props.offer.payCount,
        socialResponsibilities: props.selectedSocialBenefits
      }}
      onSubmit={(values: any) => {
        props.submitFirstStep(
          props.stepper,
          props.setOfferID,
          values,
          props.company.oid,
          props.offer.oid
        );
      }}
      render={({
        form: {
          mutators: { push, remove }
        },
        handleSubmit,
        form,
        submitting,
        initialValues,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="card-user card shadow-none mb-0">
            <div className=" card-header">
              <h5 className="card-title mb-0" id="registro">
                <i className="nc-icon nc-alert-circle-i" /> Datos de la oferta
              </h5>
            </div>
            <div className="card-body shadow-none">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <label>Título de la oferta *</label>
                    <Field
                      className={
                        form.getFieldState("name") &&
                        form.getFieldState("name").error &&
                        form.getFieldState("name").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      name="name"
                      component="input"
                      type="text"
                      autoComplete="off"
                      validate={required}
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("name") &&
                        form.getFieldState("name").error}
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <label>Descripción completa de la oferta de trabajo *</label>
                    <Field
                      className={
                        form.getFieldState("description") &&
                        form.getFieldState("description").error &&
                        form.getFieldState("description").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      name="description"
                      component="textarea"
                      type="text"
                      validate={required}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("description") &&
                        form.getFieldState("description").error}
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4">
                  <div className="form-group">
                    <label>Ubicación *</label>
                    <small className="form-text text-muted  mt-0 mb-1">
                      Puedes añadir más ubicaciones en el perfil de organización
                    </small>
                    <Field
                      name={"address"}
                      validate={required}
                      render={field => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={
                                !props.company.addresses.length
                                  ? "Cargando..."
                                  : "No se han encontrado resultados."
                              }
                              disabled={
                                !props.offer &&
                                props.offer.state !== 0 &&
                                props.offer.state !== 1
                              }
                              inputProps={{
                                className:
                                  field.meta.error && field.meta.submitFailed
                                    ? "form-control is-invalid"
                                    : "form-control"
                              }}
                              labelKey={(option: any) => {
                                return option.postalAddress
                                  ? option.postalAddress +
                                      " (" +
                                      option.municipality.text +
                                      ")"
                                  : undefined;
                              }}
                              filterBy={(option, props) => {
                                if (props.selected.length) {
                                  // Display all the options if there's a selection.
                                  return true;
                                }
                                // Otherwise filter on some criteria.
                                return (
                                  (
                                    option.postalAddress +
                                    " (" +
                                    option.municipality.text +
                                    ")"
                                  )
                                    .toLowerCase()
                                    .indexOf(props.text.toLowerCase()) !== -1
                                );
                              }}
                              id="locationTypeahead"
                              placeholder="Elige una ubicación..."
                              options={props.company.addresses}
                              onChange={(value: any) => {
                                field.input.onChange(value[0]);
                              }}
                              selected={
                                field.input.value
                                  ? [field.input.value]
                                  : initialValues.address
                                  ? [initialValues.address]
                                  : undefined
                              }
                            />

                            {field.meta.error && field.meta.touched && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <label>Número de vacantes *</label>

                        <Field
                          validate={required}
                          name="vacancies"
                          className={
                            form.getFieldState("vacancies") &&
                            form.getFieldState("vacancies").error &&
                            form.getFieldState("vacancies").submitFailed
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="number"
                          min="1"
                          max="10000"
                          step="1"
                          component="input"
                          required
                          defaultValue={props.offer ? props.offer.vacancies : 1}
                        />
                        <div className="invalid-feedback">
                          {form.getFieldState("vacancies") &&
                            form.getFieldState("vacancies").error}
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <label>Nivel funcional *</label>
                        <Field
                          validate={required}
                          name={"functionalLevel"}
                          render={(field: any) => {
                            return (
                              <div>
                                <Typeahead
                                  emptyLabel={
                                    !props.lists.functionalLevelList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  inputProps={{
                                    className:
                                      field.meta.error &&
                                      field.meta.submitFailed
                                        ? "form-control is-invalid"
                                        : "form-control"
                                  }}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  filterBy={(option, props) => {
                                    if (props.selected.length) {
                                      // Display all the options if there's a selection.
                                      return true;
                                    }
                                    // Otherwise filter on some criteria.
                                    return (
                                      option.text
                                        .toLowerCase()
                                        .indexOf(props.text.toLowerCase()) !==
                                      -1
                                    );
                                  }}
                                  id="functionalLevelTypeahead"
                                  placeholder="Elige un nivel funcional..."
                                  options={props.lists.functionalLevelList}
                                  onChange={(value: any) => {
                                    field.input.onChange(value[0]);
                                  }}
                                  selected={
                                    field.input.value
                                      ? [field.input.value]
                                      : initialValues.functionalLevel
                                      ? [initialValues.functionalLevel]
                                      : undefined
                                  }
                                />
                                {field.meta.error && field.meta.touched && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {field.meta.error}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4">
                  <div className="form-group">
                    <label>Jornada laboral *</label>
                    <Field
                      validate={required}
                      name={"workingDay"}
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={
                                !props.lists.workingDayList.length
                                  ? "Cargando..."
                                  : "No se han encontrado resultados."
                              }
                              inputProps={{
                                className:
                                  field.meta.error && field.meta.submitFailed
                                    ? "form-control is-invalid"
                                    : "form-control"
                              }}
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              filterBy={(option, props) => {
                                if (props.selected.length) {
                                  // Display all the options if there's a selection.
                                  return true;
                                }
                                // Otherwise filter on some criteria.
                                return (
                                  option.text
                                    .toLowerCase()
                                    .indexOf(props.text.toLowerCase()) !== -1
                                );
                              }}
                              id="workingDayTypeahead"
                              placeholder="Elige una jornada laboral..."
                              options={props.lists.workingDayList}
                              onChange={(value: any) => {
                                field.input.onChange(value[0]);
                              }}
                              selected={
                                field.input.value
                                  ? [field.input.value]
                                  : initialValues.workingDay
                                  ? [initialValues.workingDay]
                                  : undefined
                              }
                            />

                            {field.meta.error && field.meta.touched && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Duración (Opcional)</label>
                    <Field
                      className="form-control"
                      component="input"
                      type="text"
                      name="duration"
                      autoComplete="off"
                      defaultValue={initialValues.duration}
                    />
                  </div>
                  <div className="form-group">
                    <label>Tipo de contrato *</label>
                    <Field
                      validate={required}
                      name={"contractModality"}
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={
                                !props.lists.contractModalityList.length
                                  ? "Cargando..."
                                  : "No se han encontrado resultados."
                              }
                              inputProps={{
                                className:
                                  field.meta.error && field.meta.submitFailed
                                    ? "form-control is-invalid"
                                    : "form-control"
                              }}
                              labelKey={(option: any) => {
                                return option.text ? option.text : undefined;
                              }}
                              filterBy={(option, props) => {
                                if (props.selected.length) {
                                  // Display all the options if there's a selection.
                                  return true;
                                }
                                // Otherwise filter on some criteria.
                                return (
                                  option.text
                                    .toLowerCase()
                                    .indexOf(props.text.toLowerCase()) !== -1
                                );
                              }}
                              id="contractModalityTypeahead"
                              placeholder="Elige un tipo de contrato..."
                              options={props.lists.contractModalityList}
                              onChange={(value: any) => {
                                field.input.onChange(value[0]);
                              }}
                              selected={
                                field.input.value
                                  ? [field.input.value]
                                  : initialValues.contractModality
                                  ? [initialValues.contractModality]
                                  : undefined
                              }
                            />
                            {field.meta.error && field.meta.touched && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Horario (Opcional)</label>
                    <Field
                      className="form-control"
                      component="input"
                      type="text"
                      name="schedule"
                      autoComplete="off"
                      defaultValue={initialValues.schedule}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4">
                  <div className="form-group">
                    <label>Tipo de salario *</label>
                    <Field
                      validate={required}
                      name={"salaryType"}
                      render={(field: any) => {
                        return (
                          <div>
                            <Typeahead
                              emptyLabel={"No se han encontrado resultados."}
                              inputProps={{
                                className:
                                  field.meta.error && field.meta.submitFailed
                                    ? "form-control is-invalid"
                                    : "form-control"
                              }}
                              labelKey={(option: any) => {
                                return option && option.text
                                  ? option.text
                                  : undefined;
                              }}
                              filterBy={(option, props) => {
                                if (props.selected.length) {
                                  // Display all the options if there's a selection.
                                  return true;
                                }
                                // Otherwise filter on some criteria.
                                return (
                                  option.text
                                    .toLowerCase()
                                    .indexOf(props.text.toLowerCase()) !== -1
                                );
                              }}
                              id="salaryTypeTypeahead"
                              placeholder="Elige un tipo de salario..."
                              options={[
                                { key: 0, text: "Mensual" },
                                { key: 1, text: "Anual" }
                              ]}
                              onChange={(value: any) => {
                                field.input.onChange(value[0]);
                              }}
                              defaultSelected={initialValues.salaryType}
                            />
                            {field.meta.error && field.meta.touched && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {field.meta.error}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Salario mínimo *</label>
                    <Field
                      className={
                        form.getFieldState("minSalary") &&
                        form.getFieldState("minSalary").error &&
                        form.getFieldState("minSalary").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      component="input"
                      type="number"
                      name="minSalary"
                      defaultValue={initialValues.minSalary}
                      min="1"
                      max="1000000"
                      step="1"
                      validate={required}
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("minSalary") &&
                        form.getFieldState("minSalary").error}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Salario máximo *</label>
                    <Field
                      className={
                        form.getFieldState("maxSalary") &&
                        form.getFieldState("maxSalary").error &&
                        form.getFieldState("maxSalary").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      component="input"
                      type="number"
                      name="maxSalary"
                      defaultValue={initialValues.maxSalary}
                      min="1"
                      max="1000000"
                      step="1"
                      validate={required}
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("maxSalary") &&
                        form.getFieldState("maxSalary").error}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Número de pagas anuales *</label>
                    <Field
                      className={
                        form.getFieldState("payCount") &&
                        form.getFieldState("payCount").error &&
                        form.getFieldState("payCount").submitFailed
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      validate={composeValidators(required, payCountRange)}
                      component="input"
                      name="payCount"
                      defaultValue={initialValues.payCount}
                      type="number"
                      step="1"
                    />
                    <div className="invalid-feedback">
                      {form.getFieldState("payCount") &&
                        form.getFieldState("payCount").error}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {props.companySocialBenefits &&
            props.companySocialBenefits.length > 0 && (
              <SocialBenefits
                {...props}
                push={push}
                remove={remove}
                form={form}
                value={values.socialResponsibilities}
              ></SocialBenefits>
            )}

          <Button
            className="btn btn-primary"
            onClick={() => props.routeProps.history.push("/private")}
          >
            Cancelar
          </Button>

          <Button
            disabled={submitting}
            className="btn btn-primary"
            type="submit"
          >
            Siguiente
          </Button>
        </form>
      )}
    />
  );
};

export default Offer;
