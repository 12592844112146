import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import OfferDetail from '../layouts/OfferDetail';
import CompanyDetail from '../layouts/CompanyDetail';
import Enrol from '../layouts/Enrol';
import OffersContainer from '../layouts/Offers';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router';

const Offers: React.FC = (props: any) => {
  let location = useLocation();

  const [routeOid, setRouteOid] = useState(null);
  const offerOidProps = {
    ...props,
    setRouteOid,
  };
  return (
    <div>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames='fade'
          timeout={{ enter: 300, exit: 300 }}
        >
          <Switch location={location}>
            <Route
              path='/offers/offer-detail/:id'
              exact
              render={(routeProps) => (
                <OfferDetail {...offerOidProps} routeProps={routeProps} />
              )}
            />
            <Route
              exact
              path='/offers/offer-detail/:id/enrol'
              render={(routeProps) => (
                <Enrol {...props} routeProps={routeProps} />
              )}
            />
            <Route
              exact
              path='/offers/company-detail/:id'
              render={(routeProps) => (
                <CompanyDetail {...props} routeProps={routeProps} />
              )}
            />
            <Route>
              <OffersContainer {...props} />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Offers;
