import React from 'react';
import NewPasswordComponent from '../components/NewPassword';
import { config } from '../utils/Constants';

const NewPassword: React.FC = (props: any) => {
  let params = new URLSearchParams(props.routeProps.location.search);
  let token = params.get('access_token');
  if (token) {
    sessionStorage.setItem('companyToken', token);
  }

  const postChangePassword = (values: any) => {
    if (values.password === values.confirmPassword) {
      fetch(`${config.apiURL}changepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('companyToken')
        },
        body: JSON.stringify({
          password: values.password,
          confirmPassword: values.confirmPassword
        })
      }).then((response: any) => {
        if (response.ok) {
          props.UpdateAuthentication();
        }
      });
    } else {
      alert("Passwords don't match");
    }
  };
  const newPasswordProps = {
    ...props,
    postChangePassword
  };
  return (
    <div>
      <NewPasswordComponent {...newPasswordProps} />
    </div>
  );
};

export default NewPassword;
