import React, { useState, useEffect } from "react";

import Accordion from "react-bootstrap/Accordion";
import {
  Typeahead,
  MenuItem,
  Highlighter,
  Menu
} from "react-bootstrap-typeahead";
import { Form, Field } from "react-final-form";
import NumberFormat from "react-number-format";
import Card from "react-bootstrap/Card";
import { category } from "../../models/Category";
import "react-input-range/lib/css/index.css";
import Slider from "./Slider";
import { getCategories } from "../../utils/GetCategories";
const _ = require("lodash");
const Filters: React.FC = (props: any) => {
  const [isReset, setIsReset] = useState(null);
  const [SRList, setSRList] = useState([]);
  const [SRGroupList, setSRGroupList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [professionList, setProfessionList] = useState([]);
  const [workingDayList, setWorkingDayList] = useState([]);
  const [contractModalityList, setContractModalityList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [provincesList, setProvincesList] = useState([]);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [educationLevelList, setEducationLevelList] = useState([]);
  const [professionalSkillList, setProfessionalSkillList] = useState([]);
  const [knowledgeList, setKnowledgeList] = useState([]);

  useEffect(() => {
    Promise.all([
      getCategories("SocialResponsibility", setSRList),
      getCategories("sector", setSectorsList),
      getCategories("profession", setProfessionList),
      getCategories("workingDay", setWorkingDayList),
      getCategories("contractModality", setContractModalityList),
      getCategories("country", setCountriesList),
      getCategories("province", setProvincesList),
      getCategories("municipality", setMunicipalitiesList),
      getCategories("language", setLanguagesList),
      getCategories("educationLevel", setEducationLevelList),
      getCategories("professionalSkill", setProfessionalSkillList),
      getCategories("knowledge", setKnowledgeList),
      getCategories("SocialResponsibilityGroup", setSRGroupList)
    ]);
  }, []);

  return (
    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
      <div className="card">
        <div className="card-body">
          {/* Filtre descartat comentat per si de cas        
        <div className="btn-group" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-sm btn-primary m-0">Todo</button>
          <button type="button" className="btn btn-sm btn-outline-primary m-0">Mes</button>
          <button type="button" className="btn btn-sm btn-outline-primary m-0">Semana</button>
        </div> */}

          <Form
            onSubmit={values => {
              props.setFilters({
                ...values,
                minimumSalary: values.salary
                  ? values.salary.min
                  : values.minimumSalary,
                maximumSalary: values.salary
                  ? values.salary.max
                  : values.maximumSalary
              });
              props.setLoad(!props.load);
            }}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-0 mt-0 card-title text-primary">
                  <span className="font-weight-bold">
                    <i className="fas fa-filter"></i> &nbsp; Filtros
                  </span>

                  <a
                    href=""
                    className="pull-right"
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsReset(new Date().getTime());
                      props.setFilters({
                        ...props.emptyFilters,
                        salary: {
                          min: 0,
                          max: props.maxSalary > 20000 ? props.maxSalary : 20000
                        }
                      });
                      form.reset({
                        ...props.emptyFilters,
                        salary: {
                          min: 0,
                          max: props.maxSalary > 20000 ? props.maxSalary : 20000
                        }
                      });
                      props.setLoad(!props.load);
                    }}
                  >
                    <i className="fas fa-times"></i> Borrar filtros&nbsp;
                  </a>
                </div>
                <div className="card-header p-0">
                  <hr className="mt-2 mb-1" />
                </div>

                <Accordion defaultActiveKey="0" >
                  <Card className="mb-1" style={{ overflow: "visible"}} >
                    <div className="border-bottom pb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="pt-1 ">
                      Puesto de trabajo <i className="fas fa-angle-down"></i>{" "}
                      <small className=" pull-right">
                        {props.jobFilterCount > 0
                          ? props.jobFilterCount +
                            (props.jobFilterCount === 1
                              ? " filtro"
                              : " filtros")
                          : null}
                      </small>
                    </Accordion.Toggle>
                   
                    
                    <Accordion.Collapse eventKey="0" className="border-top mt-2">
                      <Card.Body className="py-2 mt-2">
                        <div className="form-group">
                          <label className=" mb-0 font-weight-bold text-primary">
                            Beneficios sociales
                          </label>
                          <img
                            src={require("../../assets/img/beneficios_sociales_filtro.png")}
                            alt="Beneficios sociales"
                            className="mt-1 d-block"
                          />
                          <Field
                            name="socialResponsibilities"
                            type="select"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  isLoading={!SRList.length}
                                  emptyLabel={
                                    !SRList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  options={SRList}
                                  placeholder="Todos..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                  renderMenu={(
                                    results: any[],
                                    menuProps: any
                                  ) => {
                                    let counter = 0;
                                    const items = SRGroupList.map(
                                      (group: any) => {
                                        let srItems = results.filter(
                                          item => item.parentOid == group.oid
                                        );

                                        if (srItems.length) {
                                          return (
                                            <div>
                                              <Menu.Divider
                                                key={`${group}-divider`}
                                              />
                                              <Menu.Header
                                                key={`${group}-header`}
                                              >
                                                <span>
                                                  <img
                                                    style={{ maxWidth: "15%" }}
                                                    src={require("../../assets/img/ic_" +
                                                      group.oid +
                                                      ".png")}
                                                    alt="Logo Worketik"
                                                  />
                                                  <b> {group.text}</b>{" "}
                                                </span>
                                              </Menu.Header>

                                              {srItems.map((srItem, index) => {
                                                return (
                                                  <MenuItem
                                                    key={`${srItem}-count-${counter}`}
                                                    option={srItem}
                                                    position={counter++}
                                                  >
                                                    <Highlighter
                                                      search={menuProps.text}
                                                    >
                                                      {srItem.text}
                                                    </Highlighter>
                                                  </MenuItem>
                                                );
                                              })}
                                            </div>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    );

                                    return <Menu {...menuProps}>{items}</Menu>;
                                  }}

                                  // return (
                                  //   <Menu {...menuProps}>
                                  //     {results.map(
                                  //       (result: any, index: number) => (
                                  //         <MenuItem
                                  //           option={result}
                                  //           position={index}
                                  //         >
                                  //           {result.text}
                                  //         </MenuItem>
                                  //       )
                                  //     )}
                                  //   </Menu>
                                  // );
                                  // }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Sector/es puesto de trabajo</label>
                          <Field
                            name="offerSectors"
                            type="select"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  isLoading={!sectorsList.length}
                                  emptyLabel={
                                    !sectorsList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={sectorsList}
                                  placeholder="Todos..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Ocupaciones</label>
                          <Field
                            name="professions"
                            type="select"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  isLoading={!professionList.length}
                                  emptyLabel={
                                    !professionList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={professionList}
                                  placeholder="Todos..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Jornada/s laborales</label>
                          <Field
                            name="workingDays"
                            type="select"
                            render={field => {
                              return (
                                <Typeahead
                                  isLoading={!workingDayList.length}
                                  emptyLabel={
                                    !workingDayList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={workingDayList}
                                  placeholder="Todas..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Tipo de contrato</label>
                          <Field
                            name="contractModalities"
                            type="select"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  isLoading={!contractModalityList.length}
                                  emptyLabel={
                                    !contractModalityList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={contractModalityList}
                                  placeholder="Todos..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Salario</label>
                          {props.maxSalary !== null && (
                            <Field
                              name="salary"
                              render={field => {
                                return (
                                  <div className="mt-2">
                                    <Slider
                                      isReset={isReset}
                                      min={0}
                                      max={props.maxSalary}
                                      form={form}
                                      field={field}
                                      values={values.salary}
                                    ></Slider>

                                    {values.salary && (
                                      <div>
                                        {"Desde: "}
                                        <NumberFormat
                                          value={values.salary.min}
                                          displayType={"text"}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          suffix={"€"}
                                        />
                                        {" hasta: "}
                                        <NumberFormat
                                          value={values.salary.max}
                                          displayType={"text"}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          suffix={"€"}
                                        />
                                        {values.salary.max == 100000
                                          ? " o más"
                                          : null}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                    </div>
                  </Card>
                  
                </Accordion>
               
                <Accordion>
                  <Card className="mb-1" style={{ overflow: "visible" }}>
                  <div className="border-bottom pb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="1" className="pt-1">
                      Ubicación empleo <i className="fas fa-angle-down"></i>
                      <small className="text-primary pull-right">
                        {props.locationFilterCount > 0
                          ? props.locationFilterCount +
                            (props.locationFilterCount === 1
                              ? " filtro"
                              : " filtros")
                          : null}
                      </small>
                  
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1" className="border-top mt-2">
                      <Card.Body>
                        {values.notAbroad === true ? (
                          (values.countries = null)
                        ) : (
                          <div className="form-group">
                            <label>Paises</label>
                            <Field
                              name="countries"
                              type="select"
                              render={(field: any) => {
                                return (
                                  <Typeahead
                                    isLoading={!countriesList.length}
                                    emptyLabel={
                                      !countriesList.length
                                        ? "Cargando..."
                                        : "No se han encontrado resultados."
                                    }
                                    maxResults={countriesList.length}
                                    paginate={false}
                                    selected={field.input.value}
                                    id={field.input.name}
                                    labelKey={(option: any) => {
                                      return option.text
                                        ? option.text
                                        : undefined;
                                    }}
                                    multiple
                                    options={countriesList}
                                    placeholder="Todos..."
                                    onChange={(value: any) => {
                                      field.input.onChange(value);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        )}
                        <div className="form-group">
                          {values.countries === undefined ||
                          values.countries.length === 0 ||
                          values.countries.filter((country: any) => {
                            return (
                              country.oid ===
                              "00000000-0000-0000-0000-0000000000a8"
                            );
                          }).length > 0 ? (
                            <div>
                              <label>Provincias (España)</label>
                              <Field
                                name="provinces"
                                type="select"
                                render={(field: any) => {
                                  return (
                                    <Typeahead
                                      isLoading={!provincesList.length}
                                      emptyLabel={
                                        !provincesList.length
                                          ? "Cargando..."
                                          : "No se han encontrado resultados."
                                      }
                                      selected={field.input.value}
                                      id={field.input.name}
                                      labelKey={(option: category) => {
                                        return option.text
                                          ? option.text
                                          : undefined;
                                      }}
                                      multiple
                                      options={provincesList}
                                      placeholder="Todas..."
                                      onChange={(value: any) => {
                                        field.input.onChange(value);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          {values.countries === undefined ||
                          values.countries.length === 0 ||
                          values.countries.filter((country: any) => {
                            return (
                              country.oid ===
                              "00000000-0000-0000-0000-0000000000a8"
                            );
                          }).length > 0 ? (
                            <div>
                              <label>Municipios (España)</label>
                              <Field
                                name="municipalities"
                                type="select"
                                render={(field: any) => {
                                  return (
                                    <Typeahead
                                      isLoading={!municipalitiesList.length}
                                      emptyLabel={
                                        !municipalitiesList.length
                                          ? "Cargando..."
                                          : "No se han encontrado resultados."
                                      }
                                      maxResults={municipalitiesList.length}
                                      paginate={false}
                                      selected={field.input.value}
                                      id={field.input.name}
                                      labelKey={(option: any) => {
                                        return option.text
                                          ? option.text
                                          : undefined;
                                      }}
                                      multiple
                                      options={municipalitiesList}
                                      filterBy={(option, props) => {
                                        if (
                                          props.selected.length &&
                                          values.provinces &&
                                          values.provinces.some(
                                            (province: any) =>
                                              province.oid == option.parentOid
                                          )
                                        ) {
                                          // Display all the options if there's a selection.
                                          return true;
                                        }
                                        // Otherwise filter on some criteria.
                                        return (
                                          option.text
                                            .toLowerCase()
                                            .indexOf(
                                              props.text.toLowerCase()
                                            ) !== -1 &&
                                          values.provinces &&
                                          values.provinces.some(
                                            (province: any) =>
                                              province.oid == option.parentOid
                                          )
                                        );
                                      }}
                                      // filterBy={(option: any) => {
                                      //   if (values.provinces != null) {
                                      //     var result = false;
                                      //     values.provinces.forEach(
                                      //       (province: any) => {
                                      //         result =
                                      //           result ||
                                      //           province.oid ===
                                      //             option.parentOid;
                                      //       }
                                      //     );
                                      //     return result;
                                      //   } else {
                                      //     return true;
                                      //   }
                                      // }}
                                      placeholder="Todos..."
                                      onChange={(value: any) => {
                                        field.input.onChange(value);
                                      }}
                                      disabled={values.provinces ? false : true}
                                    />
                                  );
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                    </div>
                  </Card>
                </Accordion>

                <Accordion style={{ zIndex: 1 }}>
                  <Card className="mb-1" style={{ overflow: "visible" }}>
                  <div className="border-bottom pb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="2" className="pt-1">
                      Mi perfil{" "}
                      <i className="fas fa-angle-down"></i>
                      <small className="text-primary pull-right">
                        {props.requirementsFilterCount > 0
                          ? props.requirementsFilterCount +
                            (props.requirementsFiltercount === 1
                              ? " filtro"
                              : " filtros")
                          : null}
                      </small>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2" className="border-top mt-2">
                      <Card.Body>
                        <div className="form-group">
                          <label>Idiomas requeridos</label>
                          <Field
                            name="languages"
                            type="select"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  isLoading={!languagesList.length}
                                  emptyLabel={
                                    !languagesList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={languagesList}
                                  placeholder="Todos..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Estudios mínimos requeridos</label>
                          <Field
                            name="educationLevels"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  isLoading={!educationLevelList.length}
                                  emptyLabel={
                                    !educationLevelList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text;
                                  }}
                                  options={educationLevelList}
                                  placeholder="Selecciona..."
                                  onChange={(value: any) => {
                                    if (value.length > 0) {
                                      form.change("educationLevels", value[0]);
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Competencias requeridas</label>
                          <Field
                            name="professionalSkills"
                            type="select"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  isLoading={!professionalSkillList.length}
                                  emptyLabel={
                                    !professionalSkillList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={professionalSkillList}
                                  placeholder="Todas..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Conocimientos requeridos</label>
                          <Field
                            name="knowledges"
                            type="select"
                            render={(field: any) => {
                              return (
                                <Typeahead
                                  isLoading={!knowledgeList.length}
                                  emptyLabel={
                                    !knowledgeList.length
                                      ? "Cargando..."
                                      : "No se han encontrado resultados."
                                  }
                                  selected={field.input.value}
                                  id={field.input.name}
                                  labelKey={(option: any) => {
                                    return option.text
                                      ? option.text
                                      : undefined;
                                  }}
                                  multiple
                                  options={knowledgeList}
                                  placeholder="Todos..."
                                  onChange={(value: any) => {
                                    field.input.onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                    </div>
                  </Card>
                </Accordion>

                <button type="submit" className="btn-round btn btn-primary">
                  Aplicar filtro
                </button>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
