import { config } from './Constants';

export async function getCategories(category: string, setter: any) {
  return fetch(`${config.apiURL}categories/${category}`).then(
    (response: any) => {
      if (response.ok) {
        response.json().then((data: any) => {
          setter(data.items);
        });
      }
    },
    () => {
      console.log('ERROR ON GET + ' + category);
    }
  );
}
