import React from "react";
import { Link } from "react-router-dom";

const BuyPack: React.FC = (props: any) => (
  <div className="row">
    <div className="col-md-12">
      <div className="card-user card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-1">
              <img
                src={require(".././../assets/img/ic_doc.png")}
                alt="Contratación de packs"
                className="d-block mx-auto my-auto"
              />
            </div>

            <div className="col-md-8 p-0">
              <h4 className="mt-1 mb-1">Pack de publicación por semanas </h4>
              <p className="mb-1">
                Éste pack le permite publicar cualquier oferta durante una o
                varias semanas y si es necesario, publicarla de nuevo unas
                semanas más.{" "}
              </p>
            </div>
            <div className="col-md-3 text-center">
              <p className="font-weight-bold text-primary mb-0">
                {props.company.availableWeeks} semana/s disponible/s
              </p>
              <Link className="btn btn-primary" to="/private/contract-service">
                Comprar pack publicación
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BuyPack;
