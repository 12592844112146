import React, { useEffect } from "react";
import { myCompany } from "../../models/MyCompany";
import { Form, Field } from "react-final-form";
import SocialBenefitsComponent from "../../components/SocialBenefits";

const SocialBenefits: React.FC<myCompany> = (props: any) => {
  return (
    <div>
      <div className="card-header">
        <h5 className="card-title">Beneficios sociales</h5>
      </div>
      <div className="card-body pb-4">
        <Form
          initialValues={props.company.socialResponsibilities}
          onSubmit={props.putSocialResponsibilities}
          render={({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="oid"
                type="text"
                component="input"
                defaultValue={props.oid}
                hidden
              />

              <SocialBenefitsComponent {...props} />

              <div className=" col-md-4 col-sm-12 ">
                <button
                  className="btn btn-primary btn-round"
                  type="submit"
                  disabled={
                    submitting ||
                    compare(
                      props.company.socialResponsibilities,
                      props.socialBenefitsData
                    )
                  }
                >
                  Guardar
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

const compare = (companySRList: [], selectedSRList: []) => {
  var same = true;

  if (companySRList.length != selectedSRList.length) {
    same = false;
  } else {
    same = selectedSRList.every((selectedItem: any) => {
      return companySRList.some((companyItem: any) => {
        return companyItem.categoryOid == selectedItem.oid;
      });
    });
  }

  return same;
};

export default SocialBenefits;
